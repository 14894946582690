// ** React Imports
import { Fragment } from "react";

// ** Reactstrap Imports
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// ** Icons Imports
import {
  User,
  Lock,
  Bookmark,
  Bell,
  Link,
  DollarSign,
  FileText,
} from "react-feather";
import { UiChecks } from "react-bootstrap-icons";

// ** User Components
import AgentDetails from "./AgentDetails";
import CommissionTab from "./CommissionTab";
import Statement from "../statement";
import Security from "./Security";
import Roles from "./Roles";

const AgentTabs = ({ active, toggleTab, agent }) => {
  return (
    <Fragment>
      <Nav pills className="mb-2">
        <NavItem>
          <NavLink active={active === "1"} onClick={() => toggleTab("1")}>
            <User className="font-medium-3 me-50" />
            <span className="fw-bold">Profile</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={active === "2"} onClick={() => toggleTab("2")}>
            <DollarSign className="font-medium-3 me-50" />
            <span className="fw-bold">Commission</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={active === "3"} onClick={() => toggleTab("3")}>
            <FileText className="font-medium-3 me-50" />
            <span className="fw-bold">Statement</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={active === "4"} onClick={() => toggleTab("4")}>
            <Lock className="font-medium-3 me-50" />
            <span className="fw-bold">Security</span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={active}>
        <TabPane tabId="1">
          <AgentDetails agent={agent} />
        </TabPane>
        <TabPane tabId="2">
          <CommissionTab agent={agent} />
        </TabPane>
        <TabPane tabId="3">
          <Statement agent={agent} />
        </TabPane>
        <TabPane tabId="4">
          <Security agent={agent} />
        </TabPane>
      </TabContent>
    </Fragment>
  );
};

export default AgentTabs;
