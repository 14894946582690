import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ApiErrorHandler } from "../utility/error";
import { BASE_URL, getToken } from "../utility/Utils";

export const commissionAPi = createApi({
  reducerPath: "commissions",
  tagTypes: ["Commissions"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // create Commission
    createCommission: builder.mutation({
      query: (body) => ({ url: "/hrm/commission/", method: "POST", body }),
      invalidatesTags: ["Commissions"],
    }),

    //get commissions list
    getCommissionList: builder.query({
      query: () => `/hrm/commission/list`,
    }),

    // get all commissions
    getCommissions: builder.query({
      query: ({ page = 1, perPage = 10, keyword = "" }) => {
        const params = new URLSearchParams();
        if (keyword || keyword !== "") {
          params.append("search", keyword);
          params.append("fields[0]", "agent");
          params.append("fields[1]", "amount");
          params.append("fields[2]", "application");
          params.append("fields[3]", "description");

          params.append("options[page]", page);
          params.append("options[limit]", perPage);
          params.append("options[populate][0][dir]", "users");
          params.append("options[populate][0][path]", "createdBy");
          params.append("options[populate][0][path][select]", "username");

          return `/hrm/commissions?${params.toString()}`;
        }

        params.append("options[page]", page);
        params.append("options[limit]", perPage);
        params.append("options[populate][0][dir]", "users");
        params.append("options[populate][0][path]", "createdBy");
        params.append("options[populate][0][select]", "username");

        return `/hrm/commissions?${params.toString()}`;
      },
      providesTags: ["Commissions"],
    }),

    // get commission by agentid
    getCommissionById: builder.query({
      query: (agentId) => `/hrm/commissions/getCommissionByAgent/${agentId}`,
    }),
  }),
});

export const {
  useCreateCommissionMutation,
  useGetCommissionListQuery,
  useGetCommissionsQuery,
  useGetCommissionByIdQuery,
} = commissionAPi;
