import { convertToRaw, EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CreatableSelect from "react-select";
import draftToHtml from "draftjs-to-html";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import "@styles/react/libs/editor/editor.scss";
import axios, { AxiosError } from "axios";
import { ErrorHandler } from "../../common/utils/Error";
import HandleDispatch from "../../common/utils/HandledDispatch";
import { useDispatch, useSelector } from "react-redux";
import { createAgreement } from "../../redux/agreements";
import { toast } from "react-hot-toast";
import ReactSelect from "react-select";
import MultipleFileUpload from "@commons/utils/components/MultipleFileUpload";
import classNames from "classnames";
import moment from "moment";

export default function AgreementForm() {
  const [loading, setloading] = useState(false);
  const [editorValue, setEditorValue] = useState(EditorState.createEmpty());
  const [customers, setCustomers] = useState([]);
  const [properties, setProperties] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [searchParam, setSearchParam] = useSearchParams();
  const [rentApplication, setRentApplication] = useState(null);

  const [sectionals, setSectionals] = useState([]);

  const navigate = useNavigate();

  const CurrentBuilding = useSelector((state) => state.CurrentBuilding);

  const getAllCustomers = async () => {
    try {
      const { data, status } = await axios.get("/customers/list");

      if (status == 200) {
        var csList = data.data.map((cus) => ({
          label: cus.name,
          value: cus._id,
        }));
        setCustomers(csList);
      } else {
        throw Error(data.message);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  // const getAllProperties = async () => {
  //   try {
  //     const { data, status } = await axios.get("/buildings/list/available");

  //     if (status == 200) {
  //       setProperties(data.data);
  //     } else {
  //       throw Error(data.message);
  //     }
  //   } catch (error) {
  //     ErrorHandler(error);
  //   }
  // };
  const getAvailableSectionals = async () => {
    try {
      var { status, data } = await axios.get("/sectionals/list/", {
        params: {
          building: CurrentBuilding?.data?._id,
        },
      });
      if (status == 200) {
        setSectionals(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    getAllCustomers();
    // getAllProperties();
    getAvailableSectionals();
  }, []);

  var initialValues = {
    agreementName: "",
    building: "",
    customer: "",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: "",
    documents: [],
    amount: 0,
    unit: "",

    agreementStatements: "",
    recursion: { label: "Monthly", value: "1" },
  };

  const {
    reset,
    control,
    register,
    clearErrors,
    getValues,
    setValue,
    setFocus,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: initialValues });

  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "documents",
  // });

  // const {
  //   fields: feilds1,
  //   append: append1,
  //   remove: remove1,
  // } = useFieldArray({
  //   control,
  //   name: "responsible",
  // });

  const onSubmit = async (values) => {
    setloading(true);

    if (!CurrentBuilding?.data) {
      toast.error("First Login in as Property");
      return;
    }

    try {
      values.agreementStatements = editorValue
        ? draftToHtml(convertToRaw(editorValue.getCurrentContent()))
        : "";
      values.building = CurrentBuilding?.data?._id;
      values.unit = values.unit.value;
      values.recursion = values.recursion.value;
      values.user = values.customer.value;
      var formdata = new FormData();

      for (const key in values) {
        if (key != "customer") {
          formdata.append(key, values[key]);
        }
      }

      if (rentApplication) {
        formdata.append("booking", rentApplication._id);
      }

      documents.forEach((file, i) => {
        formdata.append(`documents`, file, file.name);
      });

      const response = await HandleDispatch(
        dispatch,
        createAgreement(formdata)
      );

      if (response) {
        reset();
        setDocuments([]);
        toast.success("New Agreement Created Successfully");
        navigate("/leases");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.toString());
    } finally {
      setloading(false);
    }
  };

  var recursion = [
    { label: "Monthly", value: "1" },
    { label: "2 Months", value: "2" },
    { label: "3 Months", value: "3" },
    { label: "6 Months", value: "6" },
    { label: "Yearly", value: "12" },
  ];

  const getApplication = async (application) => {
    try {
      var { status, data } = await axios.get(`/bookings/${application}`, {
        params: {
          populate: [
            {
              path: "customer",
              dir: "customers",
              select: "_id name",
            },
            {
              path: "unit",
              dir: "sectionals",
              select: "_id name price",
            },
          ],
        },
      });

      if (status == 200) {
        const app = data.data;
        setRentApplication(app);

        setValue("unit", { label: app.unit.name, value: app.unit._id });
        setValue("customer", {
          label: app.customer.name,
          value: app.customer._id,
        });
        setValue("agreementName", app.customer.name);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  useEffect(() => {
    const application = searchParam.get("application");
    const customer = searchParam.get("customer");

    if (application) getApplication(application);

    if (customer) {
      const selectedCustomer = customers.find((item) => item.value == customer);

      if (selectedCustomer) {
        reset({
          ...initialValues,
          customer: selectedCustomer,
          agreementName: `${selectedCustomer.label}`,
        });
      }
    }
    // setSearchParam({});
  }, [customers]);

  return (
    <div>
      <Breadcrumb listClassName="breadcrumb-dashes">
        <BreadcrumbItem>
          <Link to="/"> Home </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/leases"> Agreements </Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>
          <Link to="#"> Create </Link>
        </BreadcrumbItem>
      </Breadcrumb>

      <Card>
        <CardHeader>
          <CardTitle>Agreement Form</CardTitle>
        </CardHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardBody>
            <Row className="gy-2">
              <Col sm={12} md={6}>
                <Label className="form-label" for="customer">
                  Customer
                </Label>
                <Controller
                  name="customer"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CreatableSelect
                      isDisabled={rentApplication}
                      id="customer"
                      className="react-select"
                      classNamePrefix="select"
                      placeholder="Choose Customer"
                      options={customers}
                      onCreateOption={(value) => {
                        // setAmenityTypes([...amenityTypes,value]);
                      }}
                      {...field}
                      onChange={(e) => {
                        setValue("agreementName", e.label);
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </Col>
              <Col sm={12} md={6}>
                <Label className="form-label" for="agreementName">
                  Agreement Title
                </Label>
                <Controller
                  name="agreementName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="agreementName"
                      placeholder="Agreement title"
                      invalid={errors.agreementName && true}
                      {...field}
                    />
                  )}
                />
                {errors.agreementName && (
                  <FormFeedback>Please enter a valid Name</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="unit">
                  Select Unit
                </Label>
                <Controller
                  name="unit"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CreatableSelect
                      id="unit"
                      isDisabled={rentApplication}
                      className={"react-select"}
                      classNamePrefix="select"
                      aria-invalid={errors.unit && true}
                      placeholder="Choose Unit"
                      options={sectionals.map((pr) => ({
                        label: pr.name,
                        value: pr._id,
                      }))}
                      {...field}
                      onChange={(val) => {
                        field.onChange(val);
                        setValue("amount", "");
                        setValue("recursion", { label: "Monthly", value: "1" });
                      }}
                    />
                  )}
                />
              </Col>
              <Col xs={12} md={4}>
                <Label className="form-label" for="recursion">
                  Recursion
                </Label>

                <Controller
                  name="recursion"
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      id="recursion"
                      className="react-select"
                      classNamePrefix="select"
                      invalid={errors.recursion && true}
                      defaultValue={recursion[0]}
                      {...field}
                      // value={buildingTypes.find(b=>b.value==field.value)}
                      options={recursion}
                      onChange={(v) => {
                        const unit = getValues().unit;

                        if (!unit) {
                          setError("unit", { message: "Field is required" });
                          setFocus("unit");
                          toast.error("Select Property / Unit", {
                            position: "top-center",
                          });
                          return;
                        }

                        const selectedUnit = sectionals.find(
                          (pr) => pr._id === unit.value
                        );

                        console.log(selectedUnit);

                        const total = !rentApplication
                          ? selectedUnit?.price * v.value
                          : selectedUnit?.price * v.value -
                            rentApplication?.amount;

                        setValue("amount", total);
                        field.onChange(v);
                      }}
                    />
                  )}
                />
                {errors.recursion && (
                  <FormFeedback>Please Select Recursion</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="amount">
                  Rent Amount
                </Label>
                <Controller
                  name="amount"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="amount"
                      type="text"
                      min={0}
                      readOnly
                      placeholder="Agreement Amount"
                      invalid={errors.amount && true}
                      {...field}
                    />
                  )}
                />
                {errors.amount && (
                  <FormFeedback>Please enter a valid Name</FormFeedback>
                )}
                {rentApplication && (
                  <span className="text-warning">
                    Automatically subtracted the reservation fee
                  </span>
                )}
              </Col>

              <Col sm={12} md={4}>
                <Label className="form-label" for="startDate">
                  Agreement Start Date
                </Label>
                <Controller
                  name="startDate"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="startDate"
                      type="date"
                      placeholder="Agreement Start Date"
                      invalid={errors.startDate && true}
                      {...field}
                    />
                  )}
                />
                {errors.startDate && (
                  <FormFeedback>Please enter a valid Name</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="endDate">
                  Agreement End Date
                </Label>
                <Controller
                  name="endDate"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="endDate"
                      type="date"
                      placeholder="Agreement End Date"
                      invalid={errors.endDate && true}
                      {...field}
                    />
                  )}
                />
                {errors.endDate && (
                  <FormFeedback>Please enter a valid Name</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="agreementStartDate">
                  Agreement will Starts
                </Label>
                <Controller
                  name="agreementStartDate"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="agreementStartDate"
                      type="date"
                      placeholder="Agreement Start Date"
                      invalid={errors.agreementStartDate && true}
                      {...field}
                    />
                  )}
                />
                {errors.agreementStartDate && (
                  <FormFeedback>
                    Please enter a valid Agreement Date
                  </FormFeedback>
                )}
              </Col>
              <Col sm={12}>
                <Label className="form-label" for="agreementName">
                  Agreement Statement
                </Label>
                <Editor
                  editorState={editorValue}
                  onEditorStateChange={(data) => setEditorValue(data)}
                />
                {errors.agreementName && (
                  <FormFeedback>Please enter a valid Name</FormFeedback>
                )}
              </Col>
              <Col sm={12}>
                <MultipleFileUpload
                  title={"Agreement Docs"}
                  files={documents}
                  setFiles={setDocuments}
                  fileTypes={{
                    "application/pdf": [".pdf"],
                  }}
                />
              </Col>
            </Row>

            <div className="text-center mt-3 mb-1">
              <Button color="success" type="submit" disabled={loading}>
                <Spinner
                  color="light"
                  size="sm"
                  className="me-1"
                  hidden={!loading}
                />
                {loading ? "Submitting..." : "Submit"}
              </Button>
              <Button
                color="secondary"
                outline
                className="ms-1"
                type="reset"
                onClick={() => navigate("/leases")}
              >
                Cancel
              </Button>
            </div>
          </CardBody>
        </form>
      </Card>
    </div>
  );
}
