import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
// ** Reactstrap Imports
import {
  Row,
  Col,
  Label,
  Form,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Alert,
  InputGroup,
  InputGroupText,
} from "reactstrap";

// ** Third Party Components
import * as yup from "yup";
import toast from "react-hot-toast";

// ** Custom Yup Validation Schema
import { yupResolver } from "@hookform/resolvers/yup";

// ** Redux RTK Query Imports
import {
  useCreateAgentMutation,
  useGetAgentsQuery,
} from "../../../../../redux/agents";
import CreatableSelect from "react-select";

import MultipleFileUpload from "../MultipleFileUpload";

const addAgentModal = ({ show, setShow }) => {
  // ** RTK Query Mutations
  const [createAgent, { isLoading, isError, error }] = useCreateAgentMutation();
  const { data } = useGetAgentsQuery();

  // ** states
  const [isNew, setIsnew] = useState(false);
  const [document, setDocument] = useState([]);

  const [commisType, setCommisType] = useState("");

  // ** Save & Add New Agent
  const AgentShema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    gender: yup.string(),
    status: yup.string(),
    username: yup.string().required(),
    password: yup.string().required(),
    commissionType: yup.string().required(),
    document: yup.mixed().required("File is required"),
  });

  // ** useForm hook vars
  const {
    reset,
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reselvover: yupResolver(AgentShema),
  });

  // handle type change
  useEffect(() => {
    if (watch("commissionType")?.value === "percentage") {
      setCommisType("percentage");
    }
    if (watch("commissionType")?.value === "fixed") {
      setCommisType("fixed");
    }
  }, [watch("commissionType")]);

  // ** Handles form submission
  const onSubmit = async (values) => {
    var formdata = new FormData();

    for (const key in values) {
      formdata.append(key, values[key]);
    }

    formdata.set("gender", values?.gender?.value);
    formdata.set("status", values?.status?.value);
    formdata.set("commissionType", values?.commissionType?.value);

    document.forEach((file, i) => {
      formdata.append(`document`, file, file.name);
    });

    const { data: resData } = await createAgent(formdata);
    if (resData && isNew) {
      reset();
      setIsnew(false);
      toast.success("Agent added successfully!");
    }
    if (resData && !isNew) {
      reset();
      setShow(false);
      toast.success("Agent added successfully!");
    }
  };

  // ** Handles form discard
  const handleDiscard = () => {
    reset();
    setShow(false);
  };

  // ** Handles error toast
  useEffect(() => {
    if (isError) {
      const message =
        error.data.message || "Something went wrong. Please try again later.";
      toast.error(message);
    }
  }, [isError, error]);

  return (
    <Modal
      isOpen={show}
      onClosed={handleDiscard}
      toggle={() => setShow(!show)}
      className="modal-lg"
    >
      <ModalHeader
        className="bg-transparent"
        toggle={() => setShow(!show)}
      ></ModalHeader>
      <ModalBody className="px-sm-5 pb-5">
        <div className="text-center mb-2">
          <h1 className="mb-1">Add New Agent</h1>
        </div>

        {error && (
          <Alert color="danger">
            <h6 className="alert-heading">Error {error.status}!</h6>
            <div className="alert-body">
              <p>{JSON.stringify(error.data.message)}</p>
            </div>
          </Alert>
        )}

        <Row tag={Form} onSubmit={handleSubmit(onSubmit)}>
          <Col xs={12} md={6} className="mb-1">
            <Label className="form-label" for="name">
              Name
            </Label>
            <Controller
              control={control}
              id="name"
              name="name"
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Name"
                  invalid={errors.name && true}
                />
              )}
            />
            {errors.name && <FormFeedback>please enter name</FormFeedback>}
          </Col>

          <Col xs={12} md={6} className="mb-1">
            <Label className="form-label" for="email">
              Email
            </Label>
            <Controller
              control={control}
              id="email"
              name="email"
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Email"
                  invalid={errors.email && true}
                />
              )}
            />
            {errors.email && (
              <FormFeedback>Please enter a valid email</FormFeedback>
            )}
          </Col>
          <Col xs={12} md={6} className="mb-1">
            <Label className="form-label" for="phone">
              Phone
            </Label>
            <Controller
              control={control}
              id="phone"
              name="phone"
              defaultValue=""
              rules={{ required: true }}
              {...register("phone")}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Phone"
                  invalid={errors.phone && true}
                />
              )}
            />
            {errors.phone && (
              <FormFeedback>please enter a valid phone number</FormFeedback>
            )}
          </Col>

          <Col xs={12} md={6} className="mb-1">
            <Label className="form-label" for="gender">
              gender
            </Label>
            <Controller
              control={control}
              id="gender"
              name="gender"
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <CreatableSelect
                  id="gender"
                  className={"react-select"}
                  classNamePrefix="select"
                  aria-invalid={errors.gender && true}
                  placeholder="Choose gender"
                  options={[
                    { label: "Male", value: "male" },
                    { label: "Female", value: "female" },
                  ]}
                  {...field}
                />
              )}
            />
            {errors.status && <FormFeedback>choose gender</FormFeedback>}
          </Col>
          <Col xs={12} md={6} className="mb-1">
            <Label className="form-label" for="status">
              status
            </Label>
            <Controller
              control={control}
              id="status"
              name="status"
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <CreatableSelect
                  id="status"
                  className={"react-select"}
                  classNamePrefix="select"
                  aria-invalid={errors.status && true}
                  placeholder="Choose status"
                  options={[
                    { label: "Active", value: "active" },
                    { label: "InActive", value: "inactive" },
                  ]}
                  {...field}
                />
              )}
            />
            {errors.status && <FormFeedback>choose status</FormFeedback>}
          </Col>
          <Col xs={12} md={6} className="mb-1">
            <Label className="form-label" for="username">
              Username
            </Label>
            <Controller
              control={control}
              id="username"
              name="username"
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Username"
                  invalid={errors.username && true}
                />
              )}
            />
            {errors.username && (
              <FormFeedback>please enter username</FormFeedback>
            )}
          </Col>
          <Col xs={12} md={6} className="mb-1">
            <Label className="form-label" for="password">
              password
            </Label>
            <Controller
              control={control}
              id="password"
              name="password"
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  type="password"
                  {...field}
                  placeholder="password"
                  invalid={errors.password && true}
                />
              )}
            />
            {errors.password && (
              <FormFeedback>Please enter a valid Password</FormFeedback>
            )}
          </Col>

          <Col xs={12} md={6} className="mb-1">
            <Label className="form-label" for="commissionType">
              Commission type
            </Label>
            <Controller
              control={control}
              id="commissionType"
              name="commissionType"
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <CreatableSelect
                  id="commissionType"
                  className={"react-select"}
                  classNamePrefix="select"
                  aria-invalid={errors.commissionType && true}
                  placeholder="Choose type"
                  options={[
                    { label: "Percentage", value: "percentage" },
                    { label: "Fixed", value: "fixed" },
                  ]}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);

                    if (e.value === "percentage") {
                      setCommisType("percentage");
                    }
                    if (e.value === "fixed") {
                      setCommisType("fixed");
                    }
                  }}
                />
              )}
            />
            {errors.commissionType && (
              <FormFeedback>choose commission type</FormFeedback>
            )}
          </Col>

          <Col xs={12} md={6} className="mb-1">
            <Label className="form-label" for="commissionAmount">
              Amount
            </Label>
            <Controller
              control={control}
              id="commissionAmount"
              name="commissionAmount"
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <InputGroup>
                  <InputGroupText>
                    {commisType === "percentage" ? (
                      <span>%</span>
                    ) : (
                      <span>$</span>
                    )}
                  </InputGroupText>

                  <Input
                    type="number"
                    {...field}
                    placeholder="Amount"
                    invalid={errors.commissionAmount && true}
                  />
                </InputGroup>
              )}
            />
            {errors.commissionAmount && (
              <FormFeedback>please enter amount</FormFeedback>
            )}
          </Col>

          <row sm={12} md={6}>
            <Label className="form-label">Agent Doc</Label>
            <MultipleFileUpload
              files={document}
              setFiles={setDocument}
              fileTypes={{
                "application/pdf": [".pdf"],
              }}
            />
          </row>

          <Col xs={12} className="text-center mt-2">
            <Button className="me-1" color="primary" disabled={isLoading}>
              {isLoading && !isNew ? "Please, wait..." : "Create agent"}
            </Button>
            <Button
              outline
              type="submit"
              onClick={() => setIsnew(!isNew)}
              disabled={isLoading}
            >
              {isLoading && isNew ? "Please, wait..." : "Save & Add New"}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default addAgentModal;
