import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";

import DataTable from "react-data-table-component";

import { useGetCommissionByIdQuery } from "../../../../redux/commissions";
import _ from "lodash";
import Loading from "../../../loading";

const CommissionTab = ({ agent }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data, isSuccess, isLoading } = useGetCommissionByIdQuery(
    agent.data?._id,
    {
      page: currentPage,
      perPage: rowsPerPage,
    }
  );

  const columns = [
    {
      name: "Description",
      selector: "description",
      sortable: true,
      minWidth: "230px",
      cell: (row) => {
        return (
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column">
                <div className="user-name text-truncate mb-0">
                  <span className="font-weight-bold">
                    {" "}
                    <span
                      style={{
                        color: "#000",
                        marginRight: "0.5rem",
                      }}
                    >
                      Unit
                    </span>
                    <span>{row.application.unit.name}</span>
                  </span>
                </div>
                <small
                  className="text-truncate text-muted"
                  style={{
                    marginTop: "0.3rem",
                  }}
                >
                  {new Date(row.createAt).toDateString()}
                </small>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      name: "Amount",
      selector: "amount",
      sortable: true,
      minWidth: "200px",
      //   add doller sign
      cell: (row) => {
        return `$${row.amount}`;
      },
    },
    {
      name: "Floor",
      selector: "application.unit.locatedFloor",
      sortable: true,
      minWidth: "100px",
      // cell: (row) => {
      //   return (
      //     <div className="d-flex align-items-center">
      //       <div className="d-flex align-items-center">
      //         <div className="d-flex flex-column">
      //           <div className="user-name text-truncate mb-0">
      //             <span className="font-weight-bold">
      //               {row.application.unit.name}
      //             </span>
      //           </div>
      //           <small className="text-truncate text-muted">
      //             {" "}
      //             <span
      //               className=""
      //               style={{
      //                 color: "#000",
      //                 marginRight: "0.5rem",
      //               }}
      //             >
      //               floor
      //             </span>
      //             {row.application.unit.locatedFloor}
      //           </small>
      //         </div>
      //       </div>
      //     </div>
      //   );
      // },
    },
  ];

  const renderData = () => {
    const calculateIndex = (index) => {
      return (currentPage - 1) * rowsPerPage + index + 1;
    };

    return data?.data?.map((item, index) => {
      return {
        ...item,
        index: calculateIndex(index),
      };
    });
  };
  return (
    <Fragment>
      <Card
        style={{
          width: "100%",
          overflowX: "hidden",
        }}
      >
        <CardHeader tag="h4">Commissions</CardHeader>
        <CardBody>
          <div className="react-dataTable">
            {isLoading ? (
              <Loading cols={columns} />
            ) : (
              <DataTable
                columns={columns}
                data={renderData()}
                pagination
                responsive
                paginationServer
                paginationTotalRows={data?.total}
                paginationDefaultPage={currentPage}
                paginationPerPage={rowsPerPage}
                onChangeRowsPerPage={(currentRowsPerPage) => {
                  setRowsPerPage(currentRowsPerPage);
                }}
                onChangePage={(page) => {
                  setCurrentPage(page);
                }}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default CommissionTab;
