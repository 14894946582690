// ** Redux Imports
import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { shiftApi } from "./shifts";
import { agentAPi } from "./agents";
import { agentEarnsApi } from "./agentEarns";
import { commissionAPi } from "./commissions";
import { companySittingsApi } from "./companySittings";
import { sectionalApi } from "./sectionals";
import { vendorApi } from "./vendors";
import { sectionalCategoryApi } from "./sectionalCategory";

const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: true,
    })
      .concat(shiftApi.middleware)
      .concat(agentAPi.middleware)
      .concat(commissionAPi.middleware)
      .concat(companySittingsApi.middleware)
      .concat(agentEarnsApi.middleware)
      .concat(sectionalApi.middleware)
      .concat(vendorApi.middleware)
      .concat(sectionalCategoryApi.middleware);
  },
});

export { store };
