// ** React Imports
import { useState, Fragment } from "react";

// ** Reactstrap Imports
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { FiUpload } from "react-icons/fi";

// ** Third Party Imports
import { useDropzone } from "react-dropzone";
import { FileText, X, DownloadCloud } from "react-feather";

import "@styles/react/libs/file-uploader/file-uploader.scss";

const FileUploaderSingle = ({
  files,
  setFiles,
  text = "Drop File here or click to upload",
}) => {
  // ** State
  // const [files, setFiles] = useState([])

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles([...acceptedFiles.map((file) => Object.assign(file))]);
    },
  });
  // const { getRootProps, getInputProps } = useDropzone({
  //   multiple: false,
  //   onDrop: acceptedFiles => {
  //     setFiles([...acceptedFiles.map(file => Object.assign(file))])
  //   }
  // })

  const renderFilePreview = (file) => {
    if (file.type?.startsWith("image")) {
      return (
        <img
          className="rounded"
          alt={file.name}
          src={URL.createObjectURL(file)}
          height="28"
          width="28"
        />
      );
    } else {
      return <FileText size="28" />;
    }
  };

  const renderUploadedImage = () => {
    if (files?.length && typeof files[0] !== "string") {
      // @ts-ignore
      return files?.map((file) => (
        <img
          key={file?.name}
          alt={file?.name}
          className="single-file-image img-fluid"
          style={{
            borderRadius: "3px",
          }}
          src={URL.createObjectURL(file)}
        />
      ));
    } else {
      if (typeof files[0] === "string") {
        return (
          <img
            alt="task-img"
            className="single-file-image img-fluid h-100 w-100 position-absolute object-cover"
            src={files[0]}
          />
        );
      }
    }
  };

  const handleRemoveFile = (file) => {
    const uploadedFiles = files;
    const filtered = uploadedFiles.filter((i) => i.name !== file.name);
    setFiles([...filtered]);
  };

  const renderFileSize = (size) => {
    if (Math.round(size / 100) / 10 > 1000) {
      return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`;
    } else {
      return `${(Math.round(size / 100) / 10).toFixed(1)} kb`;
    }
  };

  // const fileList = files.map((file, index) => (
  //   <ListGroupItem
  //     key={`${file?.name}-${index}`}
  //     className="d-flex align-items-center justify-content-between"
  //   >
  //     <div className="file-details d-flex align-items-center">
  //       <div className="file-preview me-1">{renderFilePreview(file)}</div>
  //       <div>
  //         <p className="file-name mb-0">{file?.name}</p>
  //         <p className="file-size mb-0">{renderFileSize(file?.size)}</p>
  //       </div>
  //     </div>
  //     <Button
  //       color="danger"
  //       outline
  //       size="sm"
  //       className="btn-icon"
  //       onClick={() => handleRemoveFile(file)}
  //     >
  //       <X size={14} />
  //     </Button>
  //   </ListGroupItem>
  // ));

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  const truncateText = (text, limit = 30) => {
    return text?.length > limit ? `${text?.substring(0, limit)}...` : text;
  };

  return (
    <div>
      <div {...getRootProps({ className: " position-relative" })}>
        <input {...getInputProps()} />
        <div
          className=""
          style={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "start",
            overflow: "hidden",
            border: "1px dashed #e9e9e9",
            borderRadius: "5px",
            padding: "8px 10px",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {!files[0]?.name && <FiUpload style={{}} />}
            <span
              style={{
                marginLeft: "10px",
              }}
            >
              {files.length ? truncateText(files[0]?.name) : text}
            </span>
          </div>

          <div
            style={{
              width: "2.5rem",
              borderRadius: "5px",
              maxWidth: "100%",
              marginLeft: "auto",
              height: "1.7rem",
              maxHeight: "100%",
            }}
          >
            {files?.length ? renderUploadedImage() : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUploaderSingle;
