// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios, { AxiosError } from "axios";
import { isLoading } from "./expenses";

export const getAllreceipts = createAsyncThunk(
  "receipts/getAllData",
  async (view, { dispatch, rejectWithValue }) => {
    try {
      let bodyContent = {
        params: {
          query: { type: view },
          options: {
            populate: [
              {
                path: "createdBy",
                dir: "users",
                select: "_id username",
              },

              {
                path: "accountId",
                dir: "accountings/chartOfAccounts",
                select: "accountNo accountName",
              },
              {
                path: "invoiceId",
                dir: "accountings/invoices",
                select: "invoiceNo",
                populate: {
                  path: "customer",
                  dir: "customers",
                  select: "name",
                },
              },
            ],
          },
        },
      };
      const response = await axios.get("/accountings/receipts", bodyContent);
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      } else {
        const docs = response.data?.data?.docs || [];
        return {
          docs,
          totalRows: response.data?.data?.totalDocs,
          params: bodyContent.params,
        };
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        var { response } = error;
        return rejectWithValue(response.data.message);
      }
    }
  }
);

export const getData = createAsyncThunk(
  "receipts/getData",
  async (params, { dispatch, getState, rejectWithValue }) => {
    try {
      var strparams = getState().receipts.params;
      var newparams = {
        ...strparams,
        options: { ...strparams.options, ...params },
      };
      const response = await axios.get("/accountings/receipts", {
        params: newparams,
      });
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      } else {
        return {
          params: newparams,
          data: response.data?.data?.docs,
        };
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        var { response } = error;
        return rejectWithValue(response.data.message);
      }
    }
  }
);

export const createReceipt = createAsyncThunk(
  "receipts/create",
  async (formdata, { dispatch, getState, rejectWithValue }) => {
    var response = await axios.post("/accountings/receipts", formdata);
    // await dispatch(getData(getState().users.params));
    dispatch(addReceipt(response.data?.data));

    return { status: response.status, message: response.message };
  }
);

export const updateReceipt = createAsyncThunk(
  "receipts/update",
  async ({ data, id }, { dispatch, getState, rejectWithValue }) => {
    try {
      console.log("EDit booking", data);
      var response = await axios.patch(`/accountings/receipts/${id}`, data);
      // await dispatch(getData(getState().users.params));
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      } else {
        dispatch(editReceipt(response.data?.data));
        return { status: response.status, message: response.message };
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        var { response } = error;
        return rejectWithValue(response.data.message);
      }
    }
  }
);

export const deleteReceipt = createAsyncThunk(
  "receipts/delete",
  async (id, { dispatch, getState, rejectWithValue }) => {
    const response = await axios.delete(`/accountings/receipts/${id}`);
    // if (response.status != 204) {
    //   return rejectWithValue(response.data.message);
    // } else {

    await dispatch(getAllreceipts());
    return response;
    // }
  }
);

export const receiptslice = createSlice({
  name: "receipts",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    selectedReceipt: null,
  },
  reducers: {
    isLoading: (state, { payload }) => {
      state.loading = payload;
      //state.error = null;
    },
    addReceipt: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectReceipt: (state, { payload }) => {
      state.selectedReceipt = payload;
    },
    editReceipt: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
      state.selectedReceipt = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllreceipts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllreceipts.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalRows;
        state.loading = false;
        state.params = action.payload.params;
      })
      .addCase(getData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      });
  },
});

export const { addReceipt, selectReceipt, editReceipt } = receiptslice.actions;
export default receiptslice.reducer;
