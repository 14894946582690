// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import { isArray } from "lodash";

export const getAllAmenities = createAsyncThunk(
  "amenities/getAllData",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      let params = {
        params: {
          options: {
            query: {},
            populate: [
              {
                path: "createdBy",
                dir: "users",
                select: "_id username",
              },
            ],
          },
        },
      };
      const response = await axios.get("amenities", params);
      if (response.status !== 200) {
        return rejectWithValue(response.data.message);
      }
      const docs = response.data?.data?.docs || [];
      return { docs, totalRows: response.data?.data?.totalDocs };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getData = createAsyncThunk(
  "amenities/getData",
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.get("amenities", {
        params: { options: params },
      });

      if (response.status !== 200) {
        return rejectWithValue(response.data.message);
      }

      return {
        params,
        data: response.data?.data?.docs,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAmenity = createAsyncThunk(
  "amenities/update",
  async ({ formdata, id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.patch(`amenities/${id}`, formdata);
      if (response.status !== 200) {
        return rejectWithValue(response.data.message);
      }

      return isArray(response.data?.data)
        ? response.data?.data[0]
        : response.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createAmenity = createAsyncThunk(
  "amenities/create",
  async (formdata, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.post("amenities", formdata);
      if (response.status !== 200) {
        return rejectWithValue(response.data.message);
      }
      return response.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAmenity = createAsyncThunk(
  "amenities/delete",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(`amenities/${id}`);
      if (response.status !== 204) {
        return rejectWithValue(response.data.message);
      }
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const amenitieslice = createSlice({
  name: "amenities",
  initialState: {
    data: [],
    total: 0,
    params: {},
    loading: false,
    error: null,
  },
  reducers: {
    addAmenity: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectAmenity: (state, { payload }) => {
      state.selectedAmenity = payload;
    },
    editAmenity: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAmenities.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllAmenities.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.total = action.payload.totalRows;
        state.loading = false;
      })
      .addCase(getAllAmenities.rejected, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      })
      .addCase(updateAmenity.fulfilled, (state, action) => {
        const updatedAmenity = action.payload;
        state.data = state.data.map((d) =>
          d._id === updatedAmenity._id ? updatedAmenity : d
        );
      })
      .addCase(createAmenity.fulfilled, (state, action) => {
        const createdAmenity = action.payload;

        if (Array.isArray(createdAmenity)) {
          state.data.push(...createdAmenity);
        } else {
          state.data.push(createdAmenity);
        }
      })

      .addCase(deleteAmenity.fulfilled, (state, action) => {
        const deletedAmenityId = action.payload;
        state.data = state.data.filter((d) => d._id !== deletedAmenityId);
      });
  },
});

export const { addAmenity, selectAmenity, editAmenity } = amenitieslice.actions;
export default amenitieslice.reducer;
