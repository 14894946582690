import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
// ** Reactstrap Imports
import {
  Row,
  Col,
  Label,
  Form,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Alert,
} from "reactstrap";

// ** Third Party Components
import * as yup from "yup";
import toast from "react-hot-toast";
import axios from "axios";

// ** Custom Yup Validation Schema
import { yupResolver } from "@hookform/resolvers/yup";

// ** Redux RTK Query Imports
import { useGetAgentsQuery } from "../../../../../redux/agents";
import {
  useGetAgentEarnsByAgentIdQuery,
  useCreateAgentEarnsMutation,
} from "../../../../../redux/agentEarns";
import CreatableSelect from "react-select";

const EarnCommissionModal = ({ show, setShow }) => {
  // ** states
  const [isNew, setIsnew] = useState(false);
  const [pages, setPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAgent, setSelectedAgent] = useState({
    label: "Select agent",
    value: "",
    balance: 0,
  });
  const [accounts, setAccounts] = useState([]);
  const [today, setToday] = useState(new Date().now);

  // ** RTK Query Mutations
  const [createAgentEarns, { isLoading, isError, error }] =
    useCreateAgentEarnsMutation();
  const { data: agents } = useGetAgentsQuery({
    page: pages,
    perPage: perPage,
    keyword: searchTerm,
  });

  console.log("selectedAgent.value", selectedAgent.value);

  const { data: agentEarns } = useGetAgentEarnsByAgentIdQuery({
    agentId: selectedAgent.value,
    page: pages,
    limit: perPage,
  });

  // ** Save & Add New Agent
  const CommissionSchema = yup.object().shape({
    agent: yup.string().required("Please select agent"),
    amount: yup.number().required("Please enter amount"),
    account: yup.string().required("Please enter account"),
    description: yup.string(),
    date: yup.date().required("Please enter date"),
  });

  // ** useForm hook vars
  const {
    reset,
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reselvover: yupResolver(CommissionSchema),
  });

  // ** Handles form submission
  const onSubmit = async (values) => {
    console.log("values: ", values);
    try {
      const { agent, amount, account, description, date } = values;
      const payload = {
        agent: agent,
        amount,
        account: account.value,
        description,
        date,
      };

      console.log("payload: ", payload);

      await createAgentEarns(payload).unwrap();
      toast.success("Commission earned successfully");
      reset();
      setShow(false);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const getAccounts = async () => {
    try {
      var { status, data } = await axios.get(
        "/accountings/accounts/hierarchy/64089b489d122d411c67abe4"
      );
      if (status == 200) {
        setAccounts(data?.data ?? []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  var agentsOptions = agents?.data?.docs?.map((agent) => ({
    label: agent.name,
    value: agent._id,
  }));

  if (agentsOptions) {
    agentsOptions = [{ label: "Select agent", value: "" }, ...agentsOptions];
  }

  useEffect(() => {
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const today = formatDate(new Date());

    setToday(today);
  }, []);

  // calculate agentEarn balance
  useEffect(() => {
    if (selectedAgent.value) {
      const agentEarnsBalance = agentEarns?.data;

      console.log("agentEarnsBalance: ", agentEarnsBalance);
      console.log(
        "agentEarnsBalance: ",
        agentEarnsBalance?.length
          ? agentEarnsBalance[agentEarnsBalance.length - 1].balance
          : 0
      );

      // calculate the balance as the latest balance
      const balance = agentEarnsBalance?.length
        ? agentEarnsBalance[agentEarnsBalance.length - 1].balance
        : 0;
      setValue("balance", 0);
      setValue("balance", balance);
    }
  }, [watch("agent")]);

  useEffect(() => {
    getAccounts();
  }, []);

  // ** Handles form discard
  const handleDiscard = () => {
    reset();
    setShow(false);
  };

  // ** Handles error toast
  useEffect(() => {
    if (isError) {
      const message =
        error.data?.message || "Something went wrong. Please try again later.";
      toast.error(message);
    }
  }, [isError, error]);

  return (
    <Modal
      isOpen={show}
      onClosed={handleDiscard}
      toggle={() => setShow(!show)}
      className="modal-lg"
    >
      <ModalHeader
        className="bg-transparent"
        toggle={() => setShow(!show)}
      ></ModalHeader>
      <ModalBody className="px-sm-5 pb-5">
        <div className="text-center mb-2">
          <h1 className="mb-1">Earn New Commission</h1>
        </div>

        {error && (
          <Alert color="danger">
            <h6 className="alert-heading">Error {error.status}!</h6>
            <div className="alert-body">
              <p>{JSON.stringify(error.data.message)}</p>
            </div>
          </Alert>
        )}

        <Row tag={Form} onSubmit={handleSubmit(onSubmit)}>
          <Col xs={12} md={6} className="mb-1">
            <Label className="form-label" for="agent">
              agent
            </Label>

            <Controller
              control={control}
              id="agent"
              name="agent"
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <CreatableSelect
                  id="agent"
                  className={"react-select"}
                  classNamePrefix="select"
                  aria-invalid={errors.agent && true}
                  placeholder={
                    selectedAgent.label ? selectedAgent.label : "Choose agent"
                  }
                  value={
                    agentsOptions.find((c) => c.value === field.value) || null
                  }
                  options={agentsOptions}
                  {...field}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption ? selectedOption.value : "");
                    setSelectedAgent({
                      label: selectedOption ? selectedOption.label : "",
                      value: selectedOption ? selectedOption.value : "",
                    });
                    console.log("selectedOption: ", selectedOption.value);
                  }}
                />
              )}
            />

            {errors.status && <FormFeedback>choose agent</FormFeedback>}
          </Col>
          <Col xs={12} md={6} className="mb-1">
            <Label className="form-label" for="amount">
              amount
            </Label>
            <Controller
              control={control}
              id="amount"
              name="amount"
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="amount"
                  invalid={errors.amount && true}
                />
              )}
            />
            {errors.amount && (
              <FormFeedback>Please enter a valid amount</FormFeedback>
            )}
          </Col>
          <Col xs={12} md={6} className="mb-1">
            <Label className="form-label" for="account">
              Account
            </Label>
            <Controller
              name="account"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CreatableSelect
                  id="account"
                  className="react-select"
                  classNamePrefix="select"
                  invalid={errors.account && true}
                  {...register("account", { required: true })}
                  {...field}
                  options={accounts.map((ac) => ({
                    value: ac._id,
                    label: `${ac.name}`,
                    isDisabled: ac.systemAccount,
                  }))}
                />
              )}
            />
            {errors.account && (
              <FormFeedback>Please enter account</FormFeedback>
            )}
          </Col>

          <Col xs={12} md={6} className="mb-1">
            <Label className="form-label" for="description">
              description
            </Label>
            <Controller
              control={control}
              id="description"
              name="description"
              defaultValue=""
              rules={{ required: true }}
              {...register("description")}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="description"
                  invalid={errors.description && true}
                />
              )}
            />
            {errors.description && (
              <FormFeedback>please enter a description</FormFeedback>
            )}
          </Col>
          <Col xs={12} md={6} className="mb-1">
            <Label className="form-label" for="date">
              Date
            </Label>
            <Controller
              name="date"
              control={control}
              defaultValue={today} // Set the default value here
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  id="date"
                  type="date"
                  invalid={errors.date && true}
                  {...field}
                />
              )}
            />
            {errors.date && (
              <FormFeedback>please enter a valid date number</FormFeedback>
            )}
          </Col>
          {/* <Col xs={12} md={6} className="mb-1">
            <Label className="form-label" for="balance">
              balance
            </Label>
            <Controller
              control={control}
              id="balance"
              name="balance"
              defaultValue=""
              rules={{ required: true }}
              {...register("balance")}
              render={({ field }) => (
                <Input {...field} readOnly placeholder="balance" />
              )}
            />
          </Col> */}
          <Col xs={12} className="text-center mt-2">
            <Button className="me-1" color="primary" disabled={isLoading}>
              {isLoading && !isNew ? "Please, wait..." : "Earn comission"}
            </Button>
            <Button
              outline
              type="submit"
              onClick={() => setIsnew(!isNew)}
              disabled={isLoading}
            >
              {isLoading && isNew ? "Please, wait..." : "Save & Add New"}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default EarnCommissionModal;
