import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, getToken } from "../utility/Utils";

export const agentEarnsApi = createApi({
  reducerPath: "agentEarns",
  tagTypes: ["AgentEarns"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // get agent earns
    getAgentEarns: builder.query({
      query: ({ page = 1, perPage = 10, keyword = "" }) => {
        const params = new URLSearchParams();
        if (keyword || keyword !== "") {
          params.append("page", page);
          params.append("search", keyword);
          return `/hrm/agentEarns?${params.toString()}`;
        }

        params.append("options[page]", page);
        params.append("options[limit]", perPage);

        return `/hrm/agentEarns?${params.toString()}`;
      },
      providesTags: ["AgentEarns"],
    }),

    // get agent earns list
    getAgentEarnsList: builder.query({
      query: () => {
        return "/hrm/agentEarns";
      },
    }),

    // get agent earns by agent id
    getAgentEarnsByAgentId: builder.query({
      query: ({ agentId, page = 1, limit = 10 }) => {
        const params = new URLSearchParams();
        params.append("page", page);
        params.append("limit", limit);
        return `/hrm/agentEarns/${agentId}?${params.toString()}`;
      },
      providesTags: ["AgentEarns"],
    }),

    // create agent earns
    createAgentEarns: builder.mutation({
      query: (body) => ({ url: "/hrm/agentEarns/", method: "POST", body }),
      invalidatesTags: ["AgentEarns"],
    }),
  }),
});

export const {
  useGetAgentEarnsQuery,
  useGetAgentEarnsListQuery,
  useGetAgentEarnsByAgentIdQuery,
  useCreateAgentEarnsMutation,
} = agentEarnsApi;
