// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAllSectionals = createAsyncThunk(
  "sectionals/getAllData",
  async ({ buildingId, status }, { getState }) => {
    let bodyContent = {
      params: {
        query: {
          buildingId,
          status,
        },
        options: {
          populate: [
            {
              path: "buildingId",
              dir: "buildings",
              select: "_id name",
            },
          ],
        },
      },
    };

    const response = await axios.get("/sectionals", bodyContent);
    const docs = response.data?.data?.docs || [];
    return { docs, totalRows: response.data?.data?.totalDocs, bodyContent };
  }
);

export const getData = createAsyncThunk(
  "sectionals/getData",
  async (params) => {
    const response = await axios.get("/sectionals", {
      params: {
        options: {
          query: params,
          populate: [
            {
              path: "buildingId",
              dir: "buildings",
              select: "_id name",
            },
          ],
        },
      },
    });
    return {
      params,
      data: response.data?.data?.docs,
    };
  }
);
export const updateSectional = createAsyncThunk(
  "Sectionals/update",
  async ({ formdata, id }, { dispatch, getState }) => {
    try {
      var response = await axios.patch(`/sectionals/${id}`, formdata);

      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      }

      return response.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createSectional = createAsyncThunk(
  "sectionals/create",
  async (formdata, { dispatch, getState, rejectWithValue }) => {
    formdata.append("buildingId", getState().CurrentBuilding?.data?._id);

    try {
      var response = await axios.post("/sectionals", formdata);

      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      }

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSectional = createAsyncThunk(
  "sectionals/delete",
  async (id, { dispatch, getState }) => {
    try {
      const response = await axios.delete(`/sectionals/${id}`);
      if (response.status != 204) {
        return rejectWithValue("Can't Delete Try Again");
      }
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sectionalSlice = createSlice({
  name: "sectionals",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    selectedSectional: null,
    loading: false,
  },
  reducers: {
    addSectional: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectSectional: (state, { payload }) => {
      state.selectedSectional = payload;
    },
    editSectional: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSectionals.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllSectionals.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalRows;
        state.params = action.payload.bodyContent;
        state.loading = false;
      })
      .addCase(getData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      })
      .addCase(createSectional.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.selectedSectional = null;
        state.allData.push(payload);
        state.data.push(payload);
      })
      .addCase(updateSectional.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.data = state.data.map((d) => {
          if (d._id == payload._id) {
            return payload;
          }
          return d;
        });
        state.allData = state.allData.map((da) => {
          if (da._id == payload._id) {
            return payload;
          }
          return da;
        });
        state.selectedSectional = null;
      })
      .addCase(deleteSectional.fulfilled, (state, { payload, error }) => {
        state.loading = false;
        state.error = error;
        const deletedUnit = payload;
        state.data = state.data.filter((d) => d._id !== deletedUnit);
      });
  },
});
export const { addSectional, selectSectional, editSectional } =
  sectionalSlice.actions;
export default sectionalSlice.reducer;

//! The following is an RTL query api implementation with still no applied in the system.
// apiSlice.js

import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQueryFunction } from "../utility/Utils";

export const sectionalApi = createApi({
  reducerPath: "sectionalReducer",
  baseQuery: fetchBaseQueryFunction,
  endpoints: (builder) => ({
    getAllSectionals: builder.query({
      query: ({ page = 1, perPage = 10, buildingId = null }) => {
        const params = new URLSearchParams();
        if (buildingId) {
          params.set("query[buildingId]", buildingId);
        }
        params.set("query[page]", page);
        params.set("query[limit]", perPage);

        return `/sectionals?${decodeURIComponent(params.toString())}`;
      },
    }),
    getData: builder.query({
      query: (params) => `/sectionals?options=${params.options}`,
    }),
    updateSectional: builder.mutation({
      query: ({ id, formdata }) => ({
        url: `/sectionals/${id}`,
        method: "PATCH",
        body: formdata,
      }),
    }),
    createSectional: builder.mutation({
      query: (formdata) => ({
        url: "/sectionals",
        method: "POST",
        body: formdata,
      }),
    }),
    deleteSectional: builder.mutation({
      query: (id) => ({
        url: `/sectionals/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllSectionalsQuery,
  useGetDataQuery,
  useUpdateSectionalMutation,
  useCreateSectionalMutation,
  useDeleteSectionalMutation,
} = sectionalApi;
