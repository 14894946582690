import moment from "moment/moment";
import { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import {
  AlertTriangle,
  ChevronDown,
  Eye,
  MoreVertical,
  RefreshCcw,
} from "react-feather";
import ReactPaginate from "react-paginate";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  convertDaysToMonthsAndYears,
  formatCurrency,
} from "../../../utility/Utils";
import classNames from "classnames";
import { EyeFill, Link45deg, ReceiptCutoff } from "react-bootstrap-icons";
import { useNavigate, useParams } from "react-router-dom";

const Agreements = ({ store }) => {
  const [sort, setSort] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const { id } = useParams();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [agreements, setAgreements] = useState(
    store.data.agreements?.slice(0, rowsPerPage)
  );
  const columns = [
    {
      name: "Property",
      sortable: true,
      selector: (row) => row.unit?.name ?? "",
    },
    {
      name: "Amount",
      sortable: true,
      selector: (row) => row.amount ?? "",
      cell: (row) => <Badge color="light-primary">${row.amount ?? ""}</Badge>,
    },

    {
      name: "Start Date",
      sortable: true,
      sortField: "email",
      selector: (row) => row.startDate ?? "",
      cell: (row) => (
        <div className="">
          {moment(row.startDate).format("DD MMM YYYY") ?? ""}
        </div>
      ),
    },
    {
      name: "End Date",
      sortable: true,
      sortField: "email",
      selector: (row) => row.endDate ?? "",
      cell: (row) => (
        <div className="">
          {moment(row.endDate).format("DD MMM YYYY") ?? ""}
        </div>
      ),
    },
    {
      name: "Remaining",
      sortable: true,
      selector: (row) => null,
      cell: (row) => (
        <div
          className={classNames("fw-bolder", {
            "text-danger":
              moment(row.endDate).diff(moment()) < 0 && row.status == "Active",
          })}
        >
          {moment(row.endDate).diff(moment()) < 0 && row.status != "Active" ? (
            0
          ) : row.status == "Terminated" ? (
            0
          ) : moment(row.endDate).diff(moment()) < 0 ? (
            <>
              <AlertTriangle
                size={14}
                className="text-danger align-middle me-1"
              />
              Expired
            </>
          ) : (
            <>
              {" "}
              {convertDaysToMonthsAndYears(
                moment(row.endDate).diff(moment(), "days")
              )}
            </>
          )}
        </div>
      ),
    },
    {
      name: "Status",
      minWidth: "100px",
      sortable: true,
      sortField: "status",
      selector: (row) => row.status,
      cell: (row) => (
        <Badge
          color={row.status.toLowerCase() == "active" ? "success" : "danger"}
          className="text-capitalize"
        >
          <span className="">{row.status}</span>
        </Badge>
      ),
    },
    // {
    //   name: "Docs",

    //   sortable: true,
    //   sortField: "status",
    //   selector: (row) => row.documents,
    //   cell: (row) => (
    //     <UncontrolledDropdown className="">
    //       <DropdownToggle
    //         tag="button"
    //         className="btn btn-sm h-100 btn-primary"
    //         size="10px"
    //         color="primary"
    //       >
    //         <EyeFill size={16} className="text-white" />
    //       </DropdownToggle>
    //       <DropdownMenu>
    //         {row.documents.map((doc) => (
    //           <DropdownItem
    //             key={doc._id}
    //             tag="a"
    //             target={"_blank"}
    //             className="w-100"
    //             href={`${themeConfig.serverUrl}uploads/${doc.documentPath}`}
    //           >
    //             <FileEarmarkPdf size={14} /> {doc.name}
    //           </DropdownItem>
    //         ))}
    //       </DropdownMenu>
    //     </UncontrolledDropdown>
    //   ),
    // },

    {
      name: "Actions",
      minWidth: "100px",
      cell: (row) => (
        <div className="d-flex">
          <UncontrolledDropdown className="">
            <DropdownToggle tag="div" className="btn btn-sm h-100 px-1 ">
              <MoreVertical size={14} className="cursor-pointer" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                tag="a"
                href="/"
                className="w-100"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedRow(row);
                  setGenerateModal(true);
                }}
              >
                <ReceiptCutoff size={14} className="me-50" />
                <span className="align-middle">Generate Invoice</span>
              </DropdownItem>
              {row.status != "Terminated" ? (
                <DropdownItem
                  tag="a"
                  href="/"
                  className="w-100"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedRow(row);
                    setTerminateModal(true);
                  }}
                >
                  <Link45deg size={14} className="me-50" />
                  <span className="align-middle">Terminate</span>
                </DropdownItem>
              ) : null}

              <DropdownItem
                tag="a"
                href="/"
                className="w-100"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedRow(row);
                  setRenewModal(true);
                }}
              >
                <RefreshCcw size={14} className="me-50" />
                <span className="align-middle">Renew</span>
              </DropdownItem>

              <DropdownItem
                tag="a"
                href="/"
                className="w-100"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/leases/${row._id}`);
                }}
              >
                <Eye size={14} className="me-50" />
                <span className="align-middle">View Detail</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      ),
    },
  ];

  const handlePagination = (page) => {
    const startIndex = page.selected * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const currentPageData = store.data.agreements.slice(startIndex, endIndex);

    setInvoices(currentPageData);
    setCurrentPage(page.selected + 1);
  };

  const CustomPagination = () => {
    const count = Number(Math.ceil(store.data.agreements.length / rowsPerPage));

    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  return (
    <Fragment>
      <Card>
        <CardHeader tag="h4">
          <CardTitle>Customers's Agreement List</CardTitle>
          <Button
            color="primary"
            onClick={() => navigate(`/leases/create?customer=${id}`)}
          >
            New Agreement
          </Button>
        </CardHeader>
        <CardBody>
          <div className="react-dataTable user-view-account-projects">
            <DataTable
              subHeader
              sortServer
              pagination
              responsive
              paginationServer
              striped
              columns={columns}
              sortIcon={<ChevronDown />}
              className="react-dataTable react-dataTable-selectable-rows"
              paginationComponent={CustomPagination}
              data={agreements}
            />
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Agreements;
