// ** React Imports
import { Fragment, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  FormFeedback,
  ModalFooter,
  Alert,
  Spinner,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";

import axios from "axios";

import { ErrorHandler } from "../../../common/utils/Error";
import moment from "moment";
import { useEffect } from "react";
import Select from "react-select";

const CancelForm = ({ show, onClose, invoice }) => {
  const [loading, setLoading] = useState(false);
  const [customerInvoices, setcustomerInvoices] = useState([]);
  const [customerLeases, setcustomerLeases] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [CustomerInvoicesloading, setInvoicesloading] = useState(false);

  const defaultValues = {
    invoice: invoice || "",
    reason: "",
  };

  const {
    register,
    reset,
    control,
    getValues,
    setError,
    trigger,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      data.invoice = invoice;

      var inv = await axios.post("/accountings/invoices/cancel", data);
      if (inv.status == 200) {
        toast.success(`Invoice Canceled Successfully`);
        reset(defaultValues);
        onClose();
      }
    } catch (error) {
      ErrorHandler(error);
    }
    setLoading(false);
  };

  const onDiscard = () => {
    clearErrors();
    reset(defaultValues);
    onClose();
  };

  return (
    <Fragment>
      <Modal isOpen={show} onClosed={onDiscard} toggle={onClose} className="">
        <ModalHeader toggle={onClose} className="bg-white">
          Refund Information
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="">
            {/* <h1 className="address-title  mb-1"></h1> */}

            <Row className="gy-1 gx-2 flex-column mb-1">
              <Col xs={12}>
                <Label className="form-label" for="reason">
                  Reason
                </Label>
                <Controller
                  name="reason"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="reason"
                      type="textarea"
                      invalid={errors.reason && true}
                      {...field}
                    />
                  )}
                />
                {errors.reason && (
                  <FormFeedback>Please enter Valid reason</FormFeedback>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button
              type="reset"
              className=""
              color="secondary"
              outline
              onClick={onDiscard}
            >
              Close
            </Button>
            <Button
              type="submit"
              className="me-1"
              color="success"
              disabled={loading}
            >
              <Spinner
                color="light"
                size="sm"
                className="me-1"
                hidden={!loading}
              />
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default CancelForm;
