import { AxiosError } from "axios";
import { toast } from "react-hot-toast";

export const ErrorHandler = (error) => {
  var message;

  if (error instanceof AxiosError) {
    var { response } = error;
    if (response) {
      message = response.data.message;
    } else {
      message = error.message;
    }
  } else {
    if (typeof error == "string") {
      message = error;
    } else {
      if (error.message) {
        message = error.message;
      } else {
        message = error.toString();
      }
    }
  }

  toast.error(message);
};
