import { convertToRaw, EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CreatableSelect from "react-select";
import draftToHtml from "draftjs-to-html";
import moment from "moment";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import "@styles/react/libs/editor/editor.scss";
import axios, { AxiosError } from "axios";
import { ErrorHandler } from "../../../common/utils/Error";
import HandleDispatch from "../../../common/utils/HandledDispatch";
import { useDispatch, useSelector } from "react-redux";
import { createStaff, updateStaff } from "../../../redux/staff";
import { toast } from "react-hot-toast";
import ReactSelect from "react-select";
import MultipleFileUpload from "./components/MultipleFileUpload";
import SingleFileUploader from "@commons/utils/components/SingleFileUploader";
import classNames from "classnames";
import { CloudFog } from "react-bootstrap-icons";

export default function AgreementForm() {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [editorValue, setEditorValue] = useState(EditorState.createEmpty());
  const [documents, setDocuments] = useState([]);
  const [image, setImage] = useState([]);

  const [jobs, setJobs] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);

  const [staff, setStaff] = useState({});

  const [params] = useSearchParams();
  const staffId = params.get("staff");

  const getStaffData = async () => {
    try {
      const { data, status } = await axios.get(`/hrm/staff/${staffId}`, {
        params: {
          options: {},
          populate: [
            {
              dir: "hrm/jobs",
              path: "job",
              select: "name",
            },
            {
              dir: "hrm/departments",
              path: "department",
              select: "name",
            },
            {
              dir: "hrm/shifts",
              path: "shift",
              select: "name",
            },
            {
              dir: "users",
              path: "user",
              select: "role username",
              populate: {
                path: "role",
                dir: "roles",
                select: "name",
              },
            },
          ],
        },
      });

      if (status == 200) {
        setStaff(data.data);
        reset(data.data);
        setValue("username", data.data.user.username);
        setValue("job", {
          label: data.data.job.name,
          value: data.data.job._id,
        });
        setValue("department", {
          label: data.data.department.name,
          value: data.data.department._id,
        });
        setValue("shift", {
          label: data.data.shift?.name,
          value: data.data.shift?._id,
        });
        setValue(
          "dateOfBirth",
          moment(data.data.dateOfBirth).format("YYYY-MM-DD")
        );
        setValue("hireDate", moment(data.data.hireDate).format("YYYY-MM-DD"));
        setValue("role", {
          label: data.data.user.role.name,
          value: data.data.user.role._id,
        });
        setValue("status", {
          label: data.data.status,
          value: data.data.status,
        });
      } else {
        throw Error(data.message);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  const getAllJobs = async () => {
    try {
      const { data, status } = await axios.get("/hrm/jobs", {
        params: {
          options: {
            select: "name",
          },
        },
      });

      if (status == 200) {
        var jobList = data.data.docs.map((job) => ({
          label: job.name,
          value: job._id,
        }));
        setJobs(jobList);
      } else {
        throw Error(data.message);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };
  const getAllShifts = async () => {
    try {
      const { data, status } = await axios.get("/hrm/shifts", {
        params: {
          options: {
            select: "name",
          },
        },
      });

      if (status == 200) {
        var shiftList = data.data.docs.map((shift) => ({
          label: shift.name,
          value: shift._id,
        }));
        setShifts(shiftList);
      } else {
        throw Error(data.message);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };
  const getAllDepartments = async () => {
    try {
      const { data, status } = await axios.get("/hrm/departments", {
        params: {
          options: {
            select: "name",
          },
        },
      });

      if (status == 200) {
        var departmentList = data.data.docs.map((department) => ({
          label: department.name,
          value: department._id,
        }));
        setDepartments(departmentList);
      } else {
        throw Error(data.message);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };
  const getAllRoles = async () => {
    try {
      const { data, status } = await axios.get("/roles", {
        params: {
          options: {
            select: "name",
          },
        },
      });

      if (status == 200) {
        var rolesList = data.data.docs.map((role) => ({
          label: role.name,
          value: role._id,
        }));
        setRoles(rolesList);
      } else {
        throw Error(data.message);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    getAllJobs();
    getAllShifts();
    getAllDepartments();
    getAllRoles();
    if (staffId) {
      getStaffData();
    }
  }, []);

  var initialValues = {
    name: "",
    email: "",
    job: "",
    shift: "",
    department: "",
    role: "",
    dateOfBirth: "",
    address: "",
    contacts: "",
    emergencyContactName: "",
    emergencyContact: "",
    motherName: "",
    placeOfBirth: "",
    monthlySalary: "",
    hireDate: "",
    role: "",
    username: "",
    password: "",
    status: "",
  };

  const {
    reset,
    control,
    register,
    clearErrors,
    getValues,
    setValue,
    setFocus,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: initialValues });

  const onSubmit = async (values) => {
    setloading(true);

    // if (!CurrentBuilding?.data) {
    //   toast.error("First Login in as Property")
    //   return;

    var formdata = new FormData();

    for (const key in values) {
      formdata.append(key, values[key]);
    }

    console.log(formdata);
    // }

    try {
      values.job = values.job?.value;
      values.shift = values.shift?.value;
      values.department = values.department?.value;
      values.role = values.role?.value;
      // if(values.status !== undefined){
      values.status = values.status?.value;
      // }

      var formdata = new FormData();

      for (const key in values) {
        formdata.append(key, values[key]);
      }

      documents.forEach((file, i) => {
        formdata.append(`documents`, file, file.name);
      });

      if (image.length) {
        formdata.append(`image`, image[0], image[0].name);
      }

      if (staffId) {
        let updatedData = {};
        let counter = 0;
        for (let i in staff) {
          if (staff[i] !== values[i]) {
            updatedData[i] = values[i];

            if (staff.user.username !== values.username) {
              updatedData["username"] = values.username;
            }
            if (updatedData[i] == staff[i]._id) {
              delete updatedData[i];
            }
            if (values[i]?.role?._id == staff.user.role._id) {
              delete updatedData[i];
            }
            if (updatedData[i] == moment(staff.hireDate).format("YYYY-MM-DD")) {
              delete updatedData[i];
            }
            if (
              updatedData[i] == moment(staff.dateOfBirth).format("YYYY-MM-DD")
            ) {
              delete updatedData[i];
            }
            if (updatedData[i] == staff.documents[counter]) {
              delete updatedData[i];
              counter++;
            }
          }
        }

        console.log("updated fields == ", updatedData);

        if (Object.keys(updatedData).length === 0)
          return toast.error("Please Update Data");

        var response = await HandleDispatch(
          dispatch,
          updateStaff({ data: updatedData, id: staffId })
        );
        if (response) return toast.success("Staff Updated Successfully");
      } else {
        console.log("create section");
        var response = await HandleDispatch(dispatch, createStaff(formdata));

        if (response) {
          reset();
          setImage([]);
          setDocuments([]);
          toast.success("New Staff Created Successfully");
        }
      }
    } catch (error) {
      console.log("err=", error);
      toast.error(error.toString());
    } finally {
      setloading(false);
    }
  };

  return (
    <div>
      <Breadcrumb listClassName="breadcrumb-dashes">
        <BreadcrumbItem>
          <Link to="/"> Home </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/hrm/staff"> Staffs </Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>
          <Link to="/hrm/staff/create"> Create </Link>
        </BreadcrumbItem>
      </Breadcrumb>

      <Card>
        <CardHeader>
          <CardTitle>Staffs Form</CardTitle>
        </CardHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardBody>
            <Row className="gy-2">
              <Col sm={12} md={4}>
                <Label className="form-label" for="name">
                  Name
                </Label>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="name"
                      placeholder="Name"
                      invalid={errors.name && true}
                      {...field}
                    />
                  )}
                />
                {errors.name && (
                  <FormFeedback>Please enter a valid Name</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="email">
                  Email
                </Label>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="email"
                      placeholder="Email"
                      invalid={errors.email && true}
                      {...field}
                    />
                  )}
                />
                {errors.email && (
                  <FormFeedback>Please enter a valid Email</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="placeOfBirth">
                  Place Of Birth
                </Label>
                <Controller
                  name="placeOfBirth"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="placeOfBirth"
                      placeholder="Place Of Birth"
                      invalid={errors.placeOfBirth && true}
                      {...field}
                    />
                  )}
                />
                {errors.placeOfBirth && (
                  <FormFeedback>Please enter a valid Name</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="dateOfBirth">
                  Date Of Birth
                </Label>
                <Controller
                  name="dateOfBirth"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="dateOfBirth"
                      type="date"
                      placeholder="Date Of Birth"
                      invalid={errors.dateOfBirth && true}
                      {...field}
                    />
                  )}
                />
                {errors.dateOfBirth && (
                  <FormFeedback>Please enter a valid Date</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="contacts">
                  Phone
                </Label>
                <Controller
                  name="contacts"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="contacts"
                      type="text"
                      placeholder="Phone"
                      invalid={errors.contacts && true}
                      {...field}
                    />
                  )}
                />
                {errors.contacts && (
                  <FormFeedback>Please enter a valid Phone</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="address">
                  Address
                </Label>
                <Controller
                  name="address"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="address"
                      type="text"
                      placeholder="Address"
                      invalid={errors.address && true}
                      {...field}
                    />
                  )}
                />
                {errors.address && (
                  <FormFeedback>Please enter a valid Address</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="motherName">
                  Mother Name
                </Label>
                <Controller
                  name="motherName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="motherName"
                      placeholder="Mother Name"
                      invalid={errors.motherName && true}
                      {...field}
                    />
                  )}
                />
                {errors.motherName && (
                  <FormFeedback>Please enter a valid Email</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="emergencyContactName">
                  Emergency Name
                </Label>
                <Controller
                  name="emergencyContactName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="emergencyContactName"
                      type="text"
                      placeholder="Emergency Name"
                      invalid={errors.emergencyContactName && true}
                      {...field}
                    />
                  )}
                />
                {errors.emergencyContactName && (
                  <FormFeedback>Please enter a valid Name</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="emergencyContact">
                  Emergency Phone
                </Label>
                <Controller
                  name="emergencyContact"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="emergencyContact"
                      type="text"
                      placeholder="Emergency Phone"
                      invalid={errors.emergencyContact && true}
                      {...field}
                    />
                  )}
                />
                {errors.emergencyContact && (
                  <FormFeedback>Please enter a valid Phone</FormFeedback>
                )}
              </Col>

              <Col sm={12} md={4}>
                <Label className="form-label" for="job">
                  Select Job
                </Label>
                <Controller
                  name="job"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CreatableSelect
                      id="job"
                      className={"react-select"}
                      classNamePrefix="select"
                      aria-invalid={errors.job && true}
                      placeholder="Choose Job"
                      options={jobs}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="shift">
                  Select Shift
                </Label>
                <Controller
                  name="shift"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CreatableSelect
                      id="shift"
                      className={"react-select"}
                      classNamePrefix="select"
                      aria-invalid={errors.shift && true}
                      placeholder="Choose shift"
                      options={shifts}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="department">
                  Select Department
                </Label>
                <Controller
                  name="department"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CreatableSelect
                      id="department"
                      className={"react-select"}
                      classNamePrefix="select"
                      aria-invalid={errors.department && true}
                      placeholder="Choose department"
                      options={departments}
                      {...field}
                    />
                  )}
                />
              </Col>

              <Col sm={12} md={4}>
                <Label className="form-label" for="hireDate">
                  Hire Date
                </Label>
                <Controller
                  name="hireDate"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="hireDate"
                      type="date"
                      placeholder="Hire Date"
                      invalid={errors.hireDate && true}
                      {...field}
                    />
                  )}
                />
                {errors.hireDate && (
                  <FormFeedback>Please enter a valid Date</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="monthlySalary">
                  Monthly Salary
                </Label>
                <Controller
                  name="monthlySalary"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="monthlySalary"
                      placeholder="Monthly Salary"
                      invalid={errors.monthlySalary && true}
                      {...field}
                    />
                  )}
                />
                {errors.monthlySalary && (
                  <FormFeedback>Please enter a valid Email</FormFeedback>
                )}
              </Col>

              <Col sm={12} md={4}>
                <Label className="form-label" for="status">
                  Select Status
                </Label>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <CreatableSelect
                      id="status"
                      className={"react-select"}
                      classNamePrefix="select"
                      aria-invalid={errors.status && true}
                      placeholder="Choose status"
                      options={[
                        { label: "Active", value: "active" },
                        { label: "InActive", value: "inactive" },
                      ]}
                      {...field}
                    />
                  )}
                />
              </Col>

              <Col sm={12} md={4}>
                <Label className="form-label" for="role">
                  Select Role
                </Label>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <CreatableSelect
                      id="role"
                      className={"react-select"}
                      classNamePrefix="select"
                      aria-invalid={errors.role && true}
                      placeholder="Choose Role"
                      options={roles}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col sm={12} md={4}>
                <Label className="form-label" for="username">
                  User Name
                </Label>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="username"
                      placeholder="User Name"
                      invalid={errors.username && true}
                      {...field}
                    />
                  )}
                />
                {errors.username && (
                  <FormFeedback>Please enter a valid UserName</FormFeedback>
                )}
              </Col>
              <Col sm={12} md={4}>
                {!staffId && (
                  <>
                    <Label className="form-label" for="password">
                      Password
                    </Label>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <Input
                          id="password"
                          type="password"
                          placeholder="Password"
                          invalid={errors.password && true}
                          {...field}
                        />
                      )}
                    />
                    {errors.password && (
                      <FormFeedback>Please enter a valid Password</FormFeedback>
                    )}
                  </>
                )}
              </Col>

              <Col sm={12} md={6}>
                <Label className="form-label">Staff Photo</Label>
                <SingleFileUploader
                  title={"Staff Photo"}
                  files={image}
                  setFiles={setImage}
                />
              </Col>
              <Col sm={12} md={6}>
                <Label className="form-label">createStaffoc</Label>
                <MultipleFileUpload
                  files={documents}
                  setFiles={setDocuments}
                  fileTypes={{
                    "application/pdf": [".pdf"],
                  }}
                />
              </Col>
            </Row>

            <div className="text-center mt-3 mb-1">
              <Button color="success" type="submit" disabled={loading}>
                <Spinner
                  color="light"
                  size="sm"
                  className="me-1"
                  hidden={!loading}
                />
                {loading
                  ? "Submitting..."
                  : staffId
                  ? "Save Changes"
                  : "Submit"}
              </Button>
              <Button
                color="secondary"
                outline
                className="ms-1"
                type="reset"
                onClick={() => navigate("/hrm/staff")}
              >
                Cancel
              </Button>
            </div>
          </CardBody>
        </form>
      </Card>
    </div>
  );
}
