// ** React imports
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";

// ** Store & Actions

// ** Icons Import
import { FiAlignRight, FiChevronRight, FiChevronLeft } from "react-icons/fi";

// ** Reactstrap Imports
import { Row, Col, Alert, Spinner, Button } from "reactstrap";

// ** User View Components
import AgentDetails from "./AgentDetails";
import AgentInfo from "./AgentInfo";

// ** Styles
import "@styles/react/apps/app-users.scss";

import { useGetAgentByIdQuery } from "../../../../redux/agents";
import AgentTabs from "./Tabs";

const AgentsProfile = () => {
  const { id } = useParams();
  const [show, setShow] = useState(true);

  const { data, isSuccess, isLoading } = useGetAgentByIdQuery(id);

  // toggle show
  const toggleShow = () => {
    setShow(!show);
  };

  const [active, setActive] = useState("1");

  const toggleTab = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };

  const baseStyle = {
    transition: "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
    position: "relative",
    // left: "-100%",
    opacity: 0,
    // width: "100%",
    // height: "100%",
  };

  const showStyle = {
    transform: "translateX(0)",
    opacity: 1,
  };

  const hideStyle = {
    transform: "translateX(-100%)",
    opacity: 0,
    width: "0",
    height: "0",
  };

  // Combine base styles with conditional styles
  const cardStyle = {
    ...baseStyle,
    ...(show ? showStyle : hideStyle),
  };

  return data ? (
    <div>
      <div className="app-user-view">
        <Row>
          <div
            className=""
            style={{
              height: "100%",
              width: "100%",
              position: "relative",
            }}
          >
            <button
              onClick={toggleShow}
              style={{
                width: "40px",
                height: "40px",
                position: "relative",
                top: "-13px",
                border: "2px solid #7367f0",
                borderRadius: ".5rem",
                background: "none",
                color: "#7367f0",

                textAlign: "center",
              }}
            >
              {show ? (
                <FiChevronRight size={20} />
              ) : (
                <FiChevronLeft size={20} />
              )}
            </button>
          </div>
          <Col
            xl="4"
            lg="5"
            xs={{ order: 1 }}
            md={{ order: 0, size: 5 }}
            // className={`card-container ${show ? "show" : "hide"}`}

            style={cardStyle}
          >
            <AgentInfo agent={data} />
          </Col>
          <Col
            xl={
              show
                ? "8"
                : "12" /* change the width of the main content when the sidebar is hidden */
            }
            lg={show ? "7" : "12"}
            xs={{ order: 0 }}
            md={{ order: 1, size: 7 }}
          >
            <AgentTabs active={active} toggleTab={toggleTab} agent={data} />
          </Col>
        </Row>
      </div>
    </div>
  ) : (
    <div
      className="text-center "
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Spinner color="primary" size="lg" />
    </div>
  );
};

export default AgentsProfile;
