// ** React Imports
import { useState, Fragment, useRef } from "react";

// ** Table Columns
import { columns } from "./columns";

// ** Reactstrap Imports
import { Button, UncontrolledTooltip } from "reactstrap";

// ** Third party Components
import DataTable from "react-data-table-component";
import { ChevronDown, Edit, Trash } from "react-feather";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// ** Styles
import "animate.css/animate.css";
import "@styles/react/libs/tables/react-dataTable-component.scss";
import "@styles/base/plugins/extensions/ext-component-sweet-alerts.scss";

import AddShiftModal from "../Modals/AddShift";
import UpdateShiftModal from "../Modals/UpdateShift";

import {
  useGetShiftsQuery,
  useDeleteShiftMutation,
} from "../../../../../redux/shifts";
import { toast } from "react-hot-toast";
import CustomHeader from "./CustomHeader";
import CustomPagination from "./CustomPagination";
import LoadingShiftsTable from "./LoadingShiftsTable";

import _ from "lodash";
import Loading from "../../../../loading";

const ShiftsTable = () => {
  // ** Store Vars & Hooks
  const MySwal = withReactContent(Swal);

  const shiftRef = useRef(null);

  // ** States
  const [showAddShiftModal, setShowAddShiftModal] = useState(false);
  const [showUpdateShiftModal, setShowUpdateShiftModal] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data, isLoading } = useGetShiftsQuery({
    page: currentPage,
    perPage: rowsPerPage,
    keyword: searchTerm,
  });

  const [deleteShift] = useDeleteShiftMutation();

  // ** Function in get data on rows per page
  const handlePerPage = (e) => {
    const value = parseInt(e.currentTarget.value);
    setRowsPerPage(value);
  };

  const handleEditClick = (data) => {
    shiftRef.current = data;
    setShowUpdateShiftModal(true);
  };

  const updatedColumns = [
    ...columns,
    {
      name: "Actions",
      cell: (row) => {
        return (
          <div className="d-flex align-items-center permissions-actions">
            <Button
              id="update-btn"
              size="sm"
              color="transparent"
              className="btn btn-icon"
              onClick={() => handleEditClick(row)}
            >
              <Edit className="font-medium-2" />
              <UncontrolledTooltip placement="top" target="update-btn">
                Update
              </UncontrolledTooltip>
            </Button>
            <Button
              id="delete-btn"
              size="sm"
              color="transparent"
              className="btn btn-icon"
              onClick={() => handleDeleteClick(row)}
            >
              <Trash className="font-medium-2 text-danger" />
              <UncontrolledTooltip placement="top" target="delete-btn">
                Delete
              </UncontrolledTooltip>
            </Button>
          </div>
        );
      },
    },
  ];

  const handleDeleteClick = (data) => {
    return MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async function (result) {
      if (result.value) {
        const res = await deleteShift(data._id);
        if (res.data !== undefined) toast.success("Shift deleted successfully");
      }
    });
  };

  //   if (isLoading) return <LoadingShiftsTable />;

  const renderData = () => {
    const calculateIndex = (index) => {
      return (currentPage - 1) * rowsPerPage + index + 1;
    };
    const shifts = Array.isArray(data?.data)
      ? data.data
      : data?.data?.docs ?? [];
    return shifts.map((item, index) => {
      return { ...item, index: calculateIndex(index) };
    });
  };

  return (
    <Fragment>
      <div className="react-dataTable">
        {isLoading ? (
          <Loading cols={columns} />
        ) : (
          // store.loading ? <Loading cols={columns} /> :
          <DataTable
            noHeader
            pagination
            subHeader
            responsive
            paginationServer
            columns={updatedColumns}
            sortIcon={<ChevronDown />}
            className="react-dataTable"
            paginationComponent={() => (
              <CustomPagination
                data={data}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                rowsPerPage={rowsPerPage}
              />
            )}
            data={renderData()}
            subHeaderComponent={
              <CustomHeader
                setShow={setShowAddShiftModal}
                searchTerm={searchTerm}
                handleSearch={(term) => setSearchTerm(term)}
                handleClearResults={() => setSearchTerm("")}
                rowsPerPage={rowsPerPage}
                handlePerPage={handlePerPage}
              />
            }
          />
        )}
      </div>
      <AddShiftModal show={showAddShiftModal} setShow={setShowAddShiftModal} />
      <UpdateShiftModal
        shift={shiftRef.current}
        show={showUpdateShiftModal}
        setShow={setShowUpdateShiftModal}
      />
    </Fragment>
  );
};

export default ShiftsTable;
