import { Controller, useForm } from "react-hook-form";
import CreatableSelect from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import InputPasswordToggle from "@components/input-password-toggle";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import toast from "react-hot-toast";
import { ErrorHandler } from "../../../../common/utils/Error";
import HandleDispatch from "../../../../common/utils/HandledDispatch";

import * as yup from "yup";
import { updateUser } from "../../../../redux/users";

const schema = yup.object().shape({
  username: yup.string().min(4).required(),
  password: yup.string().min(4).required(),
  confirmPassword: yup
    .string()
    .min(4)
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  // role: yup.object().required(),
  status: yup.object().required(),
});

const Security = ({ agent }) => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // console.log("agent", agent);

  const defaultValues = {
    id: agent?.data?.user?._id || "",
    username: agent?.data?.user?.username || "",
    password: "",
    confirmPassword: "",
    status: agent?.data?.user?.status || "",
  };
  const {
    register,
    reset,
    getValues,
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  const onSubmit = async (data) => {
    console.log("data:", data);
    // console.log("id:", agent?.data?.user);
    try {
      setLoading(true);
      if (data.status) data.status = data.status.value;
      delete data.role;

      const updateResponse = await HandleDispatch(
        dispatch,
        updateUser({ id: agent?.data?.user, user: data })
      );

      if (updateResponse) {
        toast.success("Agent user updated Successfully");
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const statusMapping = {
      active: { label: "Active", value: "active" },
      inactive: { label: "Inactive", value: "inactive" },
    };

    const mapValue = (sourceValue, mapping) => {
      return mapping[sourceValue] || {};
    };

    let values = {
      ...defaultValues,
      status: mapValue(defaultValues?.status, statusMapping),

      role: roles.find((role) => role.value === defaultValues?.role),
    };

    reset(values);
  }, [agent, roles]);

  return (
    <Fragment>
      <Card>
        <CardHeader tag="h4">Agent Credentials</CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="gy-2">
              <Col sm={12} md={12}>
                <Label className="form-label" for="username">
                  Username
                </Label>
                <Controller
                  name="username"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="username"
                      placeholder="username"
                      invalid={errors.username && true}
                      {...field}
                    />
                  )}
                />

                {errors.username && (
                  <FormFeedback className="d-block">
                    {errors.username.message}
                  </FormFeedback>
                )}
              </Col>

              <Col sm={12} md={6}>
                <Label className="form-label" for="status">
                  Status
                </Label>
                <Controller
                  name="status"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CreatableSelect
                      id="status"
                      className={"react-select"}
                      classNamePrefix="select"
                      aria-invalid={errors.status && true}
                      placeholder="Choose status"
                      options={[
                        { label: "Active", value: "active" },
                        { label: "InActive", value: "inactive" },
                      ]}
                      {...field}
                    />
                  )}
                />

                {errors.status && (
                  <FormFeedback className="d-block">
                    {errors.status.message}
                  </FormFeedback>
                )}
              </Col>
              <Col className="mb-2" md={6}>
                <Controller
                  id="password"
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <InputPasswordToggle
                      label="New Password"
                      htmlFor="password"
                      className="input-group-merge"
                      invalid={errors.password && true}
                      {...field}
                    />
                  )}
                />
                {errors.password && (
                  <FormFeedback className="d-block">
                    {errors.password.message}
                  </FormFeedback>
                )}
              </Col>
              <Col className="mb-2" md={6}>
                <Controller
                  control={control}
                  id="confirmPassword"
                  name="confirmPassword"
                  render={({ field }) => (
                    <InputPasswordToggle
                      label="Confirm New Password"
                      htmlFor="confirmPassword"
                      className="input-group-merge"
                      invalid={errors.confirmPassword && true}
                      {...field}
                    />
                  )}
                />
                {errors.confirmPassword && (
                  <FormFeedback className="d-block">
                    {errors.confirmPassword.message}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={12} className="d-flex justify-content-center">
                <Button type="submit" color="primary" disabled={loading}>
                  <Spinner
                    color="light"
                    size="sm"
                    className="me-1"
                    hidden={!loading}
                  />
                  {loading ? "Saving..." : "Save Changes"}
                </Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Security;
