// ** React Imports
import { Fragment, useEffect, useState } from "react";

// ** Reactstrap Imports
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

// ** Third Party Components
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

// ** Utils

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { ErrorHandler } from "../../../common/utils/Error";
import HandleDispatch from "../../../common/utils/HandledDispatch";
import {
  createBooking,
  selectBooking as selectBookingReducer,
  updateBooking,
} from "../../../redux/booking";
import { useGetAgentListQuery } from "../../../redux/agents";

const countryOptions = [
  { value: "uk", label: "UK" },
  { value: "usa", label: "USA" },
  { value: "france", label: "France" },
  { value: "russia", label: "Russia" },
  { value: "canada", label: "Canada" },
];
// const schema = yup.object().shape({
//   name: yup.string().required(),
//   floors: yup.number().required(),
//   price: yup.number().required(),
//   type: yup.string().required(),
// });

const AddNewAddress = ({ show, ShowModal, data: dataArr }) => {
  console.log("dataArr", dataArr.sectionals);
  const selectedBooking = useSelector(
    (state) => state.bookings.selectedBooking
  );

  const userData = useSelector((state) => state.authentication.userData);

  const customersState = useSelector((state) => state.customers.allData);
  const CurrentBuilding = useSelector((state) => state.CurrentBuilding.data);

  const { data: agents } = useGetAgentListQuery({});

  const [agentsOptions, setAgentsOptions] = useState([]);

  const [loading, setLoading] = useState(false);

  const defaultValues = {
    unit: "",
    customer: "",
    discount: "",
    amount: "",
    agents: "",
  };

  // ** Hooks
  const {
    register,
    reset,
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      if (!CurrentBuilding) {
        toast.error("You must select/login Building to Create booking");
        return;
      }

      if (selectedBooking) {
        data.building = CurrentBuilding?._id;
        let newData = await HandleDispatch(
          dispatch,
          updateBooking({ id: selectedBooking?._id, data })
        );
        if (newData) {
          toast.success("Booking Information updated Successfully");
          reset();
          dataArr.getAvailableSectionals();
          ShowModal();
        }
      } else {
        data.building = CurrentBuilding?._id;
        const responseData = await HandleDispatch(
          dispatch,
          createBooking(data)
        );
        if (responseData) {
          toast.success("New Booking Created Successfully");
          reset();
          dataArr.getAvailableSectionals();
          ShowModal();
        }
      }
    } catch (error) {
      ErrorHandler(error);
    }
    setLoading(false);
  };

  var apartments = dataArr.sectionals?.map((sec) => ({
    value: sec?._id,
    label: `
    ${sec?.name} - $${sec?.price} - (${sec?.rooms})rooms - floor-${sec?.locatedFloor}
    `,
  }));

  if (apartments.length) {
    apartments = [{ value: "building", label: `All Building` }, ...apartments];
  }

  var customers = customersState?.map((cus) => ({
    value: cus._id,
    label: cus.name,
  }));
  const onDiscard = () => {
    clearErrors();
    ShowModal(false);
    dispatch(selectBookingReducer(null));
    reset();
  };

  useEffect(() => {
    if (userData?.role?.name === "admin" || userData?.role?.name === "super") {
      const list = agents?.data?.map((agent) => ({
        value: agent._id,
        label: agent.name,
      }));
      setAgentsOptions(list);
    } else {
      const list = agents?.data
        ?.filter((agent) => agent?.user === userData?._id)
        ?.map((agent) => ({
          value: agent._id,
          label: agent.name,
        }));
      setAgentsOptions(list);
    }
  }, [agents]);

  return (
    <Fragment>
      <Modal isOpen={show} onClosed={onDiscard} toggle={ShowModal} className="">
        <ModalHeader
          className="bg-transparent"
          toggle={ShowModal}
        ></ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="pb-5 px-sm-4 mx-50">
            <h3 className="address-title  mb-1">
              {" "}
              {selectedBooking ? "Update Application" : "New Application"}{" "}
            </h3>

            <Row className="gy-1 gx-2">
              <Col xs={12} md={12}>
                <Label className="form-label" for="customer">
                  Select Customer
                </Label>
                <Controller
                  name="customer"
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="customer"
                      className="react-select"
                      classNamePrefix="select"
                      invalid={errors.customer && true}
                      {...register("customer", { required: true })}
                      {...field}
                      placeholder={"Select Customer"}
                      value={customers.find((b) => b.value == field.value)}
                      options={customers ?? []}
                      onChange={(v) => field.onChange(v.value)}
                    />
                  )}
                />
                {errors.name && (
                  <FormFeedback>{JSON.stringify(errors.name)}</FormFeedback>
                )}
              </Col>

              <Col xs={12} md={12}>
                <Label className="form-label" for="unit">
                  Select Unit
                </Label>
                <Controller
                  name="unit"
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="unit"
                      className="react-select"
                      classNamePrefix="select"
                      invalid={errors.unit && true}
                      {...register("unit", { required: true })}
                      {...field}
                      value={apartments.find((b) => b.value == field.value)}
                      options={apartments ?? []}
                      onChange={(v) => {
                        field.onChange(v.value);
                        if (v.value == "building") {
                          var amount = dataArr.sectionals?.find(
                            (sec) => sec._id == v.value
                          );
                          setValue("amount", amount?.price ?? 0);
                        } else {
                          var amount = dataArr.sectionals?.find(
                            (sec) => sec._id == v.value
                          );
                          setValue("amount", amount?.reservationFee ?? 0);
                        }
                      }}
                    />
                  )}
                />
                {errors.unit && (
                  <FormFeedback>Sectional Is Required</FormFeedback>
                )}
              </Col>

              <Col xs={12} md={12}>
                <Label className="form-label" for="amount">
                  Reservation Fee
                </Label>

                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="amount"
                      type="number"
                      {...register("amount", { required: true })}
                      step={"any"}
                      placeholder="0"
                      invalid={errors.amount && true}
                      {...field}
                    />
                  )}
                />
                {errors.amount && (
                  <FormFeedback>Please enter a Amount</FormFeedback>
                )}
              </Col>

              <Col xs={12} md={12}>
                <Label className="form-label" for="agent">
                  Agent (optional)
                </Label>
                <Controller
                  name="agent"
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="agent"
                      className="react-select"
                      classNamePrefix="select"
                      invalid={errors.agent && true}
                      {...register("agent", { required: true })}
                      {...field}
                      placeholder={"Select Agent"}
                      value={agentsOptions?.find(
                        (b) => b.value == field?.value
                      )}
                      options={
                        agentsOptions
                          ? [
                              { value: "", label: "Select Agent" },
                              ...agentsOptions,
                            ]
                          : []
                      }
                      onChange={(v) => field.onChange(v.value)}
                    />
                  )}
                />
                {errors.name && (
                  <FormFeedback>{JSON.stringify(errors.name)}</FormFeedback>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col className="text-center" xs={12}>
                <Button
                  type="submit"
                  className="me-1 mt-2"
                  color={selectedBooking ? "success" : "primary"}
                  disabled={loading}
                >
                  <Spinner
                    color="light"
                    size="sm"
                    className="me-1"
                    hidden={!loading}
                  />

                  {selectedBooking ? "Save Changes" : "Submit"}
                </Button>
                <Button
                  type="reset"
                  className="mt-2"
                  color="secondary"
                  outline
                  onClick={onDiscard}
                >
                  Discard
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default AddNewAddress;
