// ** React Imports
import { Fragment, useEffect, useState } from "react";

// ** Reactstrap Imports
import {
  Alert,
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

// ** Third Party Components
import HandleDispatch from "@commons/utils/HandledDispatch";
import { AlertTriangle } from "react-feather";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

// ** Utils

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
import axios from "axios";
import moment from "moment/moment";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { ErrorHandler } from "../../../common/utils/Error";
import {
  createReceipt,
  selectReceipt,
  updateReceipt,
} from "../../../redux/receipt";
import { formatCurrency } from "../../../utility/Utils";
import { useContext } from "react";
import { CurrentBuildingContext } from "../../../contexts/currentBuilding";
import { useParams } from "react-router-dom";

const ReceiptForm = ({ show, ShowModal, data, appView }) => {
  const selectedReceipt = useSelector(
    (state) => state.receipts.selectedReceipt
  );

  const { id } = useParams();
  const [useBalance, setuseBalance] = useState(false);
  const [customerBalance, setCustomerBalance] = useState(0);

  const customersState = useSelector((state) => state.customers.allData);
  const sectionals = useSelector((state) => state.sectionals.allData);
  const CurrentBuilding = useSelector((state) => state.CurrentBuilding.data);
  const store = useSelector((state) => state.receipts);

  const { currentBuilding: selectedBuilding } = useContext(
    CurrentBuildingContext
  );

  const defaultValues = {
    type: appView ? appView : "invoice",
    customer: "",
    invoiceId: "",
    accountId: "",
    balance: 0,
    paid: 0,
    overpaidOption: "accountRecievable",
    amount: 0,
    method: "",
    date: moment().format("YYYY-MM-DD"),
    reference: "",
    description: "",
  };

  // ** Hooks
  const {
    register,
    reset,
    control,
    setValue,
    getValues,
    clearErrors,
    setError,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const dispatch = useDispatch();

  const [customerInvoices, setcustomerInvoices] = useState([]);
  const [customerLeases, setcustomerLeases] = useState([]);
  const [loading, setLoading] = useState(false);

  const [CustomerInvoicesloading, setInvoicesloading] = useState(false);

  const getCustomerInvoices = async (idx) => {
    setInvoicesloading(true);
    try {
      let cust = getValues().customer?.value;
      var { status, data } = await axios.get(
        `accountings/invoices/customer/${cust}/${idx}`
      );
      if (status == 200) {
        if (data.data?.length) {
          setcustomerInvoices(data.data);
        } else {
          setValue("invoiceId", "");
          setcustomerInvoices([]);
        }
      } else {
        setValue("invoiceId", "");
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setInvoicesloading(false);
    }
  };
  const getCustomerInvoicesType = async (idx) => {
    setInvoicesloading(true);
    try {
      var { status, data } = await axios.get(
        `accountings/invoices/customer/${idx}` +
          (appView ? `?type=${appView}` : "")
      );
      if (status == 200) {
        if (data.data?.length) {
          setcustomerInvoices(data.data);
        } else {
          setValue("invoiceId", "");
          setcustomerInvoices([]);
        }
      } else {
        setValue("invoiceId", "");
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setInvoicesloading(false);
    }
  };

  const getCustomerLeases = async (idx) => {
    setInvoicesloading(true);
    try {
      var { status, data } = await axios.get(
        `/agreements/customer/${idx}/${selectedBuilding?._id}`
      );
      if (status == 200) {
        if (data.data?.length) {
          setcustomerLeases(data.data);
          setValue("invoiceId", "");
        } else {
          setValue("agreement", "");
          setValue("invoiceId", "");
          setcustomerLeases([]);
        }
      } else {
        setValue("agreement", "");
        setValue("invoiceId", "");
      }
      setcustomerInvoices([]);
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setInvoicesloading(false);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      if (!CurrentBuilding && !appView) {
        toast.error("Please Select Property");
        setLoading(false);
        return;
      }

      if (selectedReceipt) {
        data.building = CurrentBuilding._id;
        (data.amount = data.paid),
          (data.customer = data.customer.value),
          (data.invoiceId = data.invoiceId.value),
          (data.accountId = data.accountId.value),
          (data.method = data.method.value);
        data.useBalance = useBalance;

        const Ndata = await HandleDispatch(
          dispatch,
          updateReceipt({ id: selectedReceipt._id, data })
        );
        if (Ndata) {
          setCustomerBalance(0);
          setuseBalance(false);
          toast.success("Receipt updated Succesfully");
          reset();

          ShowModal();
        }
      } else {
        const responseData = await HandleDispatch(
          dispatch,
          createReceipt({
            ...data,
            amount: data.paid,
            customer: data.customer.value,
            invoiceId: data.invoiceId.value,
            accountId: data.accountId.value,
            method: data.method.value,
            useBalance,
          })
        );
        if (responseData) {
          toast.success("New Reciept Created Succesfully");
          setCustomerBalance(0);
          setuseBalance(false);
          reset();
          reset();
          ShowModal();
        }
      }
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const onDiscard = () => {
    clearErrors();
    ShowModal(false);
    dispatch(selectReceipt(null));
    reset(defaultValues);
  };

  const formatLeases = () => {
    let leases =
      customerLeases.map((cust) => ({
        value: cust._id,
        label: `${cust.unit?.name} - ${cust.building?.name}`,
      })) ?? [];

    return [
      {
        value: "other",
        label: `Other Invoices`,
      },
      ...leases,
    ];
  };

  useEffect(() => {
    if (selectedReceipt) {
      reset({
        ...selectedReceipt,
        customer: {
          value: selectedReceipt?.invoiceId?.customer?._id,
          label: selectedReceipt?.invoiceId?.customer?.name,
        },
        accountId: {
          value: selectedReceipt?.accountId?._id,
          label: selectedReceipt?.accountId?.name ?? "",
        },
        invoiceId: {
          value: selectedReceipt?.invoiceId?._id,
          label: selectedReceipt?.invoiceId?.date ?? "",
        },
      });
    }
  }, [selectedReceipt]);

  const getCustomerBalance = async (cid) => {
    setCustomerBalance(0);
    setuseBalance(false);
    try {
      var { status, data } = await axios.get(`/customers/balance/${cid}`);
      if (status == 200) {
        if (data.data?.balance) {
          setCustomerBalance(data.data?.balance);
        } else {
          setCustomerBalance(0);
        }
      } else {
        setCustomerBalance(0);
        setuseBalance(false);
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  useEffect(() => {
    const customer = data?.customers.find((customer) => customer._id == id);
    if (customer) {
      reset({
        ...defaultValues,
        customer: {
          value: customer?._id,
          label: customer?.name,
        },
      });
      getCustomerLeases(customer._id);
    }
  }, [show]);

  return (
    <Fragment>
      <Modal
        isOpen={show}
        onClosed={onDiscard}
        toggle={onDiscard}
        className="modal-lg"
        backdrop="static"
      >
        <ModalHeader className="bg-transparent" toggle={onDiscard}>
          {selectedReceipt ? "Update Receipt" : "Add New Receipt"}{" "}
        </ModalHeader>

        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="pb-5 px-sm-4 mx-50">
            <Row className="gy-1 gx-2">
              <Col xs={12} md={6} className="">
                <Label className="form-label" for="date">
                  Receipt Date
                </Label>

                <Controller
                  name="date"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="date"
                      type="date"
                      invalid={errors.date}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col xs={12} md={6}>
                <Label className="form-label" for="reference">
                  Reference
                </Label>

                <Controller
                  name="reference"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="reference"
                      type="text"
                      {...register("reference")}
                      invalid={errors.reference}
                      {...field}
                    />
                  )}
                />
              </Col>

              <Col xs={12} md={appView ? 6 : 4}>
                <Label className="form-label" for="customer">
                  Select Customer
                </Label>
                <Controller
                  name="customer"
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="customer"
                      className="react-select"
                      classNamePrefix="select"
                      invalid={errors.customer && true}
                      {...register("customer", { required: true })}
                      {...field}
                      placeholder={"Select Customer"}
                      // value={customers.find(b=>b.value==field.value)}
                      options={
                        data.customers.map((cust) => ({
                          value: cust._id,
                          label: cust.name,
                        })) ?? []
                      }
                      onChange={(v) => {
                        field.onChange(v);

                        if (appView) {
                          getCustomerInvoicesType(v.value);
                        } else {
                          getCustomerLeases(v.value);
                        }
                        if (v) {
                          getCustomerBalance(v.value);
                        }
                      }}
                    />
                  )}
                />
                {errors.name && (
                  <FormFeedback>{JSON.stringify(errors.name)}</FormFeedback>
                )}
              </Col>
              {!appView ? (
                <Col xs={12} md={4}>
                  <Label className="form-label" for="agreement">
                    Select Lease
                  </Label>
                  <Controller
                    name="agreement"
                    control={control}
                    render={({ field }) => (
                      <Select
                        id="agreement"
                        className="react-select"
                        classNamePrefix="select"
                        invalid={errors.agreement && true}
                        {...field}
                        placeholder={"Select Lease"}
                        // value={customers.find(b=>b.value==field.value)}
                        options={formatLeases()}
                        onChange={(v) => {
                          if (!getValues().customer) {
                            toast.error("Select Customer");
                            return;
                          }
                          field.onChange(v);
                          getCustomerInvoices(v.value);
                        }}
                      />
                    )}
                  />
                  {errors.name && (
                    <FormFeedback>{JSON.stringify(errors.name)}</FormFeedback>
                  )}
                </Col>
              ) : null}

              <Col xs={12} md={appView ? 6 : 4}>
                <Label className="form-label" for="invoiceId">
                  Select Invoice
                </Label>
                <Controller
                  name="invoiceId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="invoiceId"
                      className="react-select"
                      classNamePrefix="select"
                      isLoading={CustomerInvoicesloading}
                      invalid={errors.invoiceId && true}
                      {...register("invoiceId", { required: true })}
                      {...field}
                      options={customerInvoices.map((inv) => ({
                        value: inv._id,
                        label: `${inv?.invoiceNo ?? ""} - ${
                          inv.type
                        } - ${moment(inv.date).format(
                          "DD/MM/YYYY"
                        )} - ${formatCurrency(inv.balance)}  `,
                      }))}
                      onChange={(v) => {
                        field.onChange(v);
                        var invoice = customerInvoices?.find(
                          (sec) => sec._id == v.value
                        );
                        setValue("amount", invoice?.balance ?? 0);
                        if (useBalance) {
                          setValue(
                            "paid",
                            invoice?.balance <= customerBalance
                              ? invoice?.balance
                              : customerBalance ?? 0
                          );
                        }
                      }}
                    />
                  )}
                />
                {errors.invoiceId && (
                  <FormFeedback>Invoice Is Required</FormFeedback>
                )}
              </Col>

              {/* <Col xs={12} md={6}>
                <Label className="form-label" for="method">
                  Method
                </Label>
                <Controller
                  name="method"
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="method"
                      className="react-select"
                      classNamePrefix="select"
                      invalid={errors.method && true}
                      {...register("method", { required: true })}
                      {...field}
                      options={PaymentOptions ?? []}
                    />
                  )}
                />
                {errors.method && (
                  <FormFeedback>Receipt Method Is Required</FormFeedback>
                )}
              </Col> */}

              <Col xs={12} md={4}>
                <Label className="form-label" for="amount">
                  Amount Due
                </Label>

                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="amount"
                      type="number"
                      {...register("amount", { required: true })}
                      step={"any"}
                      placeholder="0"
                      readOnly
                      invalid={errors.amount && true}
                      {...field}
                    />
                  )}
                />
                {errors.amount && (
                  <FormFeedback>Please enter a Amount</FormFeedback>
                )}
              </Col>

              <Col xs={12} md={4}>
                <Label className="form-label" for="paid">
                  Paid
                </Label>

                <Controller
                  name="paid"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="number"
                      type="number"
                      {...register("paid", { required: true })}
                      step={"any"}
                      placeholder="0"
                      invalid={errors.paid && true}
                      {...field}
                      onChange={(e) => {
                        var value = e.target.value;
                        var { amount } = getValues();
                        // if (value > amount) {
                        //   setError("paid", {
                        //     message: `$${value} is greater than amount due ($${amount})`,
                        //   });
                        //   return;
                        // } else {
                        //   clearErrors("paid");
                        // }
                        setValue("balance", amount - value);
                        trigger("balance");
                        field.onChange(value);
                      }}
                    />
                  )}
                />
                {errors.paid && (
                  <FormFeedback>{errors.paid.message}</FormFeedback>
                )}
              </Col>
              <Col xs={12} md={4}>
                <Label className="form-label" for="balance">
                  Balance
                </Label>

                <Controller
                  name="balance"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="number"
                      type="number"
                      invalid={errors.balance}
                      step={"any"}
                      readOnly
                      placeholder="0"
                      {...field}
                    />
                  )}
                />
              </Col>

              {getValues().paid > getValues().amount || !useBalance ? (
                <>
                  <Col xs={12} md={6}>
                    <Label className="form-label" for="accountId">
                      Deposit to
                    </Label>
                    <Controller
                      name="accountId"
                      control={control}
                      render={({ field }) => (
                        <Select
                          id="accountId"
                          className="react-select"
                          classNamePrefix="select"
                          invalid={errors.accountId && true}
                          {...register("accountId", { required: true })}
                          {...field}
                          options={data.accounts.map((ac) => ({
                            value: ac._id,
                            label: `${ac.name}`,
                            isDisabled: ac.systemAccount,
                          }))}
                        />
                      )}
                    />
                    {errors.accountId && (
                      <FormFeedback>Receipt Account Required</FormFeedback>
                    )}
                  </Col>
                  {/* <Col xs={12} md={6}>
                    <Label className="form-label" for="accountId">
                      Deposit
                    </Label>
                    <Controller
                      name="accountId"
                      control={control}
                      render={({ field }) => (
                        // <Select
                        //   id="accountId"
                        //   className="react-select"
                        //   classNamePrefix="select"
                        //   invalid={errors.accountId && true}
                        //   {...register("accountId", { required: true })}
                        //   {...field}
                        //   options={data.accounts.map((ac) => ({
                        //     value: ac._id,
                        //     label: `${ac.name}`,
                        //     isDisabled: ac.systemAccount,
                        //   }))}
                        // />
                        <TreeSelect
                          id={`accountId`}
                          // value={field.value.value}
                          onChange={(val) => {
                            let accName =
                              accounts.find((acc) => acc._id == val.value)
                                .name ?? "";

                            field.onChange({
                              value: val.value,
                              label: accName,
                            });
                          }}
                          inputRef={field.ref}
                          filter
                          options={formatTreeNodes()}
                          placeholder="Select Item"
                          // className={classNames("w-100", {
                          //   "p-invalid": fieldState.error,
                          // })}
                        />
                      )}
                    />
                    {errors.accountId && (
                      <FormFeedback>Receipt Account Required</FormFeedback>
                    )}
                  </Col> */}
                </>
              ) : null}

              {getValues().balance < 0 ? (
                <Col xs={12} md={6} className="">
                  <Label className="form-label" for="balance">
                    Invoice Over Payment
                  </Label>
                  <div className=" ">
                    <div className="form-check form-check-warning">
                      <Controller
                        name="overpaidOption"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="radio"
                            id="account-recievable-radio"
                            {...field}
                            value={"accountRecievable"}
                            checked={field.value == "accountRecievable"}
                          />
                        )}
                      />
                      <Label
                        className="form-check-label"
                        for="account-recievable-radio"
                      >
                        Credit Account Receivable
                      </Label>
                    </div>
                    <div className="form-check form-check-warning">
                      <Controller
                        name="overpaidOption"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="radio"
                            id="customer-credit-radio"
                            {...field}
                            value={"customerCredit"}
                            checked={field.value == "customerCredit"}
                          />
                        )}
                      />

                      <Label
                        className="form-check-label"
                        for="customer-credit-radio"
                      >
                        Customer Credit
                      </Label>
                    </div>
                  </div>
                </Col>
              ) : null}
            </Row>
            {getValues().customer && customerBalance ? (
              <Alert
                color="warning"
                className="p-1 mt-2 d-flex justify-content-between align-items-center mb-0"
              >
                <div className="">
                  <AlertTriangle className="align-middle m-0 me-1" size={16} />
                  <span className="form-check-label" for="warning-checkbox">
                    Customer Have Credit balance off{" "}
                    <span style={{ fontWeight: "900" }}>
                      {formatCurrency(customerBalance)}{" "}
                    </span>
                  </span>
                </div>
                <Button
                  outline
                  color="warning"
                  size="sm"
                  onClick={() => {
                    if (useBalance) {
                      setValue("paid", 0);
                      setValue("balance", getValues().amount);
                    } else {
                      let paid =
                        getValues().amount <= customerBalance
                          ? getValues().amount
                          : customerBalance ?? 0;
                      setValue("paid", paid);
                      setValue("balance", getValues().amount - paid);
                    }
                    setuseBalance(!useBalance);
                  }}
                >
                  {useBalance ? "Cancel" : "Apply"}
                </Button>
              </Alert>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button
              type="reset"
              className="mt-2"
              color="secondary"
              outline
              onClick={onDiscard}
            >
              Discard
            </Button>
            <Button
              type="submit"
              size="md"
              className="me-1 mt-2"
              color={selectedReceipt ? "success" : "primary"}
              disabled={loading}
            >
              <Spinner
                color="light"
                size="sm"
                className="me-1"
                hidden={!loading}
              />
              {loading
                ? "Submitting..."
                : selectedReceipt
                ? "Save Changes"
                : "Submit"}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default ReceiptForm;
