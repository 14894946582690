// ** React Imports
import { Fragment, useState } from "react";

// ** Reactstrap Imports
import { Badge, Button, Card, CardBody } from "reactstrap";

// ** Third Party Components

// ** Custom Components
import Avatar from "@components/avatar";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";

const roleColors = {
  editor: "light-info",
  admin: "light-danger",
  author: "light-warning",
  maintainer: "light-success",
  subscriber: "light-primary",
};

const statusColors = {
  active: "light-success",
  pending: "light-warning",
  inactive: "light-secondary",
};

const AgentInfo = ({ agent }) => {
  // ** States

  //   ** render user avatar
  const renderAvatar = () => {
    if (agent) {
      return (
        <Avatar
          initials
          color="light-primary"
          content={agent.data?.name}
          className="rounded mt-3 mb-2"
          contentStyles={{
            borderRadius: 50,
            fontSize: "calc(48px)",
            width: "100%",
            height: "100%",
          }}
          style={{
            height: "110px",
            width: "110px",
            borderRadius: "50%",
          }}
        />
      );
    }
  };

  return (
    <>
      <Fragment>
        <Card>
          <CardBody>
            <div className="user-avatar-section">
              <div className="d-flex align-items-center flex-column">
                {renderAvatar()}
                <div className="d-flex flex-column align-items-center text-center">
                  <div className="user-info">
                    <h4 className="">{agent.data?.name}</h4>
                    {agent.data !== null ? (
                      <Badge
                        color={roleColors[agent.data?.role]}
                        className="text-capitalize"
                      >
                        {agent.data?.role || "agent"}
                      </Badge>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <h4 className="fw-bolder border-bottom pb-50 mb-1">Details</h4>
            <div className="info-container">
              {agent.data && (
                <ul className="list-unstyled">
                  <li className="mb-75 d-flex justify-content-between">
                    {" "}
                    <span className="fw-bolder">Email:</span>{" "}
                    {agent.data?.email}
                  </li>
                  <li className="mb-75 d-flex justify-content-between">
                    <span className="fw-bolder">Phone:</span>{" "}
                    {agent.data?.phone}
                  </li>
                  <li className="mb-75 d-flex justify-content-between">
                    <span className="fw-bolder">Gender:</span>{" "}
                    {agent.data?.gender}
                  </li>
                  <li className="mb-75 d-flex justify-content-between">
                    {" "}
                    <span className="fw-bolder">Commission:</span>{" "}
                    {agent.data?.commissionType === "percentage"
                      ? `${agent.data?.commissionAmount}%`
                      : agent.data?.commissionAmount}
                  </li>
                  <li className="mb-75 d-flex justify-content-between">
                    {" "}
                    <span className="fw-bolder">Status:</span>{" "}
                    <Badge color={statusColors[agent.data?.status]}>
                      {agent.data?.status}
                    </Badge>
                  </li>
                </ul>
              )}
            </div>
          </CardBody>
        </Card>
      </Fragment>
    </>
  );
};

export default AgentInfo;
