import React from "react";
import { useState } from "react";
import { DollarSign, Grid, Plus, User, UserPlus, Users } from "react-feather";
import {
  Button,
  Col,
  NavLink,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "reactstrap";

import JournalEntry from "../../../views/accountings/journal/journalEnteryForm";
import {
  Building,
  BuildingAdd,
  Cash,
  CashStack,
  DoorOpen,
  JournalText,
  Journals,
  People,
} from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function QuickActions() {
  const [quickActionOpen, setQuickActionOpen] = useState(false);
  const navigate = useNavigate();

  const [journalForm, setJournalForm] = useState(false);

  const toggleQuickActions = () => {
    setQuickActionOpen(false);
  };

  const toggleJournalForm = () => {
    setJournalForm(false);
  };

  const menuList = [
    {
      title: "People",
      submenus: [
        {
          title: "Tenant",
          icon: <People size={20} />,
          onClick: () => navigate("/customers"),
        },
        {
          title: "Owner",
          icon: <People size={20} />,
          onClick: () => navigate("/owners"),
        },
        {
          title: "User",
          icon: <Users size={20} />,
          onClick: () => navigate("/users"),
        },
      ],
    },
    {
      title: "Rentals",
      submenus: [
        {
          title: "Category",
          icon: <Grid size={20} />,
          onClick: () => navigate("/categories"),
        },
        {
          title: "Property",
          icon: <BuildingAdd size={20} />,
          onClick: () => navigate("/property/list"),
        },
        {
          title: "Unit",
          icon: <DoorOpen size={20} />,
          onClick: () => navigate("/units"),
        },
      ],
    },
    {
      title: "Leasing",
      submenus: [
        {
          title: "Lease",
          icon: <JournalText size={20} />,
          onClick: () => navigate("/tenants"),
        },
        {
          title: "Rental Applications",
          icon: <BuildingAdd size={20} />,
          onClick: () => navigate("/tenants"),
        },
      ],
    },
  ];
  const menuList2 = [
    {
      title: "Accounting Transactions",
      submenus: [
        {
          title: "Journal Entry",
          icon: <Journals size={20} />,
          onClick: () => setJournalForm(true),
        },
        {
          title: "Expense",
          icon: <Cash size={20} />,
          onClick: () => navigate("/expenses"),
        },
        {
          title: "Invoice",
          icon: <CashStack size={20} />,
          onClick: () => navigate("/invoices"),
        },
      ],
    },
  ];

  const layout = useSelector((state) => state.layout);

  return (
    <>
      {layout.menuCollapsed ? (
        <Button
          color="primary"
          className="btn-sm me-2"
          onClick={() => setQuickActionOpen(true)}
        >
          <Plus />
        </Button>
      ) : (
        <Button block color="primary" onClick={() => setQuickActionOpen(true)}>
          <Plus /> Create New
        </Button>
      )}

      <Offcanvas
        isOpen={quickActionOpen}
        toggle={toggleQuickActions}
        style={{ width: "50%" }}
      >
        <OffcanvasHeader toggle={toggleQuickActions}>
          <h4 className="mb-0">CREATE NEW</h4>
        </OffcanvasHeader>
        <hr />
        <OffcanvasBody>
          <Row>
            {menuList.map((menu) => {
              return (
                <Col className="mt-2">
                  <h5 style={{ fontWeight: "bold" }}>{menu.title}</h5>
                  {menu.submenus.map((submenu) => {
                    return (
                      <div className="mt-2" key={submenu.title}>
                        <a
                          onClick={() => {
                            toggleQuickActions();
                            submenu.onClick();
                          }}
                          aria-current="page"
                          className="nav-link d-flex align-items-center"
                        >
                          {submenu.icon}
                          <span
                            className="menu-item text-truncate ms-1"
                            style={{ fontSize: "15px" }}
                          >
                            {submenu.title}
                          </span>
                        </a>
                      </div>
                    );
                  })}
                </Col>
              );
            })}
          </Row>
          <hr className="mt-5 mb-2" />
          <Row>
            {menuList2.map((menu) => {
              return (
                <Col className="mt-2">
                  <h5 style={{ fontWeight: "bold" }}>{menu.title}</h5>
                  {menu.submenus.map((submenu) => {
                    return (
                      <div className="mt-2" key={submenu.title}>
                        <a
                          onClick={() => {
                            toggleQuickActions();
                            submenu.onClick();
                          }}
                          aria-current="page"
                          className="nav-link d-flex align-items-center"
                        >
                          {submenu.icon}
                          <span
                            className="menu-item text-truncate ms-1"
                            style={{ fontSize: "15px" }}
                          >
                            {submenu.title}
                          </span>
                        </a>
                      </div>
                    );
                  })}
                </Col>
              );
            })}
          </Row>
          {/* <div className="m-1">
            <NavLink
              className="fw-bolder text-secondary"
              onClick={toggleQuickActions}
            >
              <User className="align-middle me-1" />
              Tenant
            </NavLink>
          </div>
          <div className="m-1">
            <NavLink
              className="fw-bolder text-secondary"
              onClick={toggleQuickActions}
            >
              <User className="align-middle me-1" />
              Tenant
            </NavLink>
          </div>
          <div
            className="m-1 fw-bolder text-secondary"
            onClick={() => setJournalForm(true)}
          >
            <User className="align-middle me-1" />
            Journal Entry
          </div> */}
        </OffcanvasBody>
      </Offcanvas>

      {/*  All Models Components  */}

      <JournalEntry show={journalForm} ShowModal={toggleJournalForm} />
    </>
  );
}
