// ** React Imports
import { Fragment, useState } from "react";
import * as yup from "yup";

import {
  Row,
  Col,
  Label,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import {
  createCategory,
  getAllcategories,
  updateCategory,
} from "../../redux/category";
import {
  createUtilityType,
  getAllUtilityTypes,
  updateUtilityType,
} from "../../redux/utilityTypes";
import HandleDispatch from "../../common/utils/HandledDispatch";
import { ErrorHandler } from "../../common/utils/Error";

const NewUtilityType = ({ row, show, ShowModal }) => {
  const defaultValues = {
    type: "",
    unit: "",
    rate: "",
  };
  // ** Hooks
  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const dispatch = useDispatch();
  const [btnloading, setBtnloading] = useState(false);

  const onSubmit = async (data) => {
    setBtnloading(true);
    try {
      if (row) {
        const response = await HandleDispatch(
          dispatch,
          updateUtilityType({ id: row._id, data })
        );
        if (response) {
          toast.success("Type Information updated Succesfully");
          reset();
          ShowModal();
        }
      } else {
        const response = await HandleDispatch(
          dispatch,
          createUtilityType(data)
        );
        if (response) {
          toast.success("New Type Registered Succesfully");
          reset();
          ShowModal();
        }
      }
    } catch (error) {
      console.error(error);
      ErrorHandler(error);
    } finally {
      setBtnloading(false);
    }
  };

  useEffect(() => {
    if (row) {
      reset({
        unit: row.unit,
        rate: row.rate,
        type: row.type,
      });
    }
  }, [row]);

  const onDiscard=()=>{
    ShowModal()
    reset(defaultValues)
  }

  return (
    <Fragment>
      <Modal isOpen={show} onClosed={onDiscard} toggle={onDiscard} className="">
        <ModalHeader toggle={onDiscard} className="py-1 bg-white">
          {row ? "Edit Type" : "Add New Type"}
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="pb-5 mx-50">
            <div className="mb-1">
              <Label className="form-label" for="type">
                Type
              </Label>
              <Controller
                name="type"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="type"
                    placeholder="Electricity"
                    invalid={errors.type && true}
                    {...field}
                  />
                )}
              />
              {/* {errors.name && (
                <FormFeedback>Please enter a valid Name</FormFeedback>
              )} */}
            </div>
            <div className="mb-1">
              <Label className="form-label" for="unit">
                Unit
              </Label>
              <Controller
                name="unit"
                control={control}
                // rules={{required:true}}
                render={({ field }) => (
                  <Input
                    id="unit"
                    placeholder="Ex: KW"
                    invalid={errors.unit && true}
                    {...field}
                  />
                )}
              />
              {/* {errors.name && (
                <FormFeedback>Please enter a valid Name</FormFeedback>
              )} */}
            </div>
            <div>
              <Label className="form-label" for="rate">
                Rate
              </Label>
              <Controller
                name="rate"
                control={control}
                render={({ field }) => (
                  <Input
                    id="rate"
                    placeholder="Ex. 1"
                    invalid={errors.unit && true}
                    {...field}
                  />
                )}
              />
              {/* {errors.name && (
                <FormFeedback>Please enter a valid Name</FormFeedback>
              )} */}
            </div>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button
              type="submit"
              className="me-1"
              color="primary"
              disabled={btnloading}
            >
              <Spinner size={"sm"} hidden={!btnloading} className="me-1" />
              {btnloading ? "Loading.." : "Submit"}
            </Button>
            <Button
              type="reset"
              className=""
              color="dark"
              outline
              onClick={onDiscard}
            >
              Discard
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default NewUtilityType;
