import React, { useRef, Fragment, useState, useEffect } from "react";

import { toast } from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Badge, Button, Card, Col, Row, Spinner } from "reactstrap";
import { UncontrolledTooltip } from "reactstrap";
import { ChevronDown, Edit, Trash } from "react-feather";
import { User } from "react-feather";

import themeConfig from "../../../configs/themeConfig";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// ** Styles
import "animate.css/animate.css";
import "@styles/react/libs/tables/react-dataTable-component.scss";
import "@styles/base/plugins/extensions/ext-component-sweet-alerts.scss";

import _ from "lodash";
import Loading from "../../loading";

import CustomPagination from "../shifts/components/DataTable/CustomPagination";
import CustomHeader from "./components/CustomHeader";

import { useGetCommissionsQuery } from "../../../redux/commissions";
import EarnCommissionModal from "./components/modals/EarnCommission";
import UpdateCommissionModal from "./components/modals/UpdateCommission";
import StatsHorizontal from "../../../@core/components/widgets/stats/StatsHorizontal";

const MySwal = withReactContent(Swal);

const Commission = () => {
  // ** states
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showEarnCommissionModal, setShowEarnCommissionModal] = useState(false);
  const [showUpdateCommissionModal, setShowUpdateCommissionModal] =
    useState(false);
  const [totalCommissions, setTotalCommissions] = useState(0);
  const [totalApplications, setTotalApplications] = useState(0);

  const commissionRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");

  const { data, isLoading } = useGetCommissionsQuery({
    page: currentPage,
    perPage: rowsPerPage,
    keyword: searchTerm,
  });

  const navigate = useNavigate();

  useEffect(() => {
    // console.log("data", data);
  }, [data]);

  useEffect(() => {
    if (data) {
      // calculate total commissions amount
      const total = data.data.reduce((acc, commission) => {
        return acc + commission.amount;
      }, 0);
      setTotalCommissions(total);

      // calculate total applications
      const applications = data.data.map((commission) => {
        return commission.application;
      });

      const uniqueApplications = new Set(applications);

      setTotalApplications(applications.length);
    }
  }, [data]);

  const handlePerPage = (e) => {
    const value = parseInt(e.currentPage?.value);
    setRowsPerPage(value);
  };

  const columns = [
    {
      name: "#",
      selector: "index",
      sortable: true,
      minWidth: "50px",
    },
    {
      name: "Agent",
      selector: "agent",
      sortable: true,
      minWidth: "200px",
      cell: (row) => {
        return (
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column cursor-pointer">
                <div
                  className="user-name text-truncate mb-0 cursor-pointer"
                  onClick={() => {
                    const url =
                      `/hrm/agents/${row.agent?._id}` +
                      (appView ? "?view=" + appView : "");

                    navigate(url);
                  }}
                >
                  <span className="font-weight-bold">{row.agent?.name}</span>
                </div>
                <small className="text-truncate text-muted">
                  {row.agent?.email}
                </small>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      name: "Commission",
      selector: "amount",
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Application",
      selector: "application",
      sortable: true,
      minWidth: "200px",
      cell: (row) => {
        return (
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column">
                <Link
                  to={`/apps/booking/${row.application?._id}`}
                  className="user-name text-truncate mb-0"
                  onClick={() => {
                    setSearchParams(
                      `view=application&id=${row.application?._id}`
                    );
                  }}
                >
                  <span className="font-weight-bold">
                    {row.application.unit?.name}
                  </span>
                </Link>
                <small className="text-truncate text-muted">
                  {row.application.unit?.location}
                </small>
              </div>
            </div>
          </div>
        );
      },
    },

    {
      name: "Date",
      selector: "createAt",
      sortable: true,
      minWidth: "200px",
      cell: (row) => {
        return new Date(row.createAt).toDateString();
      },
    },

    // {
    //   name: "Actions",
    //   cell: (row) => {
    //     return (
    //       <div className="d-flex align-items-center permissions-actions">
    //         <Button
    //           id="update-btn"
    //           size="sm"
    //           color="transparent"
    //           className="btn btn-icon"
    //           onClick={() => handleEditClick(row)}
    //         >
    //           <Edit className="font-medium-2" />
    //           <UncontrolledTooltip placement="top" target="update-btn">
    //             Update
    //           </UncontrolledTooltip>
    //         </Button>
    //         <Button
    //           id="delete-btn"
    //           size="sm"
    //           color="transparent"
    //           className="btn btn-icon"
    //           onClick={() => handleDeleteClick(row)}
    //         >
    //           <Trash className="font-medium-2 text-danger" />
    //           <UncontrolledTooltip placement="top" target="delete-btn">
    //             Delete
    //           </UncontrolledTooltip>
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];
  const handleEditClick = (data) => {
    commissionRef.current = data;
    setShowUpdateCommissionModal(true);
  };
  const renderData = () => {
    const calculateIndex = (index) => {
      return (currentPage - 1) * rowsPerPage + index + 1;
    };
    const agents = Array.isArray(data?.data)
      ? data.data
      : data?.data?.docs ?? {};
    return agents.map((item, index) => {
      return { ...item, index: calculateIndex(index) };
    });
  };

  return (
    <Fragment>
      <Row>
        <Col lg="4" sm="6">
          <StatsHorizontal
            icon={<User size={21} />}
            color={themeConfig.primary}
            statTitle="Total Applications"
            renderStats={
              isLoading ? (
                <Spinner />
              ) : (
                <h3 className="fw-bolder mb-75">{totalApplications}</h3>
              )
            }
          />
        </Col>

        <Col lg="4" sm="6">
          <StatsHorizontal
            icon={<User size={21} />}
            color="success"
            statTitle="Total Commissions"
            renderStats={
              isLoading ? (
                <Spinner />
              ) : (
                <h3 className="fw-bolder mb-75">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(totalCommissions)}
                </h3>
              )
            }
          />
        </Col>
      </Row>
      <Card className="overflow-hidden">
        <div className="react-dataTable">
          {isLoading ? (
            <Loading cols={columns} />
          ) : (
            <DataTable
              noHeader
              pagination
              subHeader
              responsive
              paginationServer
              columns={columns}
              sortIcon={<ChevronDown />}
              className="react-dataTable"
              paginationComponent={() => (
                <CustomPagination
                  data={data}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  rowsPerPage={rowsPerPage}
                />
              )}
              data={renderData()}
              subHeaderComponent={
                <CustomHeader
                  setShow={setShowEarnCommissionModal}
                  searchTerm={searchTerm}
                  handleSearch={(term) => setSearchTerm(term)}
                  handleClearResults={() => setSearchTerm("")}
                  rowsPerPage={rowsPerPage}
                  handlePerPage={handlePerPage}
                />
              }
            />
          )}
        </div>
      </Card>
      <EarnCommissionModal
        show={showEarnCommissionModal}
        setShow={setShowEarnCommissionModal}
      />
      <UpdateCommissionModal
        show={showUpdateCommissionModal}
        setShow={setShowUpdateCommissionModal}
      />
    </Fragment>
  );
};

export default Commission;
