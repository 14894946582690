import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardText, Col, Row, Table } from "reactstrap";
import { getCustomerStatement } from "../../../redux/customer";
import { formatCurrency, getDateRangeByFilter } from "../../../utility/Utils";

const Preview = ({ statement, customer, filterDate }) => {
  return (
    <div>
      <Card className="invoice-preview-card p-1 ">
        <CardBody className="invoice-padding pb-0">
          {/* Header */}
          <div className="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
            <div className="my-auto">
              <svg viewBox="0 0 139 95" version="1.1" height="24">
                <defs>
                  <linearGradient
                    id="invoice-linearGradient-1"
                    x1="100%"
                    y1="10.5120544%"
                    x2="50%"
                    y2="89.4879456%"
                  >
                    <stop stopColor="#000000" offset="0%"></stop>
                    <stop stopColor="#FFFFFF" offset="100%"></stop>
                  </linearGradient>
                  <linearGradient
                    id="invoice-linearGradient-2"
                    x1="64.0437835%"
                    y1="46.3276743%"
                    x2="37.373316%"
                    y2="100%"
                  >
                    <stop
                      stopColor="#EEEEEE"
                      stopOpacity="0"
                      offset="0%"
                    ></stop>
                    <stop stopColor="#FFFFFF" offset="100%"></stop>
                  </linearGradient>
                </defs>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(-400.000000, -178.000000)">
                    <g transform="translate(400.000000, 178.000000)">
                      <path
                        className="text-primary"
                        d="M-5.68434189e-14,2.84217094e-14 L39.1816085,2.84217094e-14 L69.3453773,32.2519224 L101.428699,2.84217094e-14 L138.784583,2.84217094e-14 L138.784199,29.8015838 C137.958931,37.3510206 135.784352,42.5567762 132.260463,45.4188507 C128.736573,48.2809251 112.33867,64.5239941 83.0667527,94.1480575 L56.2750821,94.1480575 L6.71554594,44.4188507 C2.46876683,39.9813776 0.345377275,35.1089553 0.345377275,29.8015838 C0.345377275,24.4942122 0.230251516,14.560351 -5.68434189e-14,2.84217094e-14 Z"
                        style={{ fill: "currentColor" }}
                      ></path>
                      <path
                        d="M69.3453773,32.2519224 L101.428699,1.42108547e-14 L138.784583,1.42108547e-14 L138.784199,29.8015838 C137.958931,37.3510206 135.784352,42.5567762 132.260463,45.4188507 C128.736573,48.2809251 112.33867,64.5239941 83.0667527,94.1480575 L56.2750821,94.1480575 L32.8435758,70.5039241 L69.3453773,32.2519224 Z"
                        fill="url(#invoice-linearGradient-1)"
                        opacity="0.2"
                      ></path>
                      <polygon
                        fill="#000000"
                        opacity="0.049999997"
                        points="69.3922914 32.4202615 32.8435758 70.5039241 54.0490008 16.1851325"
                      ></polygon>
                      <polygon
                        fill="#000000"
                        opacity="0.099999994"
                        points="69.3922914 32.4202615 32.8435758 70.5039241 58.3683556 20.7402338"
                      ></polygon>
                      <polygon
                        fill="url(#invoice-linearGradient-2)"
                        opacity="0.099999994"
                        points="101.428699 0 83.0667527 94.1480575 130.378721 47.0740288"
                      ></polygon>
                    </g>
                  </g>
                </g>
              </svg>
            </div>

            <div className="mt-md-0 mt-2">
              <div className="text-end my-3">
                <CardText className="mb-25">
                  <b>Jamhuriya Technology Solutions - Jtech</b>
                </CardText>
                <CardText className="mb-25">
                  Apartment 103, 1st Floor, Adani Tower 2, Makka Almukaramah
                  Street
                </CardText>
                <CardText className="mb-0">Mogadishu Benadir</CardText>
                <CardText className="mb-0">Somalia</CardText>
              </div>
            </div>
          </div>
          {/* /Header */}
        </CardBody>

        {/* Address and Contact */}
        <CardBody className="invoice-padding pt-0 mt-3">
          <Row className="invoice-spacing">
            <Col className="p-0" xl="8">
              <h6 className="mb-1">To</h6>
              <h5 className="mb-25"> {customer?.name}</h5>
            </Col>
            <Col className="p-0 mt-xl-0 mt-2 text-end" xl="4">
              <h4>Account Summary</h4>
              <p>
                {filterDate?.start} To {filterDate?.end}
              </p>
            </Col>
          </Row>
          <Row className="invoice-spacing ">
            <Col className="p-0" xl="7"></Col>
            <Col className="p-0 mt-xl-0 mt-2  " xl="5">
              <hr className="invoice-spacing" />
              <div className="d-flex justify-content-between">
                <div>
                  <p>Beginning Balance:</p>
                  <p>Invoiced Amount: </p>
                  <p>Amount Paid:</p>
                  <p>
                    <b>Balance Due:</b>
                  </p>
                </div>
                <div className="mb-0 text-end">
                  <p>{formatCurrency(statement?.summery?.beginningBalance)}</p>
                  <p>{formatCurrency(statement?.summery?.invoiceAmount)}</p>
                  <p>{formatCurrency(statement?.summery?.receiptAmount)}</p>
                  <p>
                    <b>{formatCurrency(statement?.summery?.balanceDue)}</b>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>

        <div className="text-center mb-2">
          <b>
            Showing all invoices and payments between {filterDate?.start} and{" "}
            {filterDate?.end}
          </b>
        </div>
        <h5></h5>

        {/* Invoice Description */}
        <Table responsive>
          <thead>
            <tr>
              <th className="py-1" width={"10%"}>
                Date
              </th>
              <th className="py-1" width={"45%"}>
                Details
              </th>
              <th className="py-1" width={"15%"}>
                Invoice
              </th>
              <th className="py-1" width={"15%"}>
                Receipt
              </th>
              <th className="py-1" width={"15%"}>
                Balance
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-1">
                <p className="card-text text-nowrap">{filterDate?.start}</p>
              </td>
              <td className="py-1" width={"15%"}>
                <span className="fw-bold">Beginning Balance</span>
              </td>

              <td className="py-1 text-end" width={"15%"}>
                <span className="fw-bold"> </span>
              </td>
              <td className="py-1 text-end" width={"15%"}>
                <span className="fw-bold"> </span>
              </td>

              <td className="py-1 text-end" width={"15%"}>
                <span className="fw-bold">
                  {formatCurrency(statement?.summery?.beginningBalance)}
                </span>
              </td>
            </tr>
            {/* {data.items.map((item, idx) => (
           ))}   */}

            {statement?.detail?.map((item) => (
              <tr>
                <td className="py-1">
                  <p className="card-text text-nowrap">{item.date}</p>
                </td>
                <td className="py-1" width={"15%"}>
                  <span>{item.type}</span>
                  <span>
                    {" "}
                    (<a href="">#{item.id}</a>){" "}
                  </span>
                  <span>{item.detail}</span>
                </td>

                <td className="py-1 text-end" width={"15%"}>
                  <span className="fw-bold">
                    {item.type === "Invoice" && formatCurrency(item.amount)}
                  </span>
                </td>
                <td className="py-1 text-end" width={"15%"}>
                  <span className="fw-bold">
                    {item.type === "Receipt" && formatCurrency(item.amount)}
                  </span>
                </td>

                <td className="py-1 text-end" width={"15%"}>
                  <span className="fw-bold">
                    {formatCurrency(item.balance || 0)}
                  </span>
                </td>
              </tr>
            ))}

            <tr>
              <td className="py-1">
                <p className="card-text text-nowrap"> </p>
              </td>
              <td className="py-1" width={"15%"}>
                <span className="fw-bold"> </span>
              </td>

              <td className="py-1  " width={"30%"} colSpan={2}>
                <span className="fw-bold"> Balance Due</span>
              </td>

              <td className="py-1 text-end" width={"15%"}>
                <span className="fw-bold">
                  {formatCurrency(statement?.summery?.balanceDue)}
                </span>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </div>
  );
};

export default Preview;
