import React, { forwardRef } from "react";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
// ** Icons Imports
import { ReceiptCutoff } from "react-bootstrap-icons";
import { ChevronDown, Eye, MoreVertical, PlusCircle, X } from "react-feather";

import { Fragment, useState } from "react";
import DataTable from "react-data-table-component";

import moment from "moment/moment";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  deleteInvoice,
  getAllinvoices,
  getData,
  getInvoicesFigures,
} from "../../redux/invoice";

import "@styles/react/apps/app-invoice.scss";
import "@styles/react/libs/tables/react-dataTable-component.scss";

import StatsHorizontal from "@components/widgets/stats/StatsHorizontal";
import { CashCoin } from "react-bootstrap-icons";
import { formatCurrency } from "../../utility/Utils";
import Loading from "../loading";
import UtilityForm from "../utilities/form";
import RentInvoiceModel from "./components/generateInvoice";
import RefundForm from "./components/refundForm";
import Can2 from "../../utility/Can";
import CancelForm from "./components/CancelForm";

const MySwal = withReactContent(Swal);

const invoiceStatusObj = {
  Sent: { color: "light-secondary" },
  "Full Paid": { color: "light-success" },
  Unpaid: { color: "light-danger" },
  Draft: { color: "light-primary" },
  Downloaded: { color: "light-info" },
  "Past Due": { color: "light-danger" },
  "Partial Paid": { color: "light-warning" },
};

const BootstrapCheckbox = forwardRef((props, ref) => (
  <div className="form-check">
    <Input type="checkbox" ref={ref} {...props} />
  </div>
));

export default function Invoices() {
  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");

  const [showmodal, setModalShow] = useState(false);
  const [rentmodal, setRentShow] = useState(false);
  const [refundmodal, setRefundShow] = useState({ invoice: "", show: false });

  const [cancelModal, setCancelModal] = useState({ invoice: "", show: false });
  const dispatch = useDispatch();

  const store = useSelector((state) => state.invoices);
  const customers = useSelector((state) => state.customers.allData);
  const sectionals = useSelector((state) => state.sectionals.allData);
  const navigate = useNavigate();

  const status = searchParams.get("status");

  const [selectedRows, setSelectedRows] = useState([]);

  const [active, setActive] = useState("1");

  const toggle = (tab) => {
    setActive(tab);
  };

  const handleConfirmDelete = async (id, name) => {
    return MySwal.fire({
      title: `Delete Invoice`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.value) {
        const responseStatus = await dispatch(deleteInvoice(id));
        if (responseStatus == 204) {
          toast.success("Building Has been Deleted.");
        }
      }
    });
  };
  const columns = [
    {
      name: "#",
      sortable: true,
      // minWidth: "10px",
      // sortField: "name",
      selector: (row) => row.invoiceNo,
      cell: (row) => (
        <span className="fw-bolder text-primary">{row.invoiceNo ?? ""}</span>
      ),
    },
    {
      name: "Date",
      // minWidth: "100px",
      // sortable: true,
      // sortField: "status",
      selector: (row) => row.createdAt,
      cell: (row) => (
        <span className="text-capitalize">
          {moment(row.createdAt).format("DD-MMM-YYYY")}
        </span>
      ),
    },
    {
      name: "Type",
      // minWidth: "100px",
      // sortable: true,
      // sortField: "status",
      selector: (row) => row.type,
      cell: (row) => <span className="text-capitalize">{row.type}</span>,
    },
    {
      name: "Customer",
      sortable: true,
      // minWidth: "300px",
      // sortField: "name",
      selector: (row) => row.customer,
      cell: (row) => (
        <div className="d-flex justify-content-left align-items-center">
          <div className="d-flex flex-column">
            <Link
              to={`/customers/${row.customer?._id}`}
              target="_blank"
              className="user_name text-truncate text-body"
            >
              <span className="fw-bolder">{row.customer?.name}</span>
            </Link>
          </div>
        </div>
      ),
    },
    // {
    //   name: "Apartment",
    //   sortable: true,
    //   // minWidth: "172px",
    //   // sortField: "floors",
    //   selector: (row) => row.apartment,
    //   cell: (row) => <span></span>,
    // },
    {
      name: "Total",
      // minWidth: "138px",
      // sortable: true,
      // sortField: "discount",
      selector: (row) => row.total,
      cell: (row) => (
        <span color="primary" className="text-capitalize fw-bolder">
          {formatCurrency(row.grandTotal)}
        </span>
      ),
    },

    {
      name: "Balance",
      selector: (row) => row.balance,
      cell: (row) => (
        <span className="text-success fw-bolder">${row.balance ?? 0}</span>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.balance,
      cell: (row) => (
        <Badge
          color={`${
            row.status == "paid"
              ? "success"
              : row.status == "partial"
              ? "warning"
              : "danger"
          }`}
        >
          {row.status}
        </Badge>
        // <>
        //   {row.balance == 0 ? (
        //     <Badge
        //       color="light-success"
        //       id={`status-tooltip-${row._id}`}
        //       className="text-capitalize fw-bolder"
        //     >
        //       Paid
        //     </Badge>
        //   ) : row.balance == row.grandTotal ? (
        //     <Badge
        //       color="light-warning"
        //       id={`status-tooltip-${row._id}`}
        //       className="text-capitalize fw-bolder"
        //     >
        //       Unpaid
        //     </Badge>
        //   ) : (
        //     <span
        //       className="text-secondary fw-bolder"
        //       id={`status-tooltip-${row._id}`}
        //     >
        //       ${row.balance}
        //     </span>
        //   )}

        //   <UncontrolledTooltip
        //     placement="top"
        //     target={`status-tooltip-${row._id}`}
        //   >
        //     {row.status}
        //   </UncontrolledTooltip>
        // </>
      ),
    },

    // {
    //   name: "Status",
    //   // minWidth: "100px",
    //   // sortable: true,
    //   // sortField: "status",
    //   selector: (row) => row.status,
    //   cell: (row) =>
    //   <>
    //   <Badge pill color={invoiceStatusObj[row.status]?.color??'secondary'} id={`status_${row._id}`}>{row.status}</Badge>
    //   {/* <span className='sr-only'>{row.status?'Active':'InActive'}</span> */}
    //   <UncontrolledTooltip placement='top' target={`status_${row._id}`}>
    //   {row.status}
    //   </UncontrolledTooltip>
    //   </>,
    // },
    {
      name: "User",
      // minWidth: "100px",
      // sortable: true,
      // sortField: "status",
      selector: (row) => row.createdBy,
      cell: (row) => row.createdBy?.username,
    },
    {
      name: "Actions",
      minWidth: "100px",
      allowOverflow: true,
      cell: (row) => (
        <div className="column-action d-inline-flex align-items-center justify-content-center">
          <Link
            to={`${row._id}` + (appView ? `?view=` + appView : ``)}
            className="mx-1"
            id={`pw-tooltip-${row._id}`}
          >
            <Eye size={17} />
          </Link>
          <UncontrolledTooltip placement="top" target={`pw-tooltip-${row._id}`}>
            Preview Invoice
          </UncontrolledTooltip>
          <UncontrolledDropdown>
            <DropdownToggle tag="div" className="btn btn-sm">
              <MoreVertical size={17} className="cursor-pointer" />
            </DropdownToggle>
            <DropdownMenu>
              {/* <DropdownItem
                tag="a"
                href="/"
                className="w-100"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(selectBooking(row));
                  setModalShow(true);
                }}
              >
                <Archive size={14} className="me-50" />
                <span className="align-middle">Edit</span>
              </DropdownItem> */}
              {/* <DropdownItem
                tag="a"
                href="/"
                className="w-100"
                onClick={(e) => {
                  e.preventDefault();
                  handleConfirmDelete(row._id, row.name);
                }}
              >
                <Trash2 size={14} className="me-50" />
                <span className="align-middle">Delete</span>
              </DropdownItem> */}
              {row.total == row.balance ? null : (
                <DropdownItem
                  tag="a"
                  href="/"
                  className="w-100"
                  onClick={(e) => {
                    e.preventDefault();
                    setRefundShow({ invoice: row._id, show: true });
                  }}
                >
                  <CashCoin size={14} className="me-50" />
                  <span className="align-middle">Make Refund</span>
                </DropdownItem>
              )}

              {row.status == "unpaid" && (
                <DropdownItem
                  tag="a"
                  href="/"
                  className="w-100"
                  onClick={(e) => {
                    e.preventDefault();
                    setCancelModal({ invoice: row._id, show: true });
                  }}
                >
                  <X size={14} className="me-50" />
                  <span className="align-middle">Cancel Invoice</span>
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      ),
    },
  ];

  const [sort, setSort] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const CustomPagination = () => {
    const count = Number(Math.ceil(store.total / rowsPerPage));

    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next next-item"}
        previousClassName={"page-item prev prev-item"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  const handlePagination = (page) => {
    const params = {
      page: page.selected + 1,
      limit: rowsPerPage,
    };

    dispatch(getData(params));
    setCurrentPage(page.selected + 1);
  };

  const dataToRender = () => {
    return store.data;
  };

  const CustomHeader = ({
    store,
    toggleSidebar,
    handlePerPage,
    rowsPerPage,
    handleFilter,
    searchTerm,
  }) => {
    // ** Converts table to CSV
    function convertArrayOfObjectsToCSV(array) {
      let result;

      const columnDelimiter = ",";
      const lineDelimiter = "\n";
      const keys = Object.keys(store.data[0]);

      result = "";
      result += keys.join(columnDelimiter);
      result += lineDelimiter;

      array.forEach((item) => {
        let ctr = 0;
        keys.forEach((key) => {
          if (ctr > 0) result += columnDelimiter;

          result += item[key];

          ctr++;
        });
        result += lineDelimiter;
      });

      return result;
    }
    // ** Downloads CSV
    function downloadCSV(array) {
      const link = document.createElement("a");
      let csv = convertArrayOfObjectsToCSV(array);
      if (csv === null) return;

      const filename = "export.csv";

      if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
      }

      link.setAttribute("href", encodeURI(csv));
      link.setAttribute("download", filename);
      link.click();
    }

    return (
      <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
        <Row>
          <Col xl="6" className="d-flex align-items-center p-0">
            <div className="d-flex align-items-center w-100">
              <label htmlFor="rows-per-page">Show</label>
              <Input
                className="mx-50"
                type="select"
                id="rows-per-page"
                value={rowsPerPage}
                onChange={handlePerPage}
                style={{ width: "5rem" }}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="50">100</option>
              </Input>
              <label htmlFor="rows-per-page">Entries</label>
            </div>
          </Col>
          <Col
            xl="6"
            className="d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1"
          >
            <div className="d-flex align-items-center mb-sm-0 mb-1 me-1">
              <label className="mb-0" htmlFor="search-invoice">
                Search:
              </label>
              <Input
                id="search-invoice"
                className="ms-50 w-100"
                type="text"
                value={searchTerm}
                // onChange={(e) => handleFilter(e.target.value)}
              />
            </div>

            {/* <div className="d-flex align-items-center table-header-actions">
              <UncontrolledDropdown className="me-1">
                <DropdownToggle color="secondary" caret outline>
                  <Share className="font-small-4 me-50" />
                  <span className="align-middle">Export</span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className="w-100"
                    onClick={() => {
                      if (selectedRows.length) {
                        navigate(`/invoices/${selectedRows.join(",")}`);
                      }else{
                        toast.error ("Select invoices to print")
                      }
                    }}
                  >
                    <Printer className="font-small-4 me-50" />
                    <span className="align-middle">Print</span>
                  </DropdownItem>
                  <DropdownItem
                    className="w-100"
                    onClick={() => downloadCSV(store.data)}
                  >
                    <FileText className="font-small-4 me-50" />
                    <span className="align-middle">CSV</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div> */}
          </Col>
        </Row>
      </div>
    );
  };

  const [utilityFormOpen, setutilityFormOpen] = useState(false);

  const ToggleUtilityModal = (show) => {
    setutilityFormOpen(false);
  };

  const ToggleRentModal = (show) => {
    setRentShow(false);
  };
  const ToggleModal = (show) => {
    if (typeof show == "boolean") {
      setModalShow(show);
    } else {
      setModalShow(!showmodal);
    }
  };

  const handlePerPage = (e) => {
    const value = parseInt(e.currentTarget.value);
    dispatch(
      getData({
        limit: value,
        page: currentPage,
      })
    );
    setRowsPerPage(value);
  };

  useEffect(() => {
    const c = active == "1" ? ["Booking", "Rent"] : ["Bill"];
    console.log(appView ? [appView] : [...c]);

    dispatch(getAllinvoices({ appView: appView ? [appView] : [...c], status }));
    dispatch(getInvoicesFigures(appView ? [appView] : [...c]));
    // if (!customers.length) {
    //   dispatch(getAllCustomers())
    // }
    // if (!sectionals.length) {
    //   dispatch(getAllSectionals())
    // }
  }, [active, status]);

  return (
    <Fragment>
      <UtilityForm show={utilityFormOpen} ShowModal={ToggleUtilityModal} />
      <RentInvoiceModel show={rentmodal} onClose={ToggleRentModal} />
      <RefundForm
        show={refundmodal.show}
        onClose={() => {
          setRefundShow({ invoice: "", show: false });
        }}
        invoice={refundmodal.invoice}
      />

      <CancelForm
        show={cancelModal.show}
        onClose={() => {
          setCancelModal({ invoice: "", show: false });
        }}
        invoice={cancelModal.invoice}
      />

      <Row>
        <Col lg="3" sm="6">
          <StatsHorizontal
            color="info"
            statTitle="All Invoices"
            icon={<ReceiptCutoff size={21} />}
            renderStats={
              store.figuresLoading && store.figures.total < 1 ? (
                <Spinner />
              ) : (
                <h3 className="fw-bolder mb-75"> {store.figures.total} </h3>
              )
            }
          />
        </Col>
        <Col lg="3" sm="6">
          <StatsHorizontal
            color="success"
            statTitle="Full Paid Invoices"
            icon={<ReceiptCutoff size={21} />}
            renderStats={
              store.figuresLoading && store.figures.total < 1 ? (
                <Spinner />
              ) : (
                <h3 className="fw-bolder mb-75"> {store.figures.paid} </h3>
              )
            }
          />
        </Col>
        <Col lg="3" sm="6">
          <StatsHorizontal
            color="warning"
            statTitle="Partial Paid Invoices"
            icon={<ReceiptCutoff size={21} />}
            renderStats={
              store.figuresLoading && store.figures.total < 1 ? (
                <Spinner />
              ) : (
                <h3 className="fw-bolder mb-75"> {store.figures.partial} </h3>
              )
            }
          />
        </Col>
        <Col lg="3" sm="6">
          <StatsHorizontal
            color="danger"
            statTitle="UnPaid Invoices"
            icon={<ReceiptCutoff size={21} />}
            renderStats={
              store.figuresLoading && store.figures.total < 1 ? (
                <Spinner />
              ) : (
                <h3 className="fw-bolder mb-75"> {store.figures.unpaid} </h3>
              )
            }
          />
        </Col>
      </Row>

      <Nav pills fill>
        <NavItem className="border border-2 border-dark rounded shadow-lg">
          <NavLink
            active={active === "1"}
            onClick={() => {
              toggle("1");
              // setSearchParams({ type: ["Booking", "Rent"] });
            }}
          >
            Rent Invoice
          </NavLink>
        </NavItem>
        <NavItem className="border border-2 border-dark rounded shadow-lg">
          <NavLink
            active={active === "2"}
            onClick={() => {
              toggle("2");
              // setSearchParams({ type: ["Bill"] });
            }}
          >
            Utility Invoice
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent className="py-50" activeTab={active}>
        <TabPane tabId="1">
          <Card className="">
            <CardHeader>
              <CardTitle tag="h4">Invoice Management</CardTitle>
              {appView ? (
                <Can2 I="create" a="Invoice" y={appView}>
                  <Link
                    to={"/invoices/new?view=" + appView}
                    className="btn btn-primary"
                  >
                    <PlusCircle size={18} /> New Invoice
                  </Link>
                </Can2>
              ) : (
                <Can2 I="create" a="Invoice">
                  <Button color="primary" onClick={() => setRentShow(true)}>
                    <PlusCircle size={18} /> New Invoice
                  </Button>
                </Can2>
              )}
            </CardHeader>
            <div className="invoice-list-dataTable react-dataTable">
              {store.loading ? (
                <Loading cols={columns} />
              ) : (
                <DataTable
                  noHeader
                  subHeader
                  sortServer
                  pagination
                  responsive
                  paginationServer
                  columns={columns}
                  // onSort={handleSort}
                  // selectableRows

                  // onSelectedRowsChange={(row) => {
                  //   var ids = row.selectedRows.map((rw) => rw._id);
                  //   setSelectedRows(ids);
                  // }}
                  sortIcon={<ChevronDown />}
                  className="react-dataTable  react-dataTable-selectable-rows"
                  allowOverflow={true}
                  selectableRowsComponent={BootstrapCheckbox}
                  paginationComponent={CustomPagination}
                  data={dataToRender()}
                  subHeaderComponent={
                    <CustomHeader
                      store={store}
                      // searchTerm={searchTerm}
                      rowsPerPage={rowsPerPage}
                      // handleFilter={handleFilter}
                      handlePerPage={handlePerPage}
                      // toggleSidebar={toggleSidebar}
                    />
                  }
                />
              )}
            </div>
          </Card>
        </TabPane>
        <TabPane tabId="2">
          <Card className="">
            <CardHeader>
              <CardTitle tag="h4">Invoice Management</CardTitle>
              {appView ? (
                <Link
                  to={"/invoices/new?view=" + appView}
                  className="btn btn-primary"
                >
                  {" "}
                  <PlusCircle size={18} /> New Invoice
                </Link>
              ) : (
                <Button
                  color="primary"
                  onClick={() => setutilityFormOpen(true)}
                >
                  {" "}
                  <PlusCircle size={18} /> New Invoice
                </Button>
              )}
            </CardHeader>
            <div className="invoice-list-dataTable react-dataTable">
              {store.loading ? (
                <Loading cols={columns} />
              ) : (
                <DataTable
                  noHeader
                  subHeader
                  sortServer
                  pagination
                  responsive
                  paginationServer
                  columns={columns}
                  // onSort={handleSort}
                  // selectableRows

                  // onSelectedRowsChange={(row) => {
                  //   var ids = row.selectedRows.map((rw) => rw._id);
                  //   setSelectedRows(ids);
                  // }}
                  sortIcon={<ChevronDown />}
                  className="react-dataTable  react-dataTable-selectable-rows"
                  allowOverflow={true}
                  selectableRowsComponent={BootstrapCheckbox}
                  paginationComponent={CustomPagination}
                  data={dataToRender()}
                  subHeaderComponent={
                    <CustomHeader
                      store={store}
                      // searchTerm={searchTerm}
                      rowsPerPage={rowsPerPage}
                      // handleFilter={handleFilter}
                      handlePerPage={handlePerPage}
                      // toggleSidebar={toggleSidebar}
                    />
                  }
                />
              )}
            </div>
          </Card>
        </TabPane>
      </TabContent>
    </Fragment>
  );
}
