// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAllStaff = createAsyncThunk("staff/getAllData", async () => {
  let bodyContent = {
    params: {
      options: {
        query: {},
        populate: [
          {
            dir: "users",
            path: "user",
            select: "username",
          },
          {
            dir: "hrm/jobs",
            path: "job",
            select: "name",
          },
          {
            dir: "hrm/shifts",
            path: "shift",
            select: "name",
          },
          {
            dir: "hrm/departments",
            path: "department",
            select: "name",
          },
        ],
      },
    },
  };
  const response = await axios.get("/hrm/staff", bodyContent);
  if (response.status != 200) {
    return rejectWithValue(response.data.message);
  }
  const docs = response.data?.data?.docs || [];
  return { docs, totalRows: response.data?.data?.totalDocs };
});

export const getData = createAsyncThunk("staff/getData", async (params) => {
  const response = await axios.get("/hrm/staff", {
    params: {
      options: {
        query: params,
        populate: [
          {
            dir: "users",
            path: "user",
            select: "username",
          },
          {
            dir: "hrm/jobs",
            path: "job",
            select: "name",
          },
          {
            dir: "hrm/shifts",
            path: "shift",
            select: "name",
          },
          {
            dir: "hrm/departments",
            path: "department",
            select: "name",
          },
        ],
      },
    },
  });

  if (response.status != 200) {
    return rejectWithValue(response.data.message);
  }
  return {
    params,
    data: response.data?.data?.docs,
  };
});

export const createStaff = createAsyncThunk(
  "staff/create",
  async (
    formdata,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      formdata.forEach((data) => {
        console.log(`FormData : ${data}`);
      });
      var response = await axios.post("/hrm/staff", formdata);

      // dispatch(addStaff(response.data?.data));
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateStaff = createAsyncThunk(
  "staff/update",
  async ({ data, id }, { dispatch, getState, rejectWithValue }) => {
    try {
      var response = await axios.patch(`/hrm/staff/${id}`, data);
      // await dispatch(getData(getState().users.params));
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      }
      //  dispatch(editStaff(response.data?.data));
      return response.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getStaffFigures = createAsyncThunk(
  "hrm/staff/getStaffFigures",
  async (_, { rejectWithValue }) => {
    try {
      return await axios.get("/hrm/staff/figures");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//on process
export const searchStaff = createAsyncThunk(
  "hrm/staff/searchStaff",
  async (value) => {
    const response = await axios.get("/hrm/staff", {
      params: {
        search: value,
        fields: ["name", "email"],
      },
    });

    if (response.status != 200) {
      return rejectWithValue(response.data.message);
    }
    return response.data;
  }
);

export const deleteStaff = createAsyncThunk(
  "staff/delete",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(`/hrm/staff/${id}`);
      console.log(response.data);
      if (response.status != 204) {
        return rejectWithValue("Can't Delete Try Again");
      }
      await dispatch(getAllStaff());
      return response.status;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getStaffDetail = createAsyncThunk(
  "staff/getStaffDetail",
  async (id) => {
    const response = await axios.get(`/hrm/staff/detail/${id}`);
    if (response.status != 200) {
      return rejectWithValue(response.data.message);
    }
    return response?.data;
  }
);

export const staffSlice = createSlice({
  name: "staff",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    error: null,
    figures: { total: 0, active: 0, inactive: 0 },
    figuresLoading: false,
    selectedStaff: null,
    staffDetail: {},
  },
  reducers: {
    addStaff: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectStaff: (state, { payload }) => {
      state.selectedStaff = payload;
    },
    editStaff: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
      state.loading = false;
      state.error = null;
      state.selectedStaff = null;
    },
    searchFilter: (state, { payload }) => {
      if (payload == "") {
        state.data = state.allData;
        return;
      }

      state.data = state.allData.filter(
        (cs) =>
          cs.name?.toLowerCase().includes(payload.toLowerCase()) ||
          cs.phone?.toLowerCase().includes(payload.toLowerCase()) ||
          cs.email?.toLowerCase().includes(payload.toLowerCase())
      );
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllStaff.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllStaff.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalRows;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllStaff.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getData.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      })
      .addCase(getData.rejected, (state, { payload, error }) => {
        state.loading = false;
        state.error = error;
      })
      .addCase(createStaff.pending, (state, { payload }) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createStaff.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.selectedStaff = null;
        state.allData.unshift(payload.data);
        state.data.unshift(payload.data);
      })
      .addCase(createStaff.rejected, (state, { payload, error }) => {
        state.loading = false;
        state.error = payload;
        state.selectedStaff = null;
      })

      .addCase(updateStaff.pending, (state, { payload }) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateStaff.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.loading = false;
        state.error = null;
        state.data = state.data.map((d) => {
          if (d._id == payload._id) {
            return payload;
          }
          return d;
        });
        state.allData = state.allData.map((da) => {
          if (da._id == payload._id) {
            return payload;
          }
          return da;
        });
        state.selectedStaff = null;
      })
      .addCase(updateStaff.rejected, (state, { payload, error }) => {
        state.loading = false;
        state.error = error;
      })
      .addCase(getStaffFigures.pending, (state) => {
        state.error = null;
        state.figuresLoading = true;
      })
      .addCase(getStaffFigures.fulfilled, (state, action) => {
        const { figures } = action.payload?.data;
        if (figures) state.figures = figures;
        state.figuresLoading = false;
        state.error = null;
      })
      .addCase(getStaffFigures.rejected, (state, action) => {
        console.log("ACTION PAYLOAD", action.payload);
        state.error = action.payload;
        state.figuresLoading = false;
      })
      .addCase(searchStaff.pending, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(searchStaff.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.allData = payload.docs;
        state.total = payload.totalRows;
        state.loading = false;
        state.error = null;
      })
      .addCase(searchStaff.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(getStaffDetail.pending, (state, { payload, error }) => {
        state.staffDetail.loading = true;
      })
      .addCase(getStaffDetail.fulfilled, (state, { payload, error }) => {
        state.staffDetail.loading = false;
        state.staffDetail = { ...payload };
      })
      .addCase(getStaffDetail.rejected, (state, { payload, error }) => {
        state.staffDetail.loading = false;
        state.staffDetail = {};
      });
  },
});

export const { selectStaff, searchFilter } = staffSlice.actions;
export default staffSlice.reducer;
