// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios, { AxiosError } from "axios";

export const getAllOwners = createAsyncThunk("owners/getAllData", async () => {
  let bodyContent = {
    params: {
      options: {
        query: {},
        populate: [
          {
            path: "createdBy",
            dir: "users",
            select: "_id username",
          },
        ],
      },
    },
  };
  const response = await axios.get("/owners", bodyContent);
  if (response.status != 200) {
    return rejectWithValue(response.data.message);
  }
  const docs = response.data?.data?.docs || [];
  return { docs, totalRows: response.data?.data?.totalDocs };
});

export const getData = createAsyncThunk("owners/getData", async (params) => {
  const response = await axios.get("/owners", {
    params: { options: params },
  });
  if (response.status != 200) {
    return rejectWithValue(response.data.message);
  }
  return {
    params,
    data: response.data?.data?.docs,
  };
});

export const createOwner = createAsyncThunk(
  "owners/create",
  async (
    formdata,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      var response = await axios.post("/owners", formdata);

      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      }
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateOwner = createAsyncThunk(
  "owners/update",
  async ({ data, id }, { dispatch, getState, rejectWithValue }) => {
    try {
      console.log(id);

      var response = await axios.patch(`/owners/${id}`, data);

      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      }

      return response.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteOwner = createAsyncThunk(
  "owners/delete",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(`/owners/${id}`);
      if (response.status != 204) {
        return rejectWithValue("Can't Delete Try Again");
      }
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOwnerDetail = createAsyncThunk(
  "owners/getOwnerDetail",
  async (id) => {
    const response = await axios.get(`/owners/detail/${id}`);
    if (response.status != 200) {
      return rejectWithValue(response.data.message);
    }
    return response?.data;
  }
);

export const ownerSlice = createSlice({
  name: "owners",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    error: null,
    selectedOwner: null,
    ownerDetail: {},
  },
  reducers: {
    addOwner: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectOwner: (state, { payload }) => {
      state.selectedOwner = payload;
    },
    editOwner: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
      state.loading = false;
      state.error = null;
      state.selectedOwner = null;
    },
    searchFilter: (state, { payload }) => {
      if (payload == "") {
        state.data = state.allData;
        return;
      }

      state.data = state.allData.filter(
        (cs) =>
          cs.name?.toLowerCase().includes(payload.toLowerCase()) ||
          cs.phone?.toLowerCase().includes(payload.toLowerCase()) ||
          cs.email?.toLowerCase().includes(payload.toLowerCase())
      );
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllOwners.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllOwners.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalRows;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllOwners.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getData.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      })
      .addCase(getData.rejected, (state, { payload, error }) => {
        state.loading = false;
        state.error = error;
      })
      .addCase(createOwner.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.selectedOwner = null;
        state.allData.push(payload);
        state.data.push(payload);
      })
      .addCase(createOwner.rejected, (state, { payload, error }) => {
        state.loading = false;
        state.error = payload;
        state.selectedOwner = null;
      })

      .addCase(updateOwner.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.loading = false;
        state.error = null;
        state.data = state.data.map((d) => {
          if (d._id == payload._id) {
            return payload;
          }
          return d;
        });
        state.allData = state.allData.map((da) => {
          if (da._id == payload._id) {
            return payload;
          }
          return da;
        });
        state.selectedOwner = null;
      })
      .addCase(updateOwner.rejected, (state, { payload, error }) => {
        state.loading = false;
        state.error = error;
      })
      .addCase(deleteOwner.fulfilled, (state, { payload, error }) => {
        state.loading = false;
        state.error = error;
        const deletedOwner = payload;
        state.data = state.data.filter((d) => d._id !== deletedOwner);
      })
      .addCase(getOwnerDetail.pending, (state, { payload, error }) => {
        state.ownerDetail.loading = true;
      })
      .addCase(getOwnerDetail.fulfilled, (state, { payload, error }) => {
        state.ownerDetail.loading = false;
        state.ownerDetail = { ...payload };
      })
      .addCase(getOwnerDetail.rejected, (state, { payload, error }) => {
        state.ownerDetail.loading = false;
        state.ownerDetail = {};
      });
  },
});

export const { selectOwner, searchFilter } = ownerSlice.actions;
export default ownerSlice.reducer;
