import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

import {
  Card,
  CardBody,
  CardText,
  Row,
  Col,
  Table,
  Input,
  Button,
} from "reactstrap";
import { FilePdf, Printer } from "react-bootstrap-icons";
import { Mail } from "react-feather";

import {
  formatCurrency,
  getDateRangeByFilter,
} from "../../../../utility/Utils";
import { useGetAgentStatementQuery } from "../../../../redux/agents";
import Preview from "./preview";

const Statement = ({ agent }) => {
  const [filter, setFilter] = useState("this month");
  const [filterDate, setFilterDate] = useState({
    start: moment().startOf("month").format("YYYY-MM-DD"),
    end: moment().endOf("month").format("YYYY-MM-DD"),
  });
  const [isVisible, setIsVisible] = useState(false);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { data: store } = useGetAgentStatementQuery({
    id: agent.data?._id,
    start: filterDate.start,
    end: filterDate.end,
  });

  useEffect(() => {
    if (filter !== "custom") setFilterDate(getDateRangeByFilter(filter));
  }, [filter]);

  return (
    <>
      <div>
        <Card className="invoice-preview-card p-1">
          <CardBody className="invoice-padding pb-0">
            {/* Header */}
            <div className="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <div>
                <div className="mb-1">
                  <Input
                    type="select"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    className="mb-1"
                  >
                    <option value="today">Today</option>
                    <option value="this week">This Week</option>
                    <option value="this month">This Month</option>
                    <option value="last month">Last Month</option>
                    <option value="this year">This Year</option>
                    <option value="last year">Last Year</option>
                    <option value="custom">Custom</option>
                  </Input>
                  {filter === "custom" && (
                    <>
                      <Input
                        type="date"
                        name="start"
                        onChange={(e) =>
                          setFilterDate({
                            ...filterDate,
                            start: e.target.value,
                          })
                        }
                        className="mb-1"
                      />
                      <Input
                        type="date"
                        name="end"
                        onChange={(e) =>
                          setFilterDate({ ...filterDate, end: e.target.value })
                        }
                        className="mb-1"
                      />
                    </>
                  )}
                </div>

                <div className="logo-wrapper mb-2">
                  <h4>Agent Statement for {agent.data?.name}</h4>
                </div>
              </div>
              <div className="mt-md-0 mt-2">
                <div className="text-end">
                  <Button
                    outline
                    onClick={() => {
                      handlePrint();
                    }}
                  >
                    <Printer size={20} className="text-dark" />
                  </Button>
                  <Button outline className=" ms-1" onClick={""}>
                    <FilePdf size={20} className="text-dark " />
                  </Button>
                  <Button outline className=" ms-1">
                    <Mail size={20} className="text-dark" />
                  </Button>
                </div>
                <div className="text-end my-3">
                  <CardText className="mb-25">
                    <b>Jamhuriya Technology Solutions - Jtech</b>
                  </CardText>
                  <CardText className="mb-25">
                    Apartment 103, 1st Floor, Adani Tower 2, Makka Almukaramah
                    Street
                  </CardText>
                  <CardText className="mb-0">Mogadishu Benadir</CardText>
                  <CardText className="mb-0">Somalia</CardText>
                </div>
              </div>
            </div>
          </CardBody>

          <hr className="invoice-spacing" />
          <CardBody className="invoice-padding pt-0">
            <Row className="invoice-spacing">
              <Col className="p-0" xl="8">
                <h6 className="mb-1">To:</h6>
                <h6 className="mb-25 color-primary"> {agent.data?.name}</h6>
              </Col>
              <Col className="p-0 mt-xl-0 mt-2 text-end" xl="4">
                <h4>Account Summary</h4>
                <p>
                  {filterDate.start} To {filterDate.end}
                </p>
              </Col>
            </Row>
            <Row className="invoice-spacing ">
              <Col className="p-0" xl="7"></Col>
              <Col className="p-0 mt-xl-0 mt-2  " xl="5">
                <hr className="invoice-spacing" />
                <div className="d-flex justify-content-between">
                  <div>
                    <p>Beginning Balance:</p>
                    <p>Total Commissions: </p>
                    <p>Total Earns:</p>
                    <p>
                      <b>Balance :</b>
                    </p>
                  </div>
                  <div className="mb-0 text-end">
                    <p>{formatCurrency(store?.summery?.beginningBalance)}</p>
                    <p>{formatCurrency(store?.summery?.totalCommissions)}</p>
                    <p>{formatCurrency(store?.summery?.totalEarns)}</p>
                    <p>
                      <b>{formatCurrency(store?.summery?.balance)}</b>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
          <div className="text-center mb-2">
            <b>
              Showing all commission earns between {filterDate.start} and{" "}
              {filterDate.end}
            </b>
          </div>

          {/* commission earn details */}
          <Table responsive>
            <thead>
              <tr>
                <th className="py-1" width={"10%"}>
                  Date
                </th>
                <th className="py-1" width={"45%"}>
                  Description
                </th>
                <th className="py-1" width={"15%"}>
                  Appliction
                </th>
                <th className="py-1" width={"15%"}>
                  Debit
                </th>
                <th className="py-1" width={"15%"}>
                  Credit
                </th>
                <th className="py-1" width={"15%"}>
                  Balance
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-1">
                  <p className="card-text text-nowrap">{filterDate.start}</p>
                </td>
                <td className="py-1" width={"15%"}>
                  <span className="fw-">Beginning Balance</span>
                </td>

                <td className="py-1 text-end" width={"15%"}>
                  <span className="fw-"> </span>
                </td>
                <td className="py-1 text-end" width={"15%"}>
                  <span className="fw-"> </span>
                </td>
                <td className="py-1 text-end" width={"15%"}>
                  <span className="fw-"> </span>
                </td>

                <td className="py-1 text-end" width={"15%"}>
                  <span className="fw-">
                    {formatCurrency(store?.summery?.beginningBalance)}
                  </span>
                </td>
              </tr>
              {store?.detail.map((item, index) => (
                <tr key={index}>
                  <td className="py-1">
                    <p className="card-text text-nowrap">
                      {moment(item.date).format("YYYY-MM-DD")}
                    </p>
                  </td>
                  <td className="py-1" width={"15%"}>
                    <span className="fw-">{item.description}</span>
                  </td>

                  <td className="py-1 text-end" width={"15%"}>
                    <span className="fw-"> {item.application}</span>
                  </td>
                  <td className="py-1 text-end" width={"15%"}>
                    <span className="fw-">
                      {item.type === "Debit"
                        ? formatCurrency(item.amount)
                        : "---"}
                    </span>
                  </td>
                  <td className="py-1 text-end" width={"15%"}>
                    <span className="fw-">
                      {item.type === "Credit"
                        ? formatCurrency(item.amount)
                        : "---"}
                    </span>
                  </td>
                  <td className="py-1 text-end" width={"15%"}>
                    <span className="fw-">{formatCurrency(item.balance)}</span>
                  </td>
                </tr>
              ))}
              <tr>
                <td className="py-1">
                  <p className="card-text text-nowrap"> </p>
                </td>
                <td className="py-1" width={"15%"}>
                  <span className="fw-bold"> </span>
                </td>
                <td className="py-1" width={"15%"}>
                  <span className="fw-bold"> </span>
                </td>

                <td className="py-1  " width={"30%"} colSpan={2}>
                  <span className="fw-bold"> Balance</span>
                </td>

                <td className="py-1 text-end" width={"15%"}>
                  <span className="fw-bold">
                    {formatCurrency(store?.summery?.balance)}
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </div>

      <div
        style={{
          visibility: isVisible ? "visible" : "hidden",
          height: isVisible ? "auto" : 0,
          overflow: isVisible ? "visible" : "hidden",
        }}
      >
        <Preview
          agent={agent}
          store={store}
          showButtons={false}
          ref={componentRef}
        />
      </div>
    </>
  );
};

export default Statement;
