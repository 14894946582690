import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ApiErrorHandler } from "../utility/error";
import { BASE_URL, getToken } from "../utility/Utils";
import { result } from "lodash";

export const companySittingsApi = createApi({
  reducerPath: "companies",
  tagTypes: ["companies"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, {}) => {
      const token = getToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["companies"],
  endpoints: (builder) => ({
    // get Company Sitting
    getCompanySitting: builder.query({
      query: (id) => {
        return {
          url: `/companies/${id}`,
          method: "GET",
        };
      },
      providesTags: (result, error, id) => [{ type: "companies", id }],
    }),

    // update Company Sitting
    updateCompanySitting: builder.mutation({
      query: (body) => {
        console.log("body:", body);

        return {
          url: `/companies`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: (result, error, { body }) => {
        if (error) {
          ApiErrorHandler(error);
          return [];
        }
        return [{ type: "companies", id: body?.id }];
      },
    }),
  }),
});

export const { useGetCompanySittingQuery, useUpdateCompanySittingMutation } =
  companySittingsApi;
