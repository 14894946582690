// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAllAccounts = createAsyncThunk(
  "Accounts/getAllData",
  async () => {
    let params = {
      params: {
        options: {
          query: {},
          populate: [],
        },
      },
    };

    const response = await axios.get("accountings/accounts", params);
    const docs = response.data?.data || [];
    return { docs, totalRows: response.data?.data?.length };
  }
);

export const getData = createAsyncThunk("Accounts/getData", async (params) => {
  const response = await axios.get("accountings/accounts", {
    params: { options: params },
  });
  return {
    params,
    data: response.data?.data,
  };
});
export const updateAccount = createAsyncThunk(
  "Accounts/update",
  async ({ formdata, id }, { dispatch, getState }) => {
    var response = await axios.patch(`accountings/accounts/${id}`, formdata);
    // await dispatch(getData(getState().users.params));
    dispatch(editAccounts(response.data?.data));
    return { status: response.status, message: response.message };
  }
);

export const createAccount = createAsyncThunk(
  "Accounts/create",
  async (formdata, { dispatch, getState }) => {
    var response = await axios.post("accountings/accounts", formdata);
    // await dispatch(getData(getState().users.params));

    await dispatch(addAccount(response.data?.data));
    return { status: response.status, message: response.message };
  }
);

export const deleteAccount = createAsyncThunk(
  "Accounts/delete",
  async (id, { dispatch, getState }) => {
    const response = await axios.delete(`accountings/accounts/${id}`);
    await dispatch(getAllAccounts());
    return response.status;
  }
);

export const updateAccountStatus = createAsyncThunk(
  "Accounts/update",
  async (data, { dispatch, getState, rejectWithValue }) => {
    var response = await axios.post(
      `/accountings/accounts/change_status`,
      data
    );

    await dispatch(getAllAccounts());

    return { status: response.status, data: response.data.data };
  }
);

export const accountSlice = createSlice({
  name: "accounts",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false,
    selectedAccount: null,
  },
  reducers: {
    addAccount: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectAccount: (state, { payload }) => {
      state.selectedAccount = payload;
    },
    editAccount: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAccounts.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getAllAccounts.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalRows;
        state.loading = false;
      })

      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      })

      .addCase(updateAccountStatus.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.data = state.data.map((item) => {
          if (item._id == payload.data._id) {
            return { ...item, ...payload.data };
          }
          return item;
        });
        state.allData = state.allData.map((item) => {
          if (item._id == payload.data._id) {
            return { ...item, ...payload.data };
          }
          return item;
        });
      });
  },
});
export const { addAccount, selectAccount, editAccount } = accountSlice.actions;
export default accountSlice.reducer;
