// ** React Imports
import { Fragment } from "react";

// ** Reactstrap Imports
import { Badge, Button, Card, CardBody } from "reactstrap";

// ** Third Party Components
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// ** Custom Components
import Avatar from "@components/avatar";

// ** Utils

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
import themeConfig from "../../../configs/themeConfig";
import { formatCurrency } from "../../../utility/Utils";

const roleColors = {
  editor: "light-info",
  admin: "light-danger",
  author: "light-warning",
  maintainer: "light-success",
  subscriber: "light-primary",
};

const statusColors = {
  active: "light-success",
  pending: "light-warning",
  inactive: "light-secondary",
};

const statusOptions = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
  { value: "suspended", label: "Suspended" },
];

const countryOptions = [
  { value: "uk", label: "UK" },
  { value: "usa", label: "USA" },
  { value: "france", label: "France" },
  { value: "russia", label: "Russia" },
  { value: "canada", label: "Canada" },
];

const languageOptions = [
  { value: "english", label: "English" },
  { value: "spanish", label: "Spanish" },
  { value: "french", label: "French" },
  { value: "german", label: "German" },
  { value: "dutch", label: "Dutch" },
];

const MySwal = withReactContent(Swal);

const PropertyCardInfo = ({ property }) => {
  // ** State

  // ** render user img
  const renderImg = () => {
    if (property !== null && property.thumbnail) {
      return (
        <img
          height="110"
          width="110"
          alt="user-avatar"
          src={`${themeConfig.serverUrl}uploads/${property.thumbnail}`}
          className="img-fluid rounded mt-3 mb-2"
        />
      );
    } else {
      return (
        <Avatar
          initials
          color={property.avatarColor || "light-primary"}
          className="rounded mt-3 mb-2"
          content={property.name}
          contentStyles={{
            borderRadius: 0,
            fontSize: "calc(48px)",
            width: "100%",
            height: "100%",
          }}
          style={{
            height: "110px",
            width: "110px",
          }}
        />
      );
    }
  };

  return (
    <Fragment>
      <Card>
        <CardBody>
          <div className="user-avatar-section">
            <div className="d-flex align-items-center flex-column">
              {renderImg()}
              <div className="d-flex flex-column align-items-center text-center">
                <div className="user-info">
                  <h4>{property !== null ? property.name : ""}</h4>
                  {property !== null ? (
                    <Badge color="primary" className="text-capitalize">
                      {property.category?.name}
                    </Badge>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/* <div className='d-flex justify-content-around my-2 pt-75'>
            <div className='d-flex align-items-start me-2'>
              <Badge color='light-primary' className='rounded p-75'>
                <Check className='font-medium-2' />
              </Badge>
              <div className='ms-75'>
                <h4 className='mb-0'>1.23k</h4>
                <small>Tasks Done</small>
              </div>
            </div>
            <div className='d-flex align-items-start'>
              <Badge color='light-primary' className='rounded p-75'>
                <Briefcase className='font-medium-2' />
              </Badge>
              <div className='ms-75'>
                <h4 className='mb-0'>568</h4>
                <small>Projects Done</small>
              </div>
            </div>
          </div> */}
          <h4 className="fw-bolder border-bottom pb-50 mb-1">Details</h4>
          <div className="info-container">
            {property !== null ? (
              <ul className="list-unstyled">
                <li className="mb-75">
                  <span className="fw-bolder me-25">Floors:</span>
                  <span>{property.floors}</span>
                </li>
                <li className="mb-75">
                  <span className="fw-bolder me-25">Owner:</span>
                  <span className="text-capitalize">
                    {property.owner?.name} - {property.owner?.phone}
                  </span>
                </li>

                <li className="mb-75">
                  <span className="fw-bolder me-25">Rent:</span>
                  <span className="text-capitalize">
                    {formatCurrency(property.price)}
                  </span>
                </li>

                <li className="mb-75">
                  <span className="fw-bolder me-25">Reservation:</span>
                  <span>{formatCurrency(property.reservationFee)}</span>
                </li>
                <li className="mb-75">
                  <span className="fw-bolder me-25">Deposit:</span>
                  <span>{formatCurrency(property.deposit)}</span>
                </li>
                <li className="mb-75">
                  <span className="fw-bolder me-25">Advance:</span>
                  <span>{formatCurrency(property.advance)}</span>
                </li>
                <li className="mb-75">
                  <span className="fw-bolder me-25">Address:</span>
                  <span>{property.location?.address}</span>
                </li>
                <li className="mb-75">
                  <span className="fw-bolder me-25">Status:</span>
                  <Badge
                    className="text-capitalize"
                    color={statusColors[property.status]}
                  >
                    {property.status}
                  </Badge>
                </li>
              </ul>
            ) : null}
          </div>
          <div className="d-flex justify-content-center pt-2 d-none">
            <Button color="primary" onClick={() => setShow(true)}>
              Edit
            </Button>
            <Button className="ms-1" color="danger" outline>
              Suspended
            </Button>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default PropertyCardInfo;
