// ** React Imports
import { Download, Printer, SendCheck } from "react-bootstrap-icons";

// ** Reactstrap Imports
import { Button, Card, CardBody } from "reactstrap";
import useStorage from "../../../utility/hooks/useStorage";

const PreviewActions = ({
  id,
  setSendSidebarOpen,
  setAddPaymentOpen,
  data,
  handlePrint,
}) => {
  const { setItem } = useStorage();
  return (
    <Card className="invoice-action-wrapper">
      <CardBody>
        <Button
          color="primary"
          block
          className="mb-75"
          onClick={() => setSendSidebarOpen(true)}
        >
          <SendCheck className="me-1" />
          Send Receipt
        </Button>
        <Button color="secondary" block outline className="mb-75">
          <Download className="me-1" />
          Download
        </Button>
        <Button
          to="print"
          block
          outline
          className="mb-75 btn btn-outline-secondary d-block"
          onClick={() => handlePrint()}
        >
          <Printer className="me-1" />
          Print
        </Button>
        {/* <Button tag={Link} to={`/apps/invoice/edit/${id}`} color='secondary' block outline className='mb-75'>
          Edit
        </Button> */}
        {/* {
          data.status.toLowerCase() != 'paid' ?
           <Button color="success" block onClick={() =>{}}>
         Make Payment
        </Button>
          :null
        } */}
      </CardBody>
    </Card>
  );
};

export default PreviewActions;
