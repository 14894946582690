import React, { useEffect, useRef } from "react";

import { Badge, Button, Card, Col, Row, Spinner } from "reactstrap";
import { UncontrolledTooltip } from "reactstrap";
import { ChevronDown, Edit, Trash } from "react-feather";

import themeConfig from "../../../configs/themeConfig";
// ** Icons Imports
import { User } from "react-feather";
import { Fragment, useState } from "react";
import DataTable from "react-data-table-component";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// ** Styles
import "animate.css/animate.css";
import "@styles/react/libs/tables/react-dataTable-component.scss";
import "@styles/base/plugins/extensions/ext-component-sweet-alerts.scss";

import { toast } from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import {
  useDeletAgentMutation,
  useGetAgentsQuery,
} from "../../../redux/agents";
import _ from "lodash";
import Loading from "../../loading";

import CustomPagination from "../shifts/components/DataTable/CustomPagination";
import CustomHeader from "./components/CustomHeader";
import AddAgentModal from "./components/modals/AddAgent";
import UpdateAgentModal from "./components/modals/UpdateAgent";
import StatsHorizontal from "../../../@core/components/widgets/stats/StatsHorizontal";

const MySwal = withReactContent(Swal);

const Agent = () => {
  // ** states
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showAddAgentModal, setShowAddAgentModal] = useState(false);
  const [showUpdateAgentModal, setShowUpdateAgentModal] = useState(false);
  const [activeAgents, setActiveAgents] = useState(0);
  const [inactiveAgents, setInactiveAgents] = useState(0);

  const agentRef = useRef(null);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const appView = searchParams.get("view");

  const { data, isLoading } = useGetAgentsQuery({
    page: currentPage,
    perPage: rowsPerPage,
    keyword: searchTerm,
  });

  const [deleteAgent] = useDeletAgentMutation();

  // calculate active and inactive agents
  useEffect(() => {
    if (data?.data) {
      const active = _.filter(data.data.docs, (agent) => {
        return agent.status === "active";
      });
      const inactive = _.filter(data.data.docs, (agent) => {
        return agent.status === "inactive";
      });
      setActiveAgents(active.length);
      setInactiveAgents(inactive.length);
    }
  }, [data]);

  const handlePerPage = (e) => {
    const value = parseInt(e.currentPage?.value);
    setRowsPerPage(value);
  };

  const handleEditClick = (data) => {
    agentRef.current = data;
    setShowUpdateAgentModal(true);
  };

  const handleDeleteClick = (data) => {
    return MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async function (result) {
      if (result.value) {
        const res = await deleteAgent(data._id);
        if (res.data !== undefined) toast.success("Agent deleted successfully");
      }
    });
  };

  const columns = [
    {
      name: "Name",
      sortable: true,
      minWidth: "240px",
      sortField: "name",
      selector: (row) => row.name ?? "",
      cell: (row) => (
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={() => {
            const url =
              `/hrm/agents/${row._id}` + (appView ? "?view=" + appView : "");

            navigate(url);
          }}
        >
          <div className="avatar rounded">
            <div className="avatar-content">
              {row.photo && row.photo !== "no-image" ? (
                <image src={`${themeConfig.serverUrl}uploads/${row.photo}`} />
              ) : (
                <User />
              )}
            </div>
          </div>
          <div className="ms-1">
            <div className="fw-bolder">{row.name}</div>
            <div className="font-small-2 text-muted">{row.username}</div>
          </div>
        </div>
      ),
    },
    {
      name: "Email",
      minWidth: "210px",
      sortable: true,
      cell: (row) => <div>{row.email ?? ""}</div>,
    },
    {
      name: "Phone",
      maxWidth: "150px",
      sortable: true,
      cell: (row) => <div>{row.phone ?? ""}</div>,
    },
    {
      name: "Status",
      minWidth: "100px",
      maxWidth: "100px",
      sortable: true,
      sortField: "status",
      cell: (row) => (
        <Badge
          color={row.status.toLowerCase() == "active" ? "success" : "danger"}
          className="text-capitalize"
        >
          <span className="">{row.status}</span>
        </Badge>
      ),
    },

    {
      name: "Actions",
      cell: (row) => {
        return (
          <div className="d-flex align-items-center permissions-actions">
            <Button
              id="update-btn"
              size="sm"
              color="transparent"
              className="btn btn-icon"
              onClick={() => handleEditClick(row)}
            >
              <Edit className="font-medium-2" />
              <UncontrolledTooltip placement="top" target="update-btn">
                Update
              </UncontrolledTooltip>
            </Button>
            <Button
              id="delete-btn"
              size="sm"
              color="transparent"
              className="btn btn-icon"
              onClick={() => handleDeleteClick(row)}
            >
              <Trash className="font-medium-2 text-danger" />
              <UncontrolledTooltip placement="top" target="delete-btn">
                Delete
              </UncontrolledTooltip>
            </Button>
          </div>
        );
      },
    },
  ];

  const renderData = () => {
    const calculateIndex = (index) => {
      return (currentPage - 1) * rowsPerPage + index + 1;
    };
    const agents = Array.isArray(data?.data)
      ? data.data
      : data?.data?.docs ?? {};
    return agents.map((item, index) => {
      return { ...item, index: calculateIndex(index) };
    });
  };

  return (
    <Fragment>
      <Row>
        <Col lg="4" sm="6">
          <StatsHorizontal
            icon={<User size={21} />}
            color="primary"
            statTitle="Total agents"
            renderStats={
              isLoading ? (
                <Spinner />
              ) : (
                <h3 className="fw-bolder mb-75">
                  {data?.data?.totalDocs
                    ? data.data.totalDocs
                    : data?.data?.total ?? 0}
                </h3>
              )
            }
          />
        </Col>
        <Col lg="4" sm="6">
          <StatsHorizontal
            icon={<User size={21} />}
            color="success"
            statTitle="Active agents"
            renderStats={
              isLoading ? (
                <Spinner />
              ) : (
                <h3 className="fw-bolder mb-75">
                  {activeAgents ? activeAgents : 0}
                </h3>
              )
            }
          />
        </Col>

        <Col lg="4" sm="6">
          <StatsHorizontal
            icon={<User size={21} />}
            color="danger"
            statTitle="Inactive agents"
            renderStats={
              isLoading ? (
                <Spinner />
              ) : (
                <h3 className="fw-bolder mb-75">
                  {inactiveAgents ? inactiveAgents : 0}
                </h3>
              )
            }
          />
        </Col>
      </Row>
      <Card className="overflow-hidden">
        <div className="react-dataTable">
          {isLoading ? (
            <Loading cols={columns} />
          ) : (
            <DataTable
              noHeader
              pagination
              subHeader
              responsive
              paginationServer
              columns={columns}
              sortIcon={<ChevronDown />}
              className="react-dataTable"
              paginationComponent={() => (
                <CustomPagination
                  data={data}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  rowsPerPage={rowsPerPage}
                />
              )}
              data={renderData()}
              subHeaderComponent={
                <CustomHeader
                  setShow={setShowAddAgentModal}
                  searchTerm={searchTerm}
                  handleSearch={(term) => setSearchTerm(term)}
                  handleClearResults={() => setSearchTerm("")}
                  rowsPerPage={rowsPerPage}
                  handlePerPage={handlePerPage}
                />
              }
            />
          )}
        </div>
      </Card>
      <AddAgentModal show={showAddAgentModal} setShow={setShowAddAgentModal} />
      <UpdateAgentModal
        agent={agentRef.current}
        show={showUpdateAgentModal}
        setShow={setShowUpdateAgentModal}
      />
    </Fragment>
  );
};

export default Agent;
