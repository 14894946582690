import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ApiErrorHandler } from "../utility/error";
import { BASE_URL, getToken } from "../utility/Utils";
import { result } from "lodash";

export const agentAPi = createApi({
  reducerPath: "agents",
  tagTypes: ["Agents"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, {}) => {
      const token = getToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Agents"],
  endpoints: (builder) => ({
    // create Agent
    createAgent: builder.mutation({
      query: (body) => ({ url: "/hrm/agents/", method: "POST", body }),
      invalidatesTags: ["Agents"],
    }),

    //get agents list
    getAgentList: builder.query({
      query: () => {
        return "/hrm/agents/list";
      },
    }),

    getAgents: builder.query({
      query: ({ page = 1, perPage = 10, keyword = "" }) => {
        const params = new URLSearchParams();
        if (keyword || keyword !== "") {
          params.append("search", keyword);
          params.append("fields[0]", "name");
          params.append("fields[1]", "email");
          params.append("fields[2]", "phone");
          params.append("fields[3]", "document");

          params.append("options[page]", page);
          params.append("options[limit]", perPage);
          params.append("options[populate][0][dir]", "users");
          params.append("options[populate][0][path]", "createdBy");
          params.append("options[populate][0][path][select]", "username");

          return `/hrm/agents?${params.toString()}`;
        }

        params.append("options[page]", page);
        params.append("options[limit]", perPage);
        params.append("options[populate][0][dir]", "users");
        params.append("options[populate][0][path]", "createdBy");
        params.append("options[populate][0][select]", "username");

        return `/hrm/agents?${params.toString()}`;
      },
      providesTags: ["Agents"],
    }),

    // get Agent by id or search
    getAgentById: builder.query({
      query: (id) => `/hrm/agents/${id}`,
      providesTags: (result, error, id) => {
        if (error) {
          ApiErrorHandler(error);
          return [];
        }
        return [{ type: "Agents", id: result?.data?._id }];
      },
    }),

    // update agent by id
    updateAgent: builder.mutation({
      query: ({ id, data }) => ({
        url: `/hrm/agents/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: (result, error, { _id }) => {
        if (error) {
          ApiErrorHandler(error);
          return [];
        }
        return [{ type: "Agents", id: _id }];
      },
    }),

    // delete agent by id
    deletAgent: builder.mutation({
      query: (id) => ({
        url: `/hrm/agents/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Agents"],
    }),

    // get agent statement
    getAgentStatement: builder.query({
      query: ({ id, start, end }) => {
        const params = new URLSearchParams();
        params.append("start", start);
        params.append("end", end);
        return `/hrm/agents/statement/${id}?${params.toString()}`;
      },
    }),
  }),
});

export const {
  useGetAgentsQuery,
  useDeletAgentMutation,
  useCreateAgentMutation,
  useUpdateAgentMutation,
  useGetAgentListQuery,
  useGetAgentByIdQuery,
  useGetAgentStatementQuery,
} = agentAPi;
