import React, { useEffect, useState } from "react";
import {
  Pinterest,
  Reddit,
  Skype,
  Snapchat,
  Tiktok,
  Vimeo,
} from "react-bootstrap-icons";
import {
  Facebook,
  GitHub,
  Instagram,
  Linkedin,
  Plus,
  Trash,
  Twitter,
  Youtube,
} from "react-feather";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  Input,
  Form,
  InputGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import CreatableSelect from "react-select";

// ** Redux RTK Query Imports
import {
  useUpdateCompanySittingMutation,
  useGetCompanySittingQuery,
} from "../../../redux/companySittings";

import SingleFileUploader from "./SingleFileUploader";
import { ErrorHandler } from "../../../common/utils/Error";
import axios from "axios";
import toast from "react-hot-toast";
import themeConfig from "../../../configs/themeConfig";
import { useSelector } from "react-redux";

const socialMedia = [
  {
    title: "Facebook",
    icon: <Facebook size={20} />,
    url: "https://www.facebook.com/",
  },
  {
    title: "Twitter",
    icon: <Twitter size={20} />,
    url: "https://twitter.com/",
  },
  {
    title: "Instagram",
    icon: <Instagram size={20} />,
    url: "https://www.instagram.com/",
  },
  {
    title: "LinkedIn",
    icon: <Linkedin size={20} />,
    url: "https://www.linkedin.com/in/",
  },
  { title: "GitHub", icon: <GitHub size={20} />, url: "https://github.com/" },
  {
    title: "YouTube",
    icon: <Youtube size={20} />,
    url: "https://www.youtube.com/",
  },
  { title: "Skype", icon: <Skype size={20} />, url: "https://www.skype.com/" },
  {
    title: "TikTok",
    icon: <Tiktok size={20} />,
    url: "https://www.tiktok.com/",
  },
  {
    title: "Pinterest",
    icon: <Pinterest size={20} />,
    url: "https://www.pinterest.com/",
  },
  {
    title: "Snapchat",
    icon: <Snapchat size={20} />,
    url: "https://www.snapchat.com/",
  },
  {
    title: "Reddit",
    icon: <Reddit size={20} />,
    url: "https://www.reddit.com/",
  },
  { title: "Vimeo", icon: <Vimeo size={20} />, url: "https://vimeo.com/" },
];

const CompanySetting = ({ compnaySetting }) => {
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState([]);
  const [favicon, SetFavicon] = useState([]);
  const [options, setOptions] = useState(socialMedia);
  const [lightLogo, setLightLogo] = useState([]);
  const [darkLogo, setDarkLogo] = useState([]);
  const [tinyLogo, setTinyLogo] = useState([]);
  const [letterHeader, setLetterHeader] = useState([]);
  const [letterFooter, setLetterFooter] = useState([]);
  const [isNew, setIsnew] = useState(false);

  const userData = useSelector((state) => state.authentication.userData);

  console.log("lightLogo:", lightLogo);

  // ** RTK Query Mutations
  const [updateCompanySitting, { isLoading, isError, error }] =
    useUpdateCompanySittingMutation({});

  const { data } = useGetCompanySittingQuery(userData.company._id);

  // console.log("data:", data.data?.name);

  const defaultValues = {
    // name: data.data?.name,
    // description: data.data?.description,
    // domain: data.data?.domain,
    // privateDB: data.data?.privateDB,
    // siteTile: data.data?.siteTile,
    // siteTagline: data.data?.siteTagline,
  };

  const handsName = "Hanad Mohamed Dahir";

  console.log("defaultValues:", defaultValues);

  const {
    register,
    reset,
    control,
    setError,
    clearErrors,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const {
    fields: items,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "socialMedia",
  });

  const onDiscard = () => {
    reset();
    setValue("socialMedia", []);
  };

  const [dropdownOpen, setDropdownOpen] = useState(
    Array(items.length).fill(false)
  );

  const toggleDropDown = (index) => {
    const newDropdownOpen = [...dropdownOpen];
    newDropdownOpen[index] = !newDropdownOpen[index];
    setDropdownOpen(newDropdownOpen);
  };

  const onSubmit = async (values) => {
    const formdata = new FormData();

    // Append form values
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        if (typeof value === "object" && value !== null) {
          formdata.append(key, JSON.stringify(value));
        } else {
          formdata.append(key, value);
        }
      }
    }

    formdata.append("status", values.status.value);

    lightLogo.forEach((file, i) => {
      formdata.append("lightLogo", file, file.name);
    });

    console.log(
      "Object.fromEntries(formdata).lightLogo",
      Object.fromEntries(formdata).lightLogo
    );

    try {
      const res = await updateCompanySitting(
        formdata
        //   {
        //   // name: values.name,
        //   // description: values.description,
        //   // domain: values.domain,
        //   // privateDB: values.database,
        //   // siteTile: values.siteTile,
        //   // siteTagline: values.siteTagline,
        //   // copyright: values.copyright,
        //   // supportEmail: values.supportEmail,
        //   // supprtPhone: values.supportPhone,
        //   // playStoreURL: values.playStoreURL,
        //   // appStoreURL: values.appStoreURL,
        //   // active: Object.fromEntries(formdata).status === "active" ? true : false,
        //   // location: {
        //   //   address: values.address,
        //   //   coordinates: {
        //   //     latitude: values.latitude,
        //   //     longitude: values.longitude,
        //   //   },
        //   // },
        //   // contactPersons: {
        //   //   name: values.contactPersonName,
        //   //   phone: values.contactPersonPhone,
        //   //   email: values.contactPersonEmail,
        //   // },
        //   // lightLogo: Object.fromEntries(formdata).lightLogo,
        //   // lightLogo: {
        //   //   type: Object.fromEntries(formdata).lightLogo?.type,
        //   //   name: Object.fromEntries(formdata).lightLogo.name,
        //   //   documentPath: Object.fromEntries(formdata).lightLogo.path,
        //   // },
        //   // darkLogo: {
        //   //   type: Object.fromEntries(formdata).darkLogo?.type,
        //   //   name: Object.fromEntries(formdata).darkLogo.name,
        //   //   documentPath: Object.fromEntries(formdata).darkLogo.path,
        //   // },
        //   // tinyLogo: {
        //   //   type: Object.fromEntries(formdata).tinyLogo?.type,
        //   //   name: Object.fromEntries(formdata).tinyLogo.name,
        //   //   documentPath: Object.fromEntries(formdata).tinyLogo.path,
        //   // },
        //   // favicon: {
        //   //   type: Object.fromEntries(formdata).favicon?.type,
        //   //   name: Object.fromEntries(formdata).favicon.name,
        //   //   documentPath: Object.fromEntries(formdata).favicon.path,
        //   // },
        //   // letterHeader: {
        //   //   type: Object.fromEntries(formdata).letterHeader.type,
        //   //   name: Object.fromEntries(formdata).letterHeader.name,
        //   //   documentPath: Object.fromEntries(formdata).letterHeader.path,
        //   // },
        //   // letterFooter: {
        //   //   type: Object.fromEntries(formdata).letterFooter.type,
        //   //   name: Object.fromEntries(formdata).letterFooter.name,
        //   //   documentPath: Object.fromEntries(formdata).letterFooter.path,
        //   // },
        // }
      );

      if (res && isNew) {
        reset();
        setIsnew(false);
        toast.success("CompanySettings updated successfully!");
      }
      if (res && !isNew) {
        reset();
        toast.success("CompanySettings updated successfully!");
      }
    } catch (error) {
      ErrorHandler(error);

      if (error) {
        toast.error("CompanySettings update failed!");
      }
    }
  };

  useEffect(() => {
    reset(compnaySetting);

    setLogo([themeConfig.serverUrl + "uploads/" + compnaySetting.logo]);
    SetFavicon([themeConfig.serverUrl + "uploads/" + compnaySetting.favicon]);
  }, [compnaySetting]);

  const getIcon = (title) => {
    const icon = socialMedia.find((items) => items.title === title)?.icon;
    return icon;
  };

  useEffect(() => {
    if (data) {
      console.log("data:", data);
      reset(data.data);
      //  status
      setValue("status", {
        label: data.data?.active ? "Active" : "Inactive",
        value: data.data?.active,
      });
      setValue("contactPersonName", data.data?.contactPersons[0]?.name);
      setValue("contactPersonPhone", data.data?.contactPersons[0]?.phone);
      setValue("contactPersonEmail", data.data?.contactPersons[0]?.email);
      setValue("SupportEmail", data.data?.supportEmail);
      setValue("supportPhone", data.data?.supportPhone);
      setValue("siteTitle", data.data?.siteTitle);
      setValue("siteTagline", data.data?.siteTagline);
      setValue("address", data.data?.location?.address);
      setValue("latitude", data.data?.location?.coordinates?.latitude);
      setValue("longitude", data.data?.location?.coordinates?.longitude);
      // setLightLogo(data.data?.lightLogo);

      // setDarkLogo([
      //   `http://localhost:5000/uploads/${data.data?.darkLogo?.documentPath}`,
      // ]);
    }
  }, [data]);

  return (
    <Row tag={Form} onSubmit={handleSubmit(onSubmit)}>
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="name">
          Name
        </Label>
        <Controller
          control={control}
          name="name"
          defaultValue={handsName | "test Name"}
          rules={{ required: "Name is required" }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Company Name"
              status={errors.name ? "error" : ""}
            />
          )}
        />
        {errors.name && <FormFeedback>please enter company name</FormFeedback>}
      </Col>

      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="description">
          description
        </Label>
        <Controller
          control={control}
          id="description"
          name="description"
          defaultValue=""
          // rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="description"
              // invalid={errors.description && true}
            />
          )}
        />
      </Col>
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="domain">
          domain
        </Label>
        <Controller
          control={control}
          id="domain"
          name="domain"
          defaultValue=""
          // rules={{ required: true }}
          {...register("domain")}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="domain"
              // invalid={errors.domain && true}
            />
          )}
        />
        {/* {errors.domain && (
          <FormFeedback>please enter a valid domain name</FormFeedback>
        )} */}
      </Col>

      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="database">
          database
        </Label>
        <Controller
          control={control}
          id="database"
          name="database"
          defaultValue=""
          // rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="database name"
              // invalid={errors.database && true}
            />
          )}
        />
      </Col>
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="siteTile">
          site Title
        </Label>
        <Controller
          control={control}
          id="siteTitle"
          name="siteTitle"
          defaultValue=""
          // rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="site Title"
              // invalid={errors.siteTile && true}
            />
          )}
        />
      </Col>
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="siteTagline">
          site Tag Line
        </Label>
        <Controller
          control={control}
          id="siteTagline"
          name="siteTagline"
          defaultValue=""
          // rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="site Tag Line"
              // invalid={errors.sitetagline && true}
            />
          )}
        />
      </Col>
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="copyright">
          Copyright
        </Label>
        <Controller
          control={control}
          id="copyright"
          name="copyright"
          defaultValue=""
          // rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="copyright "
              // invalid={errors.copyright && true}
            />
          )}
        />
      </Col>
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="supportEmail">
          Suppert Email
        </Label>
        <Controller
          control={control}
          id="supportEmail"
          name="supportEmail"
          defaultValue=""
          // rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Suppert Email "
              // invalid={errors.suppertEmail && true}
            />
          )}
        />
      </Col>
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="supportPhone">
          Support Phone
        </Label>
        <Controller
          control={control}
          id="supportPhone"
          name="supportPhone"
          defaultValue=""
          // rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="support Phone "
              // invalid={errors.supportPhone && true}
            />
          )}
        />
      </Col>
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="playStoreURL">
          PlayStore URL
        </Label>
        <Controller
          control={control}
          id="playStoreURL"
          name="playStoreURL"
          defaultValue=""
          // rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="playStore URL "
              // invalid={errors.playStoreURL && true}
            />
          )}
        />
      </Col>
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="appStoreURL">
          AppStore URL
        </Label>
        <Controller
          control={control}
          id="appStoreURL"
          name="appStoreURL"
          defaultValue=""
          // rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="AppStore URL "
              // invalid={errors.appStoreURL && true}
            />
          )}
        />
      </Col>
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="status">
          status
        </Label>
        <Controller
          control={control}
          id="status"
          name="status"
          defaultValue=""
          // rules={{ required: true }}
          render={({ field }) => (
            <CreatableSelect
              id="status"
              className={"react-select"}
              classNamePrefix="select"
              // aria-invalid={errors.status && true}
              placeholder="Choose status"
              options={[
                { label: "Active", value: "active" },
                { label: "Inactive", value: "inactive" },
              ]}
              {...field}
            />
          )}
        />
      </Col>

      {/* logo */}
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="lightLogo">
          Light Logo
        </Label>

        <SingleFileUploader
          files={lightLogo}
          setFiles={setLightLogo}
          name="lightLogo"
          id="lightLogo"
          label="Light Logo"
        />
      </Col>
      {/* <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="darkLogo">
          Dark Logo
        </Label>
        <SingleFileUploader
          files={darkLogo}
          setFiles={setDarkLogo}
          name="darkLogo"
          id="darkLogo"
          label="Dark Logo"
        />
      </Col>
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="tinyLogo">
          Tiny Logo
        </Label>
        <SingleFileUploader
          files={tinyLogo}
          setFiles={setTinyLogo}
          name="tinyLogo"
          id="tinyLogo"
          label="Tiny Logo"
        />
      </Col>
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="favicon">
          Favicon
        </Label>
        <SingleFileUploader
          files={favicon}
          setFiles={SetFavicon}
          name="favicon"
          id="favicon"
          label="Favicon"
        />
      </Col>
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="letterHeader">
          Letter header
        </Label>
        <SingleFileUploader
          files={letterHeader}
          setFiles={setLetterHeader}
          name="letterHeader"
          id="letterHeader"
          label="Letter Header"
        />
      </Col>
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="letterFooter">
          Letter footer
        </Label>
        <SingleFileUploader
          files={letterFooter}
          setFiles={setLetterFooter}
          name="letterFooter"
          id="letterFooter"
          label="Letter Footer"
        />
      </Col> */}

      {/* location */}
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="address">
          Address
        </Label>
        <Controller
          control={control}
          id="address"
          name="address"
          defaultValue=""
          // rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Address "
              // invalid={errors.address && true}
            />
          )}
        />
      </Col>
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="latitude">
          latitude
        </Label>
        <Controller
          control={control}
          id="latitude"
          name="latitude"
          defaultValue=""
          // rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="latitude "
              // invalid={errors.latitude && true}
            />
          )}
        />
      </Col>
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="longitude">
          longitude
        </Label>
        <Controller
          control={control}
          id="longitude"
          name="longitude"
          defaultValue=""
          // rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="longitude "
              // invalid={errors.longitude && true}
            />
          )}
        />
      </Col>

      {/* contactPersons */}
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="contactPersonName">
          Contact person name
        </Label>
        <Controller
          control={control}
          id="contactPersonName"
          name="contactPersonName"
          defaultValue=""
          // rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Contact person name "
              // invalid={errors.contactPersonName && true}
            />
          )}
        />
      </Col>
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="contactPersonPhone">
          Contact person phone
        </Label>
        <Controller
          control={control}
          id="contactPersonPhone"
          name="contactPersonPhone"
          defaultValue=""
          // rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Contact person phone "
              // invalid={errors.contactPersonPhone && true}
            />
          )}
        />
      </Col>
      <Col xs={12} md={6} className="mb-1">
        <Label className="form-label" for="contactPersonEmail">
          Contact person emial
        </Label>
        <Controller
          control={control}
          id="contactPersonEmail"
          name="contactPersonEmail"
          defaultValue=""
          // rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Contact person emial "
              // invalid={errors.contactPersonEmail && true}
            />
          )}
        />
      </Col>

      <Col xs={12} className="text-center my-2">
        <Button type="submit" color="primary" disabled={isLoading}>
          {isLoading && isNew ? "Please, wait..." : "Save Settings"}
        </Button>
      </Col>
    </Row>
  );
};

export default CompanySetting;
