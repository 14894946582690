// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAllRoles = createAsyncThunk(
  "roles/getAllData",
  async (_, { rejectWithValue }) => {
    try {
      let bodyContent = {
        params: {
          options: {
            query: {},
            // limit:15,
            populate: [],
          },
        },
      };
      const response = await axios.get("/roles", bodyContent);
      const docs = response.data?.data.docs || [];
      return { docs };
    } catch (error) {
      console.log("Errrrrrror: ", error);
      return rejectWithValue(error);
    }
  }
);

export const getData = createAsyncThunk("roles/getData", async (params) => {
  const response = await axios.get("/roles", {
    params: { options: params },
  });
  return {
    params,
    data: response.data?.data.docs,
  };
});

export const getSingleRole = createAsyncThunk(
  "roles/create",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      var response = await axios.post("/roles", data);
      if (response.status != 201) {
        return rejectWithValue(response.data.message);
      }

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createRole = createAsyncThunk(
  "roles/create",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      var response = await axios.post("/roles", data);
      if (response.status != 201) {
        return rejectWithValue(response.data.message);
      }

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateRole = createAsyncThunk(
  "roles/update",
  async ({ data, id }, { dispatch, getState, rejectWithValue }) => {
    try {
      var response = await axios.put(`/roles/${id}`, data);

      if (response.status != 201) {
        return rejectWithValue(response.data.message);
      }

      return response.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteRole = createAsyncThunk(
  "roles/delete",
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const currentUserRole = getState()?.authentication?.userData.role;

      if (id == currentUserRole._id) {
        return rejectWithValue("Cannot delete you current role");
      }

      const response = await axios.delete(`/roles/${id}`);
      if (response.status != 204) {
        return rejectWithValue("Can't Delete Try Again");
      }
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const rollSlice = createSlice({
  name: "roles",
  initialState: {
    data: [],
    loading: false,
    selectedRole: null,
  },
  reducers: {
    selectRole: (state, { payload }) => {
      state.selectedRole = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRoles.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.loading = false;
      })
      .addCase(getData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(createRole.fulfilled, (state, { payload, error }) => {
        state.data.push(payload);
      })
      .addCase(updateRole.fulfilled, (state, { payload }) => {
        state.data = state.data.map((d) => {
          if (d._id == payload._id) {
            return payload;
          }
          return d;
        });
      })
      .addCase(deleteRole.fulfilled, (state, { payload, error }) => {
        const deletedRole = payload;
        state.data = state.data.filter((d) => d._id !== deletedRole);
      });
  },
});
export const { selectRole } = rollSlice.actions;
export default rollSlice.reducer;
