// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios, { AxiosError } from "axios";

export const getAllExpenses = createAsyncThunk(
  "expenses/getAllData",
  async ({ status }, { dispatch, rejectWithValue, getState }) => {
    try {
      let params = {
        params: {
          options: {
            query: {
              buildingId: getState().CurrentBuilding?.data?._id ?? null,
              billStatus: status,
            },
            populate: [
              {
                dir: "accountings/chartOfAccounts",
                path: "expenseType",
              },
              {
                dir: "vendors",
                path: "vendor",
              },
            ],
          },
        },
      };

      const response = await axios.get("accountings/expenses", params);
      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      } else {
        const docs = response.data?.data?.docs || [];
        return { docs, totalRows: response.data?.data?.totalDocs };
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        var { response } = error;
        return rejectWithValue(response.data.message);
      }
    }
  }
);

export const getData = createAsyncThunk(
  "expenses/getData",
  async (params, { dispatch, rejectWithValue, getState }) => {
    try {
      const response = await axios.get("accountings/expenses", {
        params: {
          options: {
            query: {
              ...params,
              buildingId: getState().CurrentBuilding?.data?._id ?? null,
            },
            populate: [
              {
                dir: "accountings/chartOfAccounts",
                path: "accountId",
              },
            ],
          },
        },
      });

      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      } else {
        return {
          params,
          data: response.data?.data?.docs,
        };
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        var { response } = error;
        return rejectWithValue(response.data.message);
      }
    }
  }
);

export const updateExpense = createAsyncThunk(
  "Expenses/update",
  async ({ formdata, id }, { dispatch, getState, rejectWithValue }) => {
    try {
      var response = await axios.patch(`accountings/expenses/${id}`, formdata);

      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      }

      return response.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createExpenses = createAsyncThunk(
  "Expenses/create",
  async (
    formdata,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      var response = await axios.post("accountings/expenses", formdata);

      if (response.status != 200) {
        return rejectWithValue(response.data.message);
      }
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteExpense = createAsyncThunk(
  "Expenses/delete",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(`accountings/expenses/${id}`);
      if (response.status != 204) {
        return rejectWithValue("Can't Delete Try Again");
      }
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getExpenseFigures = createAsyncThunk(
  "Expenses/getExpenseFigures",
  async (_, { rejectWithValue, getState }) => {
    try {
      const buildingId = getState().CurrentBuilding?.data?._id ?? null;
      return await axios.get(
        `/accountings/expenses/figures?buildingId=${buildingId}`
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const expenseSlice = createSlice({
  name: "expenses",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: true,
    selectedExpense: null,
    figuresLoading: false,
    figures: { total: 0, paid: 0, unpaid: 0 },
  },
  reducers: {
    isLoading: (state, { payload }) => {
      state.loading = payload;
      state.error = null;
    },
    addExpense: (state, { payload }) => {
      state.allData.push(payload);
      state.data.push(payload);
    },
    selectExpense: (state, { payload }) => {
      state.selectedExpense = payload;
    },
    editExpense: (state, { payload }) => {
      state.data = state.data.map((d) => {
        if (d._id == payload._id) {
          return payload;
        }
        return d;
      });
      state.allData = state.allData.map((da) => {
        if (da._id == payload._id) {
          return payload;
        }
        return da;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllExpenses.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllExpenses.fulfilled, (state, action) => {
        state.data = action.payload.docs;
        state.allData = action.payload.docs;
        state.total = action.payload.totalRows;
        state.loading = false;
      })
      .addCase(getData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.loading = false;
      })
      .addCase(createExpenses.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.selectedExpense = null;
        state.allData.push(payload);
        state.data.push(payload);
      })
      .addCase(updateExpense.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.loading = false;
        state.error = null;
        state.data = state.data.map((d) => {
          if (d._id == payload._id) {
            return payload;
          }
          return d;
        });
        state.allData = state.allData.map((da) => {
          if (da._id == payload._id) {
            return payload;
          }
          return da;
        });
        state.selectedExpense = null;
      })
      .addCase(deleteExpense.fulfilled, (state, { payload, error }) => {
        state.loading = false;
        state.error = error;
        const deletedExpense = payload;
        state.data = state.data.filter((d) => d._id !== deletedExpense);
      })
      .addCase(getExpenseFigures.pending, (state, action) => {
        state.figuresLoading = true;
        state.error = null;
      })
      .addCase(getExpenseFigures.fulfilled, (state, action) => {
        const { figures } = action.payload?.data;
        if (figures) state.figures = figures;
        state.figuresLoading = false;
        state.error = null;
      })
      .addCase(getExpenseFigures.rejected, (state, action) => {
        state.figuresLoading = false;
        state.error = null;
      });
  },
});
export const { addExpense, selectExpense, editExpense, isLoading } =
  expenseSlice.actions;
export default expenseSlice.reducer;
