import axios from "axios";
import { toast } from "react-hot-toast";
import jwtDefaultConfig from "./jwtDefaultConfig";
import { store } from "../../../redux/store";
import { handleLogout } from "../../../redux/authentication";

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
  subscribers = [];

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken();
        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }

        return config;
      },
      (error) => {
        console.log("intercept 1");
        Promise.reject(error);
      }
    );

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log(error);

        // ** const { config, response: { status } } = error
        const { config, response, code } = error;
        const originalRequest = config;

        // if (response && response.status === 400) {
        //   // this.removeToken();
        //   store.dispatch(handleLogout());
        //   window.location.href = "/login";
        // }

        // ** if (status === 401) {
        if (response && response.status === 401) {
          // this.removeToken();
          // window.location.href = "/login";
          store.dispatch(handleLogout());
          window.location.href = "/login";
        }

        if (code == "ERR_NETWORK") {
          toast.error("Connection Error");
        }

        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return JSON.parse(localStorage.getItem(this.jwtConfig.storageTokenKeyName));
  }
  removeToken() {
    return localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    console.log(localStorage.getItem(this.jwtConfig.storageTokenKeyName));
    return JSON.parse(localStorage.getItem(this.jwtConfig.storageTokenKeyName));
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args);
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    });
  }
}
